import HttpClient from "../../../api/HttpClient";
import { AuthorizationRequest, AuthorizationResponse } from "../../../models";
import BaseRepository from "../BaseRepository";

export class AuthorizationRepo extends BaseRepository {
  protected client = new HttpClient();
  protected url = 'authorization';

  async checkPolicies(request: AuthorizationRequest): Promise<AuthorizationResponse> {
    const body = {
      "policies": request.policies
    }
    const response = await this.client.post(this.url, body);
    const result = this.handleResponse<AuthorizationResponse>(response);
    return result!;
  }
}

export default AuthorizationRepo;
