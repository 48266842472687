import { router, routes } from "../../../app/routes";

interface BreadcrumbProps {
  showHome: boolean;
  values: { label: string; url: string | null }[];
}

export function generateServiceTypesBreadcrumbs(
  path: string,
  serviceTypeName?: string,
  serviceTypeId?: number,
): BreadcrumbProps {

  const idMap = new Map([["Id", serviceTypeId?.toString() ?? ""]]);

  switch (path) {
    case router.getUrl(routes.serviceType.index, idMap):
      return {
        showHome: true,
        values: [{ label: "Service Types", url: null }],
      };
    case router.getUrl(routes.serviceType.details, idMap):
      return {
        showHome: true,
        values: [
          {
            label: "Service Types",
            url: routes.serviceType.index
          },
          {
            label: serviceTypeName ?? "",
            url: null,
          },
        ],
      };
    case router.getUrl(routes.serviceType.service, idMap):
      return {
        showHome: true,
        values: [
          { label: "Service Types", url: routes.serviceType.index },
          {
            label: serviceTypeName ?? "",
            url: router.getUrl(routes.serviceType.details, idMap),
          },
          { label: "Services", url: null},
        ],
      };

    default:
      return { showHome: false, values: [] };
  }
}


export default generateServiceTypesBreadcrumbs;

