import { ODataResponse, UserInviteModel } from "../../data/entities";
import { UserInviteODataRepo } from "../../data/repo/authentication/UserInviteODataRepo";
import UserInviteRepo from "../../data/repo/authentication/UserInviteRepo";
import { InviteRequest } from "../../models";

export class UserInviteService {

  protected oDataRepo = new UserInviteODataRepo();
  protected repo = new UserInviteRepo();

  async query(query: string): Promise<ODataResponse<UserInviteModel>> {
    return await this.oDataRepo.query(query);
  }

  async insert(request: InviteRequest): Promise<UserInviteModel> {
    return await this.repo.insert(request);
  }

  async delete(id: number): Promise<boolean> {
    return await this.repo.delete(id);
  }

}
