import { BaseEntityService } from "../BaseEntityService"
import { ProviderODataRepo, ProviderRepo } from "../../data/repo";
import ProviderModel from "../../data/entities/booking/ProviderModel";


export class ProviderService extends BaseEntityService<ProviderModel, ProviderRepo, ProviderODataRepo> {
  constructor(){
    super(new ProviderRepo(), new ProviderODataRepo());
  }
}

export default ProviderService;
