import { BaseEntityService } from "../BaseEntityService"
import { ProviderServiceODataRepo, ProviderServiceRepo } from "../../data/repo";
import ProviderServiceModel from "../../data/entities/booking/ProviderServiceModel";

export class ProviderServiceService extends BaseEntityService<ProviderServiceModel, ProviderServiceRepo, ProviderServiceODataRepo> {
  constructor(){
    super(new ProviderServiceRepo(), new ProviderServiceODataRepo());
  }
}

export default ProviderServiceService;
