import { BaseEntityService } from "../BaseEntityService"
import { OrganizationModel } from "../../data/entities";
import { OrganizationODataRepo, OrganizationRepo } from "../../data/repo/organization";


export class OrganizationService extends BaseEntityService<OrganizationModel, OrganizationRepo, OrganizationODataRepo> {
  constructor(){
    super(new OrganizationRepo(), new OrganizationODataRepo());
  }
}

export default OrganizationService;
