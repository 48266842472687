import PageLayout from "../PageLayout";
import { UserService } from "../../services";

export function HomePage() {
  // Avoid calling the API if the user is not signed in
  if (!UserService.isSignedIn()) return <></>;

  return (
    <PageLayout>
      <div className="row">
        <h1>Welcome to Ameena!</h1>
        <h2>I mean, uhhhhh</h2>
        <h3>.....................</h3>
        <h3>....................</h3>
        <h3>...................</h3>
        <h3>..................</h3>
        <h3>.................</h3>
        <h3>................</h3>
        <h3>...............</h3>
        <h3>..............</h3>
        <h3>.............</h3>
        <h3>............</h3>
        <h3>...........</h3>
        <h3>..........</h3>
        <h3>.........</h3>
        <h3>........</h3>
        <h3>.......</h3>
        <h3>......</h3>
        <h3>.....</h3>
        <h3>....</h3>
        <h3>...</h3>
        <h3>..</h3>
        <h3>.</h3>
      </div>
    </PageLayout>
  );
}

export default HomePage;
