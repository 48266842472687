import { useSpinner } from "../../../hooks";
import { showErrorMessage, showSuccessMessage } from "../../../utils";
import ScheduleService from "../../../services/booking/ScheduleService";
import { Schedule } from "../../../data/entities/booking/Schedule";

export function useCRUDHandlers(
  refreshData: () => void,
  showAddScheduleState?: { setter: (state: boolean) => void },
  showCloneScheduleState?: { setter: (state: boolean) => void }
) {
  const service = new ScheduleService();
  const { show, hide } = useSpinner();

  const insert = async (schedule: Schedule) => {
    try {
      show();
      const ok = await service.insert(schedule);
      if (ok) {
        showSuccessMessage(`Schedule added successfully.`);
        showAddScheduleState?.setter(false);
        refreshData();
      }
    } catch (error) {
      showErrorMessage(`Error occurred while trying to add schedule.`);
    } finally {
      hide();
    }
  };

  const deleteItem = async (id: number) => {
    try {
      show();
      await service.delete(id);
      showSuccessMessage(`Schedule deleted successfully.`);
      refreshData();
    } catch (error) {
      showErrorMessage(`Error occurred while trying to delete schedule.`);
    } finally {
      hide();
    }
  };

  const clone = async (id: number, effectiveDate: Date) => {
    try {
      show();
      await service.cloneAsync(id, effectiveDate);
      showSuccessMessage(`Schedule cloned successfully.`);
      showCloneScheduleState?.setter(false);
      refreshData();

    } catch (error) {
      showErrorMessage(`Error occurred while trying to clone schedule.`);
    } finally {
      hide();
    }
  }

  return {
    insert,
    deleteItem,
    clone,
  };
}
