import { routes } from "../../../app/routes";
import {
  TopbarSubmenu,
  IconDefinitions,
  SubmenuItem,
} from "../../../components";
import { useEffect, useMemo, useState } from "react";
import { useUrlParams } from "../../../hooks";

export const ProfileTopbar = (props: any) => {

  const returnUrl = useUrlParams("returnUrl");
  const id = useUrlParams("id");

  const defaultSubmenuItems: SubmenuItem[] = useMemo(
    (): SubmenuItem[] => [
      {
        label: "Profile",
        icon: IconDefinitions.user,
        url: routes.profile.index,
        activeMatch: routes.profile.index,
        activeMatchExact: true,
      },
      {
        label: "Change Password",
        icon: IconDefinitions.lock,
        url: routes.profile.changePassword,
        activeMatch: routes.profile.changePassword,
        activeMatchExact: true,
      }
    ],
    [id]
  );

  useEffect(() => {
    const backItem: SubmenuItem = {
      label: "Back",
      icon: IconDefinitions.back,
      url: returnUrl ?? "",
    };

    if (returnUrl) {
      setSubmenuItems([backItem, ...defaultSubmenuItems]);
    } else {
      setSubmenuItems(defaultSubmenuItems);
    }
  }, [defaultSubmenuItems, id, returnUrl]);

  const [submenuItems, setSubmenuItems] =
    useState<SubmenuItem[]>(defaultSubmenuItems);

  return <TopbarSubmenu className="p-0" items={submenuItems} />;
};

export default ProfileTopbar;

