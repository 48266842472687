import { useState, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { PageBreadcrumbProps, PageLayout } from "../../PageLayout"
import { generateOrganizationBreadcrumbs } from "./OrganizationsBreadcrumbs";
import { BreadcrumbProps } from "../../../components";
import OrganizationsTopbar from "./OrganizationsIndexTopbar";
import { PageTitleProvider, usePageTitle } from "../../../hooks";

function BaseLayout() {

  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const { title } = usePageTitle();


  /**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
    const newBreadcrumbs:BreadcrumbProps = generateOrganizationBreadcrumbs(location.pathname);
    setBreadcrumbs(newBreadcrumbs);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <PageLayout topbar={<OrganizationsTopbar />} breadcrumbs={breadcrumbs} title={title}>
      <Outlet />
    </PageLayout >
  )
}

export function OrganizationsIndexLayout() {
  // Wrap the BaseUserLayout in a PageTitleProvider.
  // This prevents an error of the context not being initialized when the usePageTitle hook is called.
  return (
    <PageTitleProvider>
      <BaseLayout />
    </PageTitleProvider>
  );
}

export default OrganizationsIndexLayout

