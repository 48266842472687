import { router, routes } from "../../../app/routes";

interface BreadcrumbProps {
  showHome: boolean;
  values: { label: string; url: string | null }[];
}

export function generateUserBreadcrumbs(
  path: string,
  userName?: string,
  userId?: number
): BreadcrumbProps {
  const idMap = new Map([["Id", userId?.toString() ?? ""]]);

  switch (path) {
    case routes.user.index:
      return {
        showHome: true,
        values: [{ label: "Users", url: null }],
      };
    case routes.user.invite:
      return {
        showHome: true,
        values: [{ label: "Users", url: routes.user.index }, { label: "Invitations", url: null }],
      };
    case router.getUrl(routes.user.details, idMap):
      return {
        showHome: true,
        values: [
          { label: "Users", url: routes.user.index },
          {
            label: userName ?? "",
            url: null,
          },
        ],
      };
    case router.getUrl(routes.user.access, idMap):
      return {
        showHome: true,
        values: [
          { label: "Users", url: routes.user.index },
          {
            label: userName ?? "",
            url: router.getUrl(routes.user.details, idMap),
          },
          { label: "Access", url: null },
        ],
      };
    case router.getUrl(routes.user.delete, idMap):
      return {
        showHome: true,
        values: [
          { label: "Users", url: routes.user.index },
          {
            label: userName ?? "",
            url: router.getUrl(routes.user.details, idMap),
          },
          { label: "Delete", url: null },
        ],
      };

    default:
      return { showHome: false, values: [] };
  }
}

export default generateUserBreadcrumbs;

