import {
  Button,
  SlideOutModal,
  SlideOutModalProps,
} from "../../../components";
import { Button as BSButton, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import * as yup from 'yup';
import { Formik } from 'formik';
import { useRef } from "react";

export interface CloneScheduleModalProps extends SlideOutModalProps {
  onSubmit: any;
  scheduleId: number;
}

export function CloneScheduleModal(props: Readonly<CloneScheduleModalProps>) {

  const submitRef = useRef<HTMLButtonElement>(null);

  const validationSchema = yup.object().shape({
    scheduleId: yup.number().required(),
    effectiveDate: yup.date().required('Effective Date is required'),
  });

  return (
    <SlideOutModal
      {...props}
      title={`Clone Schedule`}
      size="lg"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => {
                console.log("clicked visible button");
                submitRef.current?.click()
              }} variant="primary" className="w-100">
                Clone
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          scheduleId: props.scheduleId,
          effectiveDate: new Date().toISOString().split('T')[0],
        }}
        onSubmit={(values) => {
          props.onSubmit(values.scheduleId, values.effectiveDate);
        }}
      >
        {({ handleSubmit, errors, values, touched, handleChange }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="effectiveDate">
                    <FloatingLabel label="Effective Date" className="mb-3">
                      <Form.Control
                        type="date"
                        name="effectiveDate"
                        placeholder="Effective Date"
                        value={values.effectiveDate}
                        onChange={handleChange}
                        isInvalid={touched.effectiveDate && !!errors.effectiveDate}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.effectiveDate}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <BSButton ref={submitRef} type="submit" onClick={()=>{
                console.log("clicked hidden button");
                console.log(errors);
              }}className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default CloneScheduleModal;
