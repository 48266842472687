import {
  IconAndText,
  IconDefinitions,
  Card,
  CardTitle,
  SpinnerDefault,
  Icon,
} from "../../components";
import { Button, Table } from "react-bootstrap";
import { useGridActions, useSetPageTitle } from "../../hooks";
import { useAccess } from "./hooks";
import { usePageActions } from "../PageLayout";
import { ErrorPage } from "../error";
import { isEmptyArray } from "formik";
import { AddUserLocationModal, DeleteUserEntityModal } from "./components";
import { Dropdown } from "react-bootstrap";
import { AddUserOrganizationModal } from "./components/AddUserOrganizationModal";
import { useCRUDHandlers } from "./hooks/useCRUDHandlers";
import ManageRolesModal from "./components/ManageRolesModal";
import { useTableActions } from "./hooks";
import { RoleScopeEnum } from "../../data/entities/user/RoleScopeEnum";
import { UserEntityModel } from "../../models/user/roleassignments/UserEntityModel";
import { useSelector } from "react-redux";
import { RootState } from "../../app";
import usePermission from "../../hooks/usePermission";
import { PermissionDefinitions } from "../../security";

export function UserAccessPage() {

  const { globalRoles, tenantRoleAssignments, organizationRoleAssignments, locationRoleAssignments, loading, error, refreshData } = useAccess();

  const { showAddState: showAddLocationState } = useGridActions();
  const { showAddState: showAddOrganizationState } = useGridActions();
  const {
    showManageRolesState,
    showDeleteState,
    handleLocationRowClick,
    handleOrganziationRowClick,
    handleDeleteClick,
    selectedEntity
  } = useTableActions();

  const { addOrganization, addLocation, setLocationRoles, setOrganizationRoles, deleteUserLocation, deleteUserOrganization }
    = useCRUDHandlers(refreshData, showAddOrganizationState, showAddLocationState, showManageRolesState);

  useSetPageTitle("Access", []);

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canAddAccess = usePermission(PermissionDefinitions.access.create(tenantId!));

  usePageActions(
    canAddAccess && (
      <Dropdown>
        <Dropdown.Toggle as="div" className="cursor-pointer">
          <Button variant="primary">
            <IconAndText iconName={IconDefinitions.add} text="Add" />
          </Button>
        </Dropdown.Toggle>
        <Dropdown.Menu className="text-center border-radius-sm">
          <Dropdown.Item
            onClick={() => {
              showAddOrganizationState.setter(true);
            }}
          >
            Organization
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              showAddLocationState.setter(true);
            }}
          >
            Location
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  );

  const renderTable = (data: any[], roleKey: string, roleTitle: string, onClick: (item: UserEntityModel) => void, onDelete: (item: UserEntityModel) => void) => (
    <Table borderless hover responsive>
      <thead>
        <tr>
          <th style={{ width: "300px" }}>{roleTitle}</th>
          <th>Roles</th>
          <th style={{ width: "50px" }}></th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, idx) => (
          <tr className="cursor-pointer" key={idx} onClick={() => onClick(item)}>
            <td>{item.name}</td>
            <td>{item.roles.length > 0 ? item.roles.join(", ") : <span className="text-muted">None</span>}</td>
            {canAddAccess && (
              <td>
                <Icon
                  onClick={(e) => {
                    if (e)
                      e.stopPropagation();
                    onDelete(item);
                  }}
                  className="mx-2 text-danger cursor-pointer"
                  iconName={IconDefinitions.delete}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );

  if (loading) return <SpinnerDefault />;
  if (error) return <ErrorPage message={error.message} />;

  return (
    <>
      <CardTitle title="Access" className="mb-4" />

      {/* Global Roles */}
      {!isEmptyArray(globalRoles) ? (
        <Card className="mb-4">
            <Table borderless responsive>
              <thead>
                <tr>
                  <th>Global Roles</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{globalRoles.join(", ")}</td>
                </tr>
              </tbody>
            </Table>
        </Card>
      ) : (
        <></>
      )}

      {/* Tenant Roles */}
      {!isEmptyArray(tenantRoleAssignments) ? (
        <Card className="mb-4">
            <Table borderless responsive>
              <thead>
                <tr>
                  <th>Site-Wide Roles</th>
                </tr>
              </thead>
              <tbody>
                <tr className="cursor-pointer">
                  <td>{tenantRoleAssignments?.map(t => t.roleNames.join(", "))}</td>
                </tr>
              </tbody>
            </Table>
        </Card>
      ) : (
        <></>
      )}

      {/* Organization Roles */}
      <Card className="mb-4">
        {!isEmptyArray(organizationRoleAssignments) && organizationRoleAssignments ? (
          renderTable(organizationRoleAssignments.map(o => ({ name: o.organizationName, roles: o.roleNames, id: o.organizationId, scope: RoleScopeEnum.Organization } as UserEntityModel)), "organization", "Organization", handleOrganziationRowClick, handleDeleteClick)
        ) : (
          <div className="p-3 text-muted">No organizations assigned</div>
        )}
      </Card>

      {/* Location Roles */}
      <Card className="mb-4">
        {!isEmptyArray(locationRoleAssignments) && locationRoleAssignments ? (
          renderTable(locationRoleAssignments.map(l => ({ name: l.locationName, roles: l.roleNames, id: l.locationId, scope: RoleScopeEnum.Location } as UserEntityModel)), "location", "Location", handleLocationRowClick, handleDeleteClick)
        ) : (
          <div className="p-3 text-muted">No locations assigned</div>
        )}
      </Card>

      <AddUserLocationModal
        showAddState={showAddLocationState}
        showState={showAddLocationState}
        onSubmit={addLocation}
      />
      <AddUserOrganizationModal
        showAddState={showAddOrganizationState}
        showState={showAddOrganizationState}
        onSubmit={addOrganization}
      />
      <ManageRolesModal
        showAddState={showManageRolesState}
        showState={showManageRolesState}
        entity={selectedEntity.value}
        onSubmit={selectedEntity.value.scope === RoleScopeEnum.Location ? setLocationRoles : setOrganizationRoles}
      />
      <DeleteUserEntityModal
        showState={showDeleteState}
        title={`Remove ${selectedEntity.value.name} Access`}
        message={`You are about to remove access from ${selectedEntity.value.name}. This action cannot be undone. Are you sure you want to proceed?`}
        confirmText="Delete"
        entity={selectedEntity.value}
        onConfirm={selectedEntity.value.scope === RoleScopeEnum.Location ? deleteUserLocation : deleteUserOrganization}
      />
    </>
  );
}

export default UserAccessPage;
