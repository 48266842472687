import { useParams } from "react-router-dom";
import { routes } from "../../../app/routes";
import {
  TopbarSubmenu,
  IconDefinitions,
  SubmenuItem,
} from "../../../components";
import { UrlService } from "../../../services";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app";
import usePermission from "../../../hooks/usePermission";
import { PermissionDefinitions } from "../../../security";

export const UserTopbar = (props: any) => {
  const params = useParams();
  const returnUrl = UrlService.getQueryParams("returnUrl");
  const id = params["id"] ?? "";


  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canDeleteUser = usePermission(PermissionDefinitions.user.delete(tenantId!));

  const defaultSubmenuItems: SubmenuItem[] = useMemo((): SubmenuItem[] => {
    const items: SubmenuItem[] = [
      {
        label: "Back",
        icon: IconDefinitions.back,
        url: routes.user.index,
      },
      {
        label: "Details",
        icon: IconDefinitions.user,
        url: routes.user.details,
        activeMatch: routes.user.details,
        activeMatchExact: true,
        params: new Map([["id", id]]),
      },
      {
        label: "Access",
        icon: IconDefinitions.lock,
        url: routes.user.access,
        activeMatch: routes.user.access,
        activeMatchExact: true,
        params: new Map([["id", id]]),
      },
    ];

    if (canDeleteUser) {
      items.push({
        label: "Delete",
        icon: IconDefinitions.trashCan,
        url: routes.user.delete,
        activeMatch: routes.user.delete,
        activeMatchExact: true,
        params: new Map([["id", id]]),
      });
    }

    return items;
  }, [id, canDeleteUser]);

  useEffect(() => {
    const backItem: SubmenuItem = {
      label: "Back",
      icon: IconDefinitions.back,
      url: returnUrl ?? "",
    };

    if (returnUrl) {
      setSubmenuItems([backItem, ...defaultSubmenuItems]);
    } else {
      setSubmenuItems(defaultSubmenuItems);
    }
  }, [defaultSubmenuItems, id, returnUrl]);

  const [submenuItems, setSubmenuItems] =
    useState<SubmenuItem[]>(defaultSubmenuItems);

  return <TopbarSubmenu className="p-0" items={submenuItems}></TopbarSubmenu>;
};

export default UserTopbar;

