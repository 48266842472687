import React, { PropsWithChildren, useEffect, useState } from 'react'
import {
  DropDownListBlurEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import DropdownOption from './DropdownOption';
import SearchableDropdown from './SearchableDropdown';
import { OrganizationService } from '../../services';
import { OrganizationModel } from '../../data/entities';

interface SearchableOrganizationDropdownProps extends PropsWithChildren {
  value?: any;
  name: string;
  isInvalid?: boolean;
  onBlur?: (e: DropDownListBlurEvent) => void;
  onChange?: (e: DropDownListChangeEvent) => void;
  onSelect?: (value: DropdownOption) => void;
  placeholder?: string;
  disabled?: boolean;
}

export const SearchableOrganizationDropdown = React.forwardRef(
  (
    props: Readonly<SearchableOrganizationDropdownProps>,
    ref: React.ForwardedRef<HTMLSelectElement | null>
  ) => {
    const organizationService = new OrganizationService();
    const [options, setOptions] = useState<DropdownOption[]>([]);

    useEffect(() => {
      const fetchData = async () => {
        let organizations = await organizationService.getAll();

        const newOptions = organizations.map<DropdownOption>((organization: OrganizationModel) => ({
          value: organization.id?.toString() ?? "",
          text: `${organization.name}`,
        }));

        setOptions(newOptions);
      };

      fetchData();
    }, []);

    const getValue = () => {
      if (props.value) {
        return options.find((option) => option.value === props.value?.toString());
      }
    }

    return (
      <SearchableDropdown
        label="Organization"
        name={props.name}
        options={options}
        value={getValue()}
        onChange={props.onChange}
        onSelect={props.onSelect}
        isInvalid={props.isInvalid}
        disabled={props.disabled}
      >
        {props.children}
      </SearchableDropdown>
    );
  }
);

export default SearchableOrganizationDropdown
