import {
  Button,
  HandleDropDownListChange,
  SearchableServiceDropdown,
  SlideOutModal,
  SlideOutModalProps,
} from "../../../components";
import { sharedProperty } from "../../../hooks";
import { useRef } from "react";
import * as yup from 'yup';
import { Formik } from 'formik';
import { Col, Form, Row, Button as BSButton } from "react-bootstrap";
import ProviderServiceModel from "../../../data/entities/booking/ProviderServiceModel";

export interface AddProviderServiceModalProps extends SlideOutModalProps {
  showAddState: sharedProperty<boolean>;
  providerId: number;
  onSubmit: any;
}

export function AddProviderServiceModal(props: Readonly<AddProviderServiceModalProps>) {

  const submitRef = useRef<HTMLButtonElement>(null);

  const validationSchema = yup.object().shape({
    serviceId: yup.number().required("Service is required"),
    providerId: yup.number().required("Provider is required"),
  });

  return (
    <SlideOutModal
      {...props}
      title="Add New Service"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => {
                  submitRef.current?.click()
                }} variant="primary" className="w-100">
                Add
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          serviceId: 0,
          providerId: props.providerId,
        }}
        onSubmit={async (values) => {
          props.onSubmit({
            ...values,
            id: 0
          } as ProviderServiceModel);
        }}
      >
        {({values, touched, errors, setFieldValue, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>

              <Row>
                <Col>
                  <Form.Group controlId="serviceId">
                      <SearchableServiceDropdown
                        name="serviceId"
                        value={values.serviceId}
                        placeholder="Service"
                        onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                        isInvalid={touched.serviceId && !!errors.serviceId}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.serviceId}
                      </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <BSButton ref={submitRef} type="submit" size="lg" className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default AddProviderServiceModal;
