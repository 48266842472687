import React from "react";
import { Outlet } from "react-router-dom";
import AppLayout from "../../AppLayout";
import logo from "../../../images/branding/logo-brown-text-white-inline.svg";
import logoBrown from "../../../images/branding/logo-brown-text-blue-inline.svg";
import style from "../styles/Account.module.scss";

export function AccountPageLayout(props: React.PropsWithChildren) {
  return (
    <AppLayout includeTopbar={false} includeSidebar={false}>
      <div className={`${style.gradient} h-100 d-flex flex-column justify-content-center`}>
        <div className={`d-flex h-100 flex-column flex-lg-row ${style.pageContainer}`}>
          <div className={`${style.contentContainer} bg-white text-dark`}>
              <div className="w-100 d-flex d-lg-none justify-content-center">
                <img width={300} className="mb-4" src={logoBrown} alt="Ameena logo" />
              </div>
              <Outlet />
          </div>
          <div className={`${style.contentContainer} align-items-center`}>
            <img className="mb-4" width={400} src={logo} alt="Ameena logo" />
            <h2 className={style.heading}>Designed for Full Booking Support</h2>
            <div className={style.subheading}>
              <span>Manage all your bookings and grow your business from anywhere!</span>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default AccountPageLayout;
