import { useState, useEffect } from 'react';
import { SlideOutModal, SlideOutModalProps } from '../../../components';
import { sharedProperty} from '../../../hooks';
import ProviderTopbar from '../layout/ProviderTopbar';
import ProviderDetailsPage from '../ProviderDetailsPage';
import ProviderServicesPage from '../ProviderServicesPage';
import ProviderModel from '../../../data/entities/booking/ProviderModel';
import ProviderSchedulesPage from '../ProviderSchedulePage';

interface ManageProviderModalProps extends SlideOutModalProps {
  dataItemState: sharedProperty<ProviderModel>;
  showState: sharedProperty<boolean>;
  onSubmit: any;
}

export function ManageProviderModal(props: Readonly<ManageProviderModalProps>) {

  const [activeTab, setActiveTab] = useState<string>('Details');

  useEffect(() => {
    setActiveTab('Details');
  }, [props.dataItemState.value.id])

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <SlideOutModal {...props}
      title={`${props.dataItemState.value.name}`}
      bodyClassName="p-0"
      size='xl'
      topbar={
        <ProviderTopbar onChangeTab={handleTabChange} />
      }
      onHide={() => { setActiveTab('Details') }}
    >
      {/* {activeTab === 'Details' && (
        <ProviderDetailsPage dataItemState={props.dataItemState} showState={props.showState} onSubmit={props.onSubmit} />
      )}

      {activeTab === 'Services' && (
        <ProviderServicesPage providerId={props.dataItemState.value.id} showState={props.showState}/>
      )}

      {activeTab === 'Schedules' && (
        <ProviderSchedulesPage providerId={props.dataItemState.value.id} showState={props.showState}/>
      )} */}

      {props.children}
    </SlideOutModal>
  )
}

export default ManageProviderModal;
