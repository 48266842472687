import { useEffect, useState, useCallback } from "react";
import { useUrlParams, useAsync } from "../../../hooks";
import ScheduleService from "../../../services/booking/ScheduleService";
import { Schedule } from "../../../data/entities/booking/Schedule";

export function useSchedule() {
  const scheduleService = new ScheduleService();
  const [refreshKey, setRefreshKey] = useState(0);
  const providerId = useUrlParams("providerId");
  const { value: dsched, error: defaultError, loading: defaultLoading } = useAsync(() => scheduleService.getDefaultSchedulesAsync(parseInt(providerId)), [providerId, refreshKey]);
  const { value: esched, error: exceptionError, loading: exceptionLoading } = useAsync(() => scheduleService.getExceptionSchedulesAsync(parseInt(providerId)), [providerId, refreshKey]);

  const [defaultSchedules, setDefaultSchedules] = useState<Schedule[] | null>([]);
  const [exceptionSchedules, setExceptionSchedules] = useState<Schedule[] | null>([]);

  useEffect(() => {
    setDefaultSchedules(dsched);
    setExceptionSchedules(esched);
  }, [dsched, esched]);

  const refreshData = useCallback(() => {
    setRefreshKey(prevKey => prevKey + 1);
  }, []);

  return {
    defaultSchedules,
    exceptionSchedules,
    defaultLoading,
    exceptionLoading,
    defaultError,
    exceptionError,
    refreshData
  }
}
