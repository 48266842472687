import { AuthorizationRepo } from "../../data/repo";
import { AuthorizationRequest, AuthorizationResponse } from "../../models";

export class AuthorizationService {
  protected repo = new AuthorizationRepo();

  async checkPolicies(request: AuthorizationRequest): Promise<AuthorizationResponse> {
    return await this.repo.checkPolicies(request);
  }
}

export default AuthorizationService;
