import { createBrowserRouter, Outlet } from "react-router-dom";
import Root from "../../root";
import * as Features from "../../features";
import { Role } from "../../models";

/**
 * The base router configuration for the application.
 * It defines the routes and their corresponding components.
 */
const browserRouter = createBrowserRouter([
  {
    path: "*",
    element: <Features.NotFound />,
  },
  {
    path: "/",
    element: <Root></Root>,
    errorElement: <Features.ErrorPage />,
    children: [
      // Empty path
      {
        path: "",
        element: <Features.HomePage />,
      },
      //Manage
      {
        path: "profile",
        element: <Features.ProfileBaseLayout />,
        children: [
          {
            path: "",
            element: <Features.ProfileIndexPage />,
          },
          {
            path: "change-password",
            element: <Features.ProfileChangePasswordPage />,
          },
        ],
      },
      // User
      {
        path: "user",
        element: (
          <Features.SecurityOutlet
            roles={[
              Role.administrator,
              Role.tenant_admin,
              Role.owner,
              Role.manager,
            ]}
          >
            <Outlet />
          </Features.SecurityOutlet>
        ),
        children: [
          {
            path: "",
            element: <Features.UsersIndexLayout />,
            children: [
              {
                path: "",
                element: <Features.UserIndexPage />,
              },
              {
                path: "invite",
                element: <Features.UserInvitationsPage />
              }
            ]
          },
          {
            path: ":id",
            element: <Features.UserLayout />,
            children: [
              {
                path: "",
                element: <Features.UserDetailsPage />,
              },
              {
                path: "access",
                element: <Features.UserAccessPage />,
              },
              {
                path: "delete",
                element: <Features.UserDeletePage />,
              },
            ],
          },
        ],
      },
      // Service Types
      {
        path: "service-type",
        element: (
          <Features.SecurityOutlet
          roles={[
            Role.administrator,
            Role.tenant_admin,
            Role.owner,
            Role.manager,
          ]}
          >
            <Outlet />
          </Features.SecurityOutlet>
        ),
        children: [
          {
            path: "",
            element: <Features.ServiceTypesIndexLayout />,
            children: [
              {
                path: "",
                element: <Features.ServiceTypesIndexPage />
              }
            ]
          },
          {
            path: ":id",
            element: <Features.ServiceTypeLayout />,
            children: [
              {
                path: "",
                element: <Features.ServiceTypeDetailsPage />,
              },
              {
                path: "service",
                element: <Features.ServiceTypeServicesPage />,
              }
            ]
          }
        ]
      },
      // Locations
      {
        path: "location",
        element: (
          <Features.SecurityOutlet
          roles={[
            Role.administrator,
            Role.tenant_admin,
            Role.owner,
            Role.manager,
          ]}
          >
            <Outlet />
          </Features.SecurityOutlet>
        ),
        children: [
          {
            path: "",
            element: <Features.LocationsIndexLayout />,
            children: [
              {
                path: "",
                element: <Features.LocationsIndexPage />
              }
            ]
          },
          {
            path: ":id",
            element: <Features.LocationLayout />,
            children: [
              {
                path: "",
                element: <Features.LocationDetailsPage />,
              },
              {
                path: "address",
                element: <Features.LocationAddressPage />,
              },
              {
                path: "pricing",
                element: <Features.LocationPricingPage />,
              },
              {
                path: "provider",
                element: <Features.LocationProvidersPage />,
              },
              {
                path: "provider/:providerId",
                element: <Features.ProviderLayout />,
                children: [
                  {
                    path: "",
                    element: <Features.ProviderDetailsPage />,
                  },
                  {
                    path: "services",
                    element: <Features.ProviderServicesPage />,
                  },
                  {
                    path: "schedule",
                    element: <Features.ProviderSchedulePage />,
                  }
                ]
              }
            ]
          }
        ]
      },
      // Organizations
      {
        path: "organization",
        element: (
          <Features.SecurityOutlet
            roles={[
              Role.administrator,
              Role.tenant_admin,
              Role.owner,
              Role.manager,
            ]}
          >
            <Outlet />
          </Features.SecurityOutlet>
        ),
        children: [
          {
            path: "",
            element: <Features.OrganizationsIndexLayout/>,
            children: [
              {
                path: "",
                element: <Features.OrganizationsIndexPage />
              }
            ]
          },
          {
            path: ":id",
            element: <Features.OrganizationLayout />,
            children: [
              {
                path: "",
                element: <Features.OrganizationDetailsPage />,
              },
            ]
          }
        ]
      },
    ],
  },
  //Account
  {
    path: "account",
    element: <Features.AccountPageLayout />,
    errorElement: <Features.ErrorPage />,
    children: [
      {
        path: "",
        element: <Features.LoginPage />,
      },
      {
        path: "register/:id/:key",
        element: <Features.RegisterPage />,
      },
      {
        path: "register/confirmation",
        element: <Features.RegisterConfirmationPage />,
      },
      {
        path: "login",
        element: <Features.LoginPage />,
      },
      {
        path: "tenant-selection",
        element: <Features.TenantSelectionPage />,
      },
      {
        path: `login?returnUrl=:returnUrl`,
        element: <Features.LoginPage />,
      },
      {
        path: "forgotPassword",
        element: <Features.ForgotPasswordPage />,
      },
      {
        path: "forgotPasswordConfirmation",
        element: <Features.ForgotPasswordConfirmationPage />,
      },
      {
        path: "resetPassword",
        element: <Features.ResetPasswordPage />,
      },
      {
        path: "resetPasswordConfirmation",
        element: <Features.ResetPasswordConfirmationPage />,
      },
      {
        path: "registrationRequest",
        element: <Features.RegistrationRequestPage />,
      },
      {
        path: "registrationRequestConfirmation",
        element: <Features.RegistrationRequestConfirmationPage />,
      },
    ],
  },
]);

/**
 * The router object that extends the baseRouter and provides additional functionality.
 */
export const router = Object.assign(browserRouter, {
  /**
   * Gets the URL with the specified parameters replaced.
   * @param url - The URL to process.
   * @param params - The parameters to replace in the URL.
   * @returns The processed URL.
   */
  getUrl: (url: string, params: Map<string, string> | undefined) => {
    let lowerUrl = url.toLocaleLowerCase();
    let replacedUrl: string = lowerUrl;
    params?.forEach((value, key) => {
      replacedUrl = lowerUrl.replace(`:${key.toLocaleLowerCase()}`, value);
      lowerUrl = replacedUrl;
    });
    return lowerUrl;
  },
  /**
   * Navigates to the specified URL with the specified parameters.
   * @param url - The URL to navigate to.
   * @param params - The parameters to replace in the URL.
   * @param newTab - A boolean indicating whether to open the URL in a new tab.
   */
  navigateWithParams: (
    url: string,
    params: Map<string, string> | undefined,
    newTab: boolean = false
  ) => {
    const processedUrl = router.getUrl(url, params);
    if (newTab) {
      window.open(processedUrl, "_blank");
      return;
    }
    browserRouter.navigate(processedUrl).catch((error) => {
      return <Features.ErrorPage />;
    });
  },
});

export default router;
