import React from "react";
import style from "./styles/ModalSubmenu.module.scss";
import { ModalSubmenuItem, SubmenuItem } from "./ModalSubmenuItem";
import { getUuid } from "../../../utils";

interface ModalSubmenuProps extends React.PropsWithChildren {
  items?: SubmenuItem[];
  className?: string;
}

export function ModalSubmenu(props: ModalSubmenuProps) {
  const itemsList = [...(props.items ?? [])];

  return (
    <ul className={`topbar ${style.submenu} overflow-hidden ps-3 bg-white nav ${props.className} `}>
      {itemsList.map((item) => (
        <ModalSubmenuItem key={getUuid()} item={item}/>
      ))}
    </ul>
  );
}

export default ModalSubmenu;

