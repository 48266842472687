import { DayOfWeek } from '../../data/entities/booking/DayOfWeek';
import ScheduleBlockEditor from './ScheduleBlockEditor';
import { Row, Col } from 'react-bootstrap';
import { Icon } from '../icons';
import style from './styles/Schedule.module.scss';
import { FieldArray, useFormikContext } from 'formik';

const daysOfWeek = [
  { name: 'SUN', value: DayOfWeek.Sunday },
  { name: 'MON', value: DayOfWeek.Monday },
  { name: 'TUE', value: DayOfWeek.Tuesday },
  { name: 'WED', value: DayOfWeek.Wednesday },
  { name: 'THU', value: DayOfWeek.Thursday },
  { name: 'FRI', value: DayOfWeek.Friday },
  { name: 'SAT', value: DayOfWeek.Saturday },
];

export const ScheduleEditor = () => {

  const { values, errors } = useFormikContext<any>();
  const dayErrors: { [key in DayOfWeek]?: string } = (errors.scheduleBlocks as { [key in DayOfWeek]?: string }) || {};

  return (
    <FieldArray name="scheduleBlocks">
      {({ push, remove }) => (
        <>
          {daysOfWeek.map((day) => {
            const blocksForDay = values.scheduleBlocks.filter(
              (block: any) => block.dayOfWeek === day.value
            );

            return (
              <Row key={day.value} className="align-items-start">
                <Col xs={2} className="fw-bold me-3 py-2">
                  {day.name}
                </Col>
                <Col className="d-flex align-items-start justify-content-between">
                  <div>
                    {blocksForDay.map((block: any) => {
                      const blockIndex = values.scheduleBlocks.findIndex(
                        (b: any) => b === block
                      );
                      return (
                        <ScheduleBlockEditor
                          key={block.id || blockIndex}
                          blockIndex={blockIndex}
                          remove={remove}
                        />
                      );
                    })}
                    </div>
                    <div className="py-2">
                      <Icon
                        iconName="plus"
                        className={`${style.add} cursor-pointer w-auto`}
                        onClick={() =>
                          push({
                            scheduleId: values.scheduleId,
                            dayOfWeek: day.value,
                            startTime: '09:00',
                            endTime: '17:00',
                          })
                        }
                      />
                    </div>
                </Col>
                {dayErrors[day.value] && (
                  <div className="text-danger">
                    {dayErrors[day.value]}
                  </div>
                )}
                <hr className="my-2"/>
              </Row>
            );
          })}
        </>
      )}
    </FieldArray>
  );
};

export default ScheduleEditor;
