import { useEffect, useState } from "react";
import { useAuthorization } from "./useAuthorization";

export const usePermission = (requiredPolicies: string[] | string) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
  const { checkPolicies } = useAuthorization();

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const policies = Array.isArray(requiredPolicies)
          ? requiredPolicies
          : [requiredPolicies];
        const result = await checkPolicies(policies);
        const authorized = policies.every((policy) => result[policy]);
        setIsAuthorized(authorized);
      } catch (error) {
        console.error("Authorization error:", error);
        setIsAuthorized(false);
      }
    };
    checkAuthorization();
  }, [checkPolicies, requiredPolicies]);

  return isAuthorized;
};

export default usePermission;
