import { Button, HandleDropDownListChange, IconAndText, IconDefinitions, SearchableOrganizationDropdown, Card, SpinnerDefault } from "../../components";
import * as yup from "yup";
import { Formik } from "formik";
import { Col, FloatingLabel, Form, Row, Button as BSButton } from "react-bootstrap";
import { LocationModel } from "../../data/entities";
import { useCRUDHandlers, useSetPageTitle, useUrlParams } from "../../hooks";
import { LocationService } from "../../services";
import useAsync from "../../hooks/useAsync";
import { useRef } from "react";
import { usePageActions } from "..";
import { useSelector } from "react-redux";
import { RootState } from "../../app";
import usePermission from "../../hooks/usePermission";
import { PermissionDefinitions } from "../../security";

export function LocationDetailsPage() {

  const id = parseInt(useUrlParams("id"));
  const locationService = new LocationService();
  const { editItem } = useCRUDHandlers<LocationModel>(locationService, "Location");
  const submitRef = useRef<HTMLButtonElement>(null);
  const { value: location, error, loading } = useAsync(() => new LocationService().get(id), [id]);

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canEditLocation = usePermission(PermissionDefinitions.location.edit(tenantId!));

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().notRequired(),
    organizationId: yup.number().required("Organization is required"),
  });

  useSetPageTitle(location?.name ?? "", [location]);
  usePageActions(
    canEditLocation && (
      <Button onClick={() => submitRef.current?.click()}>
        <IconAndText iconName={IconDefinitions.save} text="Save" />
      </Button>
    ),
    [canEditLocation]);

  if (error) return <div>Error loading location: {error.message}</div>;
  if (!location || loading) return <SpinnerDefault />;

  return (
    <Card title="Details">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: location.name,
          businessCode: location.businessCode,
          organizationId: location.organizationId
        }}
        onSubmit={(values) => {
          const result = {
            ...location,
            name: values.name,
            businessCode: values.businessCode,
            organizationId: values.organizationId,
          } as LocationModel;
          editItem(result);
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit} noValidate>

            <Row>
              <Col>
                <FloatingLabel label="Name" className="mb-3">
                  <Form.Control
                    type="string"
                    placeholder="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    disabled={!canEditLocation}
                    isInvalid={touched.name && 
                      !!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            <Row>
              <Col>
                <FloatingLabel label="Business Code" className="mb-3">
                  <Form.Control
                    type="string"
                    placeholder="Business Code"
                    name="businessCode"
                    value={values.businessCode}
                    onChange={handleChange}
                    disabled={!canEditLocation}
                    isInvalid={touched.businessCode && !!errors.businessCode}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.businessCode}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="organizationId">
                  <SearchableOrganizationDropdown
                    name="organizationId"
                    value={values.organizationId}
                    onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                    disabled={!canEditLocation}
                    isInvalid={touched.organizationId && !!errors.organizationId}
                  >
                    <Form.Control.Feedback type="invalid">
                      {errors.organizationId}
                    </Form.Control.Feedback>
                  </SearchableOrganizationDropdown>
                </Form.Group>
              </Col>
            </Row>

            <BSButton ref={submitRef} type="submit" className="d-none" />
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default LocationDetailsPage;

