import { Accordion, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { Card, ConfirmationModal, Icon, IconAndText, IconDefinitions } from "../../components";
import { ScheduleType } from "../../data/entities/booking/ScheduleType";
import { Schedule as ScheduleModel} from "../../data/entities/booking/Schedule";
import Schedule from "../../components/Schedule/Schedule";
import { usePageActions } from "../PageLayout";
import EditScheduleModal from "./components/EditScheduleModal";
import { useGridActions, useSharedState, useUrlParams } from "../../hooks";
import ScheduleService from "../../services/booking/ScheduleService";
import { ScheduleBlock } from "../../data/entities/booking/ScheduleBlock";
import AddScheduleModal from "./components/AddScheduleModal";
import { useCRUDHandlers } from "./hooks/useCRUDHandlers";
import { useSchedule } from "./hooks/useSchedule";
import { formatDate } from "../../utils";
import style from "./styles/ProviderSchedulePage.module.scss";
import CloneScheduleModal from "./components/CloneScheduleModal";

export function ProviderSchedulePage() {

  const scheduleService = new ScheduleService();
  const { editItemState: scheduleEditItemState,  showEditState, showAddState, showDeleteState, deleteItemState, showCloneState } = useGridActions<ScheduleModel>()
  const { defaultSchedules, exceptionSchedules, refreshData } = useSchedule();
  const scheduleTypeState = useSharedState<ScheduleType>({} as ScheduleType);
  const scheduleCloneItemState = useSharedState<ScheduleModel>({} as ScheduleModel);
  const { insert, deleteItem, clone } = useCRUDHandlers(refreshData, showAddState, showCloneState);

  const providerId = useUrlParams("providerId");
  usePageActions(
    <DropdownButton id="dropdown-basic-button" title={<IconAndText iconName={IconDefinitions.add} text="Add"/>}>
      <Dropdown.Item className="text-center" onClick={() => {
        scheduleTypeState.setter(ScheduleType.Default);
        showAddState.setter(true)
      }}>Schedule</Dropdown.Item>
      <Dropdown.Item className="text-center" onClick={() => {
        scheduleTypeState.setter(ScheduleType.Override);
        showAddState.setter(true)
      }}>Exception</Dropdown.Item>
    </DropdownButton>
  );

  return (
    <>
      <Card title="Schedule">
        <Row>
          <Col lg={12}>
            <h2>Schedules</h2>
            <div className="d-flex flex-column justify-content-between">
              {defaultSchedules && defaultSchedules.map((schedule, index) => (
                <Accordion defaultActiveKey={index === 0 ? "0" : undefined} key={index} className="mb-3">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="d-flex align-items-center w-100">
                        <span style={{lineHeight: "1px"}}>{index === 0 ? "Current" : `Starting ${formatDate(schedule.effectiveDate.toString())}`}</span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      <Schedule className="p-4" schedule={schedule} />
                      <div className={`${style.accordionFooter} d-flex justify-content-between`}>
                        <div className={`${style.accordionItem}`} onClick={() => {
                            scheduleEditItemState.setter(schedule);
                            showEditState.setter(true);
                          }}>
                          <Icon iconName={IconDefinitions.edit} />
                          <span className="ms-1">Edit</span>
                        </div>
                        <div className={`${style.accordionItem}`} onClick={() => {
                            scheduleCloneItemState.setter(schedule);
                            showCloneState.setter(true);
                          }}>
                          <Icon iconName={IconDefinitions.gear}/>
                          <span className="ms-1">Clone</span>
                        </div>
                        <div className={`${style.accordionItem}`} onClick={() => {
                            deleteItemState.setter(schedule);
                            showDeleteState.setter(true);
                          }}>
                          <Icon iconName={IconDefinitions.delete} />
                          <span className="ms-1">Delete</span>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </div>
            <EditScheduleModal
              showState={showEditState}
              schedule={scheduleEditItemState}
              onSubmit={async (scheduleBlocks: ScheduleBlock[], scheduleId: number) => {
                await scheduleService.setScheduleBlocksAsync(scheduleId, scheduleBlocks);
                refreshData();
              }}
            />
            <AddScheduleModal
              showState={showAddState}
              providerId={parseInt(providerId)}
              scheduleType={scheduleTypeState.value}
              onSubmit={insert}
              label={scheduleTypeState.value === ScheduleType.Default ? "Schedule" : "Exception"}
            />
            <CloneScheduleModal
              showState={showCloneState}
              scheduleId={scheduleCloneItemState.value.id}
              onSubmit={clone}
            />
            <ConfirmationModal
              showState={showDeleteState}
              title={`Delete`}
              message={`You are about to delete this schedule, it cannot be undone. Are you sure you want to delete?`}
              confirmText="Delete"
              onConfirm={() => deleteItem(deleteItemState.value.id)}
            />
          </Col>
          <Col lg={12} className="border-lg-1">
            <h2>Exceptions</h2>
            <div className="d-flex flex-column justify-content-between">
              {exceptionSchedules && exceptionSchedules.map((schedule, index) => (
                <Accordion key={index} className="mb-3">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between w-100">
                        <span>{`${formatDate(schedule.effectiveDate.toString())} to ${schedule.effectiveEndDate && formatDate(schedule.effectiveEndDate.toString())}`}</span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      <Schedule className="p-4" schedule={schedule} />
                      <div className={`${style.accordionFooter} d-flex justify-content-between`}>
                        <div className={`${style.accordionItem}`} onClick={() => {
                            scheduleEditItemState.setter(schedule);
                            showEditState.setter(true);
                          }}>
                          <Icon iconName={IconDefinitions.edit} />
                          <span className="ms-1">Edit</span>
                        </div>
                        <div className={`${style.accordionItem}`} onClick={() => {
                            deleteItemState.setter(schedule);
                            showDeleteState.setter(true);
                          }}>
                          <Icon iconName={IconDefinitions.delete} />
                          <span className="ms-1">Delete</span>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default ProviderSchedulePage;

