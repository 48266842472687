import { CardTitle } from "../../card";
import { MenuItem } from "./MenuItem";
import SidebarSubmenuItem from "./SidebarSubmenuItem";
import { v4 as uuidv4 } from "uuid";

interface SubmenuSideBarProps {
  title?: string,
  menuItems: MenuItem[];
}
export function SidebarSubmenu({title, menuItems}:  SubmenuSideBarProps) {

  return (
    <div className="h-100">
      <div className="sidebar h-100 z-1 d-flex flex-column">
        <div className="h-100">
          <CardTitle title={"Menu"} className="d-none d-lg-block mb-3" />
          <ul className="d-inline-flex d-lg-block p-0">
            {menuItems.map((menuItem) => (
              <SidebarSubmenuItem
                key={uuidv4()}
                text={menuItem.text}
                url={menuItem.url}
                additionalClass={`${menuItem.additionalClass}`}
                activeMatchUrl={menuItem.activeMatchUrl ?? menuItem.url}
                clickHandler={menuItem.clickHandler}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SidebarSubmenu;
