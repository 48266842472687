import { useSpinner } from "../../../hooks";
import { showErrorMessage, showSuccessMessage } from "../../../utils";
import { UserRoleAssignmentService } from "../../../services/authentication/UserRoleAssignmentService";
import { AddOrganizationRequest, AddLocationRequest, SetLocationRolesRequest, SetOrganizationRolesRequest } from "../../../models";
import { UserEntityModel } from "../../../models/user/roleassignments/UserEntityModel";

export function useCRUDHandlers(
  refreshData: () => void,
  showAddOrganizationState?: { setter: (state: boolean) => void },
  showAddLocationState?: { setter: (state: boolean) => void },
  showManageRolesState?: { setter: (state: boolean) => void }
) {
  const service = new UserRoleAssignmentService();
  const { show, hide } = useSpinner();

  const addOrganization = async (userId: number, request: AddOrganizationRequest) => {
    try {
      show();
      const ok = await service.addUserOrganization(userId, request);
      if (ok) {
        showSuccessMessage(`Organization access added successfully.`);
        showAddOrganizationState?.setter(false);
        refreshData();
      }
    } catch (error) {
      showErrorMessage(`Error occurred while trying to add organization access.`);
    } finally {
      hide();
    }
  };

  const addLocation = async (userId: number, request: AddLocationRequest) => {
    try {
      show();
      const ok = await service.addUserLocation(userId, request);
      if (ok) {
        showSuccessMessage(`Location access added successfully.`);
        showAddLocationState?.setter(false);
        refreshData();
      }
    } catch (error) {
      showErrorMessage(`Error occurred while trying to add location access.`);
    } finally {
      hide();
    }
  };

  const setLocationRoles = async (userId: number, request: SetLocationRolesRequest) => {
    try {
      show();
      const ok = await service.setLocationRoles(userId, request);
      if (ok) {
        showSuccessMessage(`Roles updated successfully.`);
        showManageRolesState?.setter(false);
        refreshData();
      }
    } catch (error) {
      showErrorMessage(`Error occurred while trying to update roles.`);
    } finally {
      hide();
    }
  };
  const setOrganizationRoles = async (userId: number, request: SetOrganizationRolesRequest) => {
    try {
      show();
      const ok = await service.setOrganizationRoles(userId, request);
      if (ok) {
        showSuccessMessage(`Roles updated successfully.`);
        showManageRolesState?.setter(false);
        refreshData();
      }
    } catch (error) {
      showErrorMessage(`Error occurred while trying to update roles.`);
    } finally {
      hide();
    }
  };


  const deleteUserOrganization = async (userId: number, userOrganization: UserEntityModel) => {
    try {
      show();
      const ok = await service.deleteOrganization(userId, userOrganization.id);
      if (ok) showSuccessMessage(`${userOrganization.name} deleted successfully.`);
    } catch (error) {
      throw new Error(`Unable to delete ${userOrganization.name}: ${error}`);
    } finally {
      hide();
      refreshData();
    }
  };


  const deleteUserLocation = async (userId: number, userLocation: UserEntityModel) => {
    try {
      show();
      const ok = await service.deleteLocation(userId, userLocation.id);
      if (ok) showSuccessMessage(`${userLocation.name} deleted successfully.`);
    } catch (error) {
      throw new Error(`Unable to delete ${userLocation.name}: ${error}`);
    } finally {
      hide();
      refreshData();
    }
  };

  return {
    addOrganization,
    addLocation,
    setLocationRoles,
    setOrganizationRoles,
    deleteUserLocation,
    deleteUserOrganization
  };
}
