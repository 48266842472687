import { GridRowClickEvent } from "@progress/kendo-react-grid";
import { MenuSelectEvent } from "@progress/kendo-react-layout";
import { router } from "../app/routes";
import { useSharedState } from "../hooks";
import { useCallback } from "react";

export function useGridActions<T>(route?: string, clickHandler?: (e: GridRowClickEvent) => void) {

  const showAddState = useSharedState(false);
  const showEditState = useSharedState(false);
  const showDeleteState = useSharedState(false);
  const showCloneState = useSharedState(false);

  const editItemState = useSharedState<T>({} as T);
  const deleteItemState = useSharedState<T>({} as T);

  // #region Provider
  const onSelect = (e: MenuSelectEvent, dataItem: any) => {
    switch (e.item.data.action) {
      case "open":
        if(route) {
          router.navigateWithParams(
            route ?? "",
            new Map([["id", dataItem.id]])
          );
        }
        else{
          showEdit(dataItem);
        }
        break;
      case "opentab":
        router.navigateWithParams(
          route ?? "",
          new Map([["id", dataItem.id]]),
          true
        );
        break;
      case "delete":
        showDelete(dataItem);
        break;
    }
  };

  const onClick = (e: GridRowClickEvent, params?: Map<string, string>) => {
    if(route){
      const routeParams = params ?? new Map([["id", e.dataItem.id]]);
      router.navigateWithParams(
        route ?? "",
        routeParams
      );
    }
    else{
      showEdit(e.dataItem);
    }
  };

  const showEdit = useCallback((dataItem: T) => {
    editItemState.setter(dataItem);
    showEditState.setter(true);
  }, [showEditState, editItemState]);

  const showDelete = useCallback((dataItem: any) => {
    deleteItemState.setter(dataItem);
    showDeleteState.setter(true);
  }, [showDeleteState, deleteItemState]);
  // #endregion

  return {
    onSelect,
    onClick,
    showDelete,
    showEdit,
    showAddState,
    showEditState,
    showDeleteState,
    editItemState,
    deleteItemState,
    showCloneState,
  };
}

export default useGridActions;
