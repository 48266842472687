import { useLocation } from "react-router-dom";
import { useAuthentication } from "./hooks";
import TenantModel from "../../data/entities/organization/TenantModel";
import { Button, Icon } from "../../components";
import { motion } from "framer-motion";
import style from "./styles/Account.module.scss"
import { router, routes } from "../../app/routes";

export function TenantSelectionPage() {

  const location = useLocation();
  const { email, password, rememberMe, tenants } = location.state;
  const { loginUser } = useAuthentication();

  if(!tenants || tenants.length === 0) {
    return (
      <motion.div
        initial={{ y: "10px", opacity: 0 }}
        animate={{y: 0, opacity: 1 }}
        exit={{ y: "50%", opacity: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut"}}
      >
        <h2>Oops!</h2>
        <h4 className="fs-5 text-muted mb-4 fw-lighter">It looks like <span className="fw-bold">{email}</span> does not belong to any tenants yet.</h4>
        <Button type="submit" onClick={() => router.navigate(routes.account.login)}><Icon iconName="arrow-left"/> Back</Button>
      </motion.div>
    );
  }

  return (

    <motion.div
      initial={{ y: "10px", opacity: 0 }}
      animate={{y: 0, opacity: 1 }}
      exit={{ y: "50%", opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut"}}
    >
      <h2>Choose a Tenant</h2>
      <h4 className="fs-5 text-muted mb-4 fw-lighter"><span className="fw-bold">{email}</span> is a part of multiple tenants</h4>
      <div className="my-4">
        {tenants.map((tenant: TenantModel) => (
          <div className={style.tenant} onClick={() => loginUser(email, password, rememberMe, tenant.id)}>
            {tenant.name}
          </div>
        ))}
      </div>
      <Button type="submit" onClick={() => router.navigate(routes.account.login)}><Icon iconName="arrow-left"/> Back</Button>
    </motion.div>
  );

}

export default TenantSelectionPage;
