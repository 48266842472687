import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../app/routes";
import { UserService } from "../../../services";
import { showErrorMessage, showSuccessMessage } from "../../../utils";

export function useDeleteUser() {
  const userService = new UserService();
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();

  const deleteUser = async (confirmed: boolean, id: number, userName: string) => {
    if (!confirmed) {
      showErrorMessage(`You must confirm that you want to delete this user.`);
      return;
    }

    setShowSpinner(true);
    try {
      const ok = await userService.delete(id);
      if (!ok) {
        showErrorMessage(`Error deleting user.`);
      } else {
        showSuccessMessage(`Successfully deleted ${userName}.`);
        navigate(routes.user.index);
      }
    } catch (error) {
      showErrorMessage(`Error occurred while trying to execute delete: ${error}`);
    } finally {
      setShowSpinner(false);
    }
  };

  return {
    deleteUser,
    showSpinner
  };
}
