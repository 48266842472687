import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface AuthorizationState {
  policyCache: {
    [policy: string]: boolean;
  };
}

const initialState: AuthorizationState = {
  policyCache: {},
};

const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    updatePolicyCache: (state, action: PayloadAction<{ [policy: string]: boolean }>) => {
      state.policyCache = {
        ...state.policyCache,
        ...action.payload,
      };
    },
    resetAuthorizationState: () => initialState,
  },
});

export const {
  updatePolicyCache,
  resetAuthorizationState,
} = authorizationSlice.actions;
export const selectPolicyCache = (state: RootState) => state.authorization.policyCache;
export default authorizationSlice.reducer;
