import { FloatingLabel, Form, Row, Button as BSButton } from "react-bootstrap";
import { SlideOutModal, SlideOutModalProps, Button } from "../../../components";
import * as yup from "yup";
import { Formik } from "formik";
import ServiceLocationModel from "../../../data/entities/booking/ServiceLocationModel";
import { useRef } from "react";

export interface EditServiceLocationModalProps extends SlideOutModalProps {
  dataItem: ServiceLocationModel;
}

export function EditServiceLocationModal(props: Readonly<EditServiceLocationModalProps>) {
  const submitRef = useRef<HTMLButtonElement>(null);

  const validationSchema = yup.object().shape({
    price: yup.number().required("Price is required").min(0, "Price must be greater than or equal to 0"),
    serviceId: yup.number().required("Service is required"),
  });

  return (
    <SlideOutModal
      {...props}
      title={`${props.dataItem.location?.name}`}
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => {
                  submitRef.current?.click()
                }} variant="primary" className="w-100">
                Save
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues = {{
          price: props.dataItem.price,
          serviceId: props.dataItem.serviceId,
        }}
        onSubmit={(values) => {
          props.onSubmit({
            ...props.dataItem,
            ...values
           } as ServiceLocationModel);
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Form.Group controlId="price">
                  <FloatingLabel label="Price" className="mb-3">
                    <Form.Control
                      type="number"
                      name="price"
                      placeholder="Price"
                      value={values.price}
                      onChange={handleChange}
                      isInvalid={touched.price && !!errors.price}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.price}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <BSButton ref={submitRef} type="submit" className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default EditServiceLocationModal;

