import { routes } from "../../../app/routes";
import { IconDefinitions } from "../../icons";
import logo from "../../../images/branding/logo-blue.svg";

export const useCommonItems = () => [
  {
    text: "Home",
    url: routes.home,
    image: logo,
    className: "lh-1",
    activeMatchUrl: routes.home,
  },
  {
    text: "My Profile",
    url: routes.profile.index,
    icon: IconDefinitions.user,
    activeMatchUrl: [routes.profile.index, routes.profile.changePassword],
  },
];

export default useCommonItems;
