import { routes, router } from "../../../app/routes";

interface BreadcrumbProps {
  showHome: boolean;
  values: { label: string; url: string | null }[];
}

export function generateLocationsBreadcrumbs(
  path: string,
  locationName?: string,
  locationId?: number,
  providerName?: string,
  providerId?: number

): BreadcrumbProps {
  const idMap = new Map([["Id", locationId?.toString() ?? ""]]);
  const providerIdMap = new Map([["id", locationId?.toString() ?? ""], ["providerId", providerId?.toString() ?? ""]]);

  switch (path) {
    case router.getUrl(routes.location.index, idMap):
      return {
        showHome: true,
        values: [{ label: "Locations", url: null }],
      };
    case router.getUrl(routes.location.details, idMap):
      return {
        showHome: true,
        values: [
          { label: "Locations", url: routes.location.index },
          { label: locationName ?? "", url: null },
        ],
      };
    case router.getUrl(routes.location.address, idMap):
      return {
        showHome: true,
        values: [
          { label: "Locations", url: routes.location.index },
          { label: locationName ?? "", url: router.getUrl(routes.location.details, idMap) },
          { label: "Address", url: null },
        ],
      };
    case router.getUrl(routes.location.pricing, idMap):
      return {
        showHome: true,
        values: [
          { label: "Locations", url: routes.location.index },
          { label: locationName ?? "", url: router.getUrl(routes.location.details, idMap) },
          { label: "Pricing", url: null },
        ],
      }
    case router.getUrl(routes.location.provider.index, idMap):
      return {
        showHome: true,
        values: [
          { label: "Locations", url: routes.location.index },
          { label: locationName ?? "", url: router.getUrl(routes.location.details, idMap) },
          { label: "Providers", url: null },
        ],
      }
    case router.getUrl(routes.location.provider.details, providerIdMap):
      return {
        showHome: true,
        values: [
          { label: "Locations", url: routes.location.index },
          { label: locationName ?? "", url: router.getUrl(routes.location.details, idMap) },
          { label: "Providers", url: router.getUrl(routes.location.provider.index, idMap) },
          { label: providerName ?? "", url: null },

        ],
      }
    case router.getUrl(routes.location.provider.services, providerIdMap):
      return {
        showHome: true,
        values: [
          { label: "Locations", url: routes.location.index },
          { label: locationName ?? "", url: router.getUrl(routes.location.details, idMap) },
          { label: "Providers", url: router.getUrl(routes.location.provider.index, idMap) },
          { label: providerName ?? "", url: router.getUrl(routes.location.provider.details, idMap) },
          { label: "Services", url: null },

        ],
      }
    case router.getUrl(routes.location.provider.schedule, providerIdMap):
      return {
        showHome: true,
        values: [
          { label: "Locations", url: routes.location.index },
          { label: locationName ?? "", url: router.getUrl(routes.location.details, idMap) },
          { label: "Providers", url: router.getUrl(routes.location.provider.index, idMap) },
          { label: providerName ?? "", url: router.getUrl(routes.location.provider.details, idMap) },
          { label: "Schedule", url: null },

        ],
      }

    default:
      return { showHome: false, values: [] };
  }
}

export default generateLocationsBreadcrumbs;

