import { MenuItem } from "@progress/kendo-react-layout"
import { TitlelessCard, ODataGrid, Breakpoints, ConfirmationModal, IconAndText, IconDefinitions, ResponsiveGridColumn, Button, Icon } from "../../components"
import { useCallback } from "react";
import { usePageActions } from "../PageLayout";
import { AddServiceTypeModal } from "./components";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useCRUDHandlers, useGridActions, useSetPageTitle, useSharedState } from "../../hooks";
import { routes } from "../../app/routes";
import ServiceTypeModel from "../../data/entities/booking/ServiceTypeModel";
import { ServiceTypeService } from "../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../app";
import usePermission from "../../hooks/usePermission";
import { PermissionDefinitions } from "../../security";

export function ServiceTypesIndexPage() {

  const gridData = useSharedState<Array<ServiceTypeModel>>([]);
  const serviceTypeService = new ServiceTypeService();

  const { onClick, onSelect, showAddState, showDeleteState, deleteItemState, showDelete } = useGridActions<ServiceTypeModel>(routes.serviceType.details);
  const { gridRef, getItems, addItem, deleteItem } = useCRUDHandlers<ServiceTypeModel>(serviceTypeService, "Service Type", showAddState);

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canAddServiceType = usePermission(PermissionDefinitions.serviceType.create(tenantId!));
  const canDeleteServiceType = usePermission(PermissionDefinitions.serviceType.delete(tenantId!));

  useSetPageTitle("Service Types", []);
  usePageActions(
    canAddServiceType && (
      <Button onClick={() => {showAddState.setter(true);}}>
        <IconAndText iconName={IconDefinitions.add} text="Add" />
      </Button>
    ),
    [canAddServiceType]
  );

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <>
        {canDeleteServiceType  && (
          <td className="text-center">
            <Icon
              iconName={IconDefinitions.delete}
              className="text-danger"
              onClick={() => showDelete(props.dataItem)}
            />
          </td>
        )}
      </>
    ),
    [showDelete, canDeleteServiceType]
  );

  return (
    <TitlelessCard bodyClassName="p-0">
      <ODataGrid
        ref={gridRef}
        getData={(oDataQuery) => getItems(`${oDataQuery}`)}
        dataState={gridData}
        onRowClick={onClick}
        onRowMenuSelect={onSelect}
        sort={[{ field: "name", dir: "desc"}]}
        scrollable="scrollable"
        pageSize={10}
      >
        <ResponsiveGridColumn
          title="Name"
          field="name"
          sortable={true}
        />
        <ResponsiveGridColumn
          title="Description"
          field="description"
          sortable={true}
        />
        <ResponsiveGridColumn
          className="column-url"
          field="url"
          title=" "
          sortable={false}
          filterable={false}
          headerCell={() => null}
          cell={canDeleteServiceType == true ? commandsCell : undefined}
          width={50}
          breakpoint={Breakpoints.sm}
        />
        <MenuItem
          text="Open"
          data={{ action: "open" }}
          icon="hyperlink-open"
        />
        <MenuItem
          text="Open Tab"
          data={{ action: "opentab" }}
          icon="windows"
        />
        {canDeleteServiceType && (
          <MenuItem
            text="Delete"
            data={{ action: "delete" }}
            icon={IconDefinitions.delete}
          />
        )}
      </ODataGrid>
      <AddServiceTypeModal
        showState={showAddState}
        showAddState={showAddState}
        onSubmit={addItem}
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Delete ${deleteItemState.value?.name}`}
        message={`You are about to delete this service type, it cannot be undone. Are you sure you want to delete ${deleteItemState.value.name}?`}
        confirmText="Delete"
        onConfirm={() => deleteItem(deleteItemState.value)}
      />
    </TitlelessCard>
  )
}

export default ServiceTypesIndexPage
