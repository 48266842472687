import { usePageActions } from "../PageLayout";
import { useCallback } from "react";
import { ODataGrid, IconAndText, IconDefinitions, TitlelessCard, Breakpoints, ConfirmationModal, ResponsiveGridColumn, Icon, Button } from "../../components";
import { MenuItem } from "@progress/kendo-react-layout";
import { GridCellProps } from "@progress/kendo-react-grid";
import AddLocationModal from "./components/AddLocationModal";
import { useCRUDHandlers, useGridActions, useQueryParams, useSetPageTitle, useSharedState } from "../../hooks";
import { LocationModel } from "../../data/entities";
import { LocationService, UserService } from "../../services";
import { routes } from "../../app/routes";
import usePermission from "../../hooks/usePermission";
import { PermissionDefinitions } from "../../security";
import { useSelector } from "react-redux";
import { RootState } from "../../app";

export function LocationsIndexPage() {
  const { getQueryParam } = useQueryParams();
  const organizationFilterId = getQueryParam("organizationFilterId");
  const gridData = useSharedState<Array<LocationModel>>([]);
  const locationService = new LocationService();
  const { onClick, onSelect, showAddState, showDeleteState, deleteItemState, showDelete } = useGridActions<LocationModel>(routes.location.details);
  const { gridRef, getItems, addItem, deleteItem } = useCRUDHandlers<LocationModel>(locationService, "Location", showAddState);
  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canAddLocation = usePermission(PermissionDefinitions.location.create(tenantId!));
  const canDeleteLocation = usePermission(PermissionDefinitions.location.delete(tenantId!));

  useSetPageTitle("Locations", []);
  usePageActions(
    canAddLocation && (
      <Button
        onClick={() => {
          showAddState.setter(true);
        }}
      >
        <IconAndText iconName={IconDefinitions.add} text="Add" />
      </Button>
    )
    , [canAddLocation]);

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <>
      {canDeleteLocation && (
        <td className="text-center">
          <Icon
            iconName={IconDefinitions.delete}
            className="text-danger"
            onClick={() => showDelete(props.dataItem)}
          />
        </td>
      )}
      </>
    ),
    [showDelete, canDeleteLocation]
  );

  return (
    <TitlelessCard bodyClassName="p-0">
      <ODataGrid
        ref={gridRef}
        getData={(oDataQuery) => getItems(`${oDataQuery}&$expand=Organization${!organizationFilterId ? "": `&$filter=organizationId eq ${organizationFilterId}`}`) }
        dataState={gridData}
        onRowClick={onClick}
        onRowMenuSelect={onSelect}
        sort={[{ field: "name", dir: "desc"}]}
        scrollable="scrollable"
        pageSize={10}
      >
        <ResponsiveGridColumn
          title="Name"
          field="name"
          sortable={true}
        />
        <ResponsiveGridColumn
          title="Business Code"
          field="businessCode"
          sortable={true}
        />
        <ResponsiveGridColumn
          title="Organization"
          field="organization.name"
          sortable={true}
        />
        <ResponsiveGridColumn
          className="column-url"
          field="url"
          title=" "
          sortable={false}
          filterable={false}
          headerCell={() => null}
          cell={canDeleteLocation == true ? commandsCell : undefined}
          width={50}
          breakpoint={Breakpoints.sm}
        />
        <MenuItem
          text="Open"
          data={{ action: "open" }}
          icon="hyperlink-open"
        />
        <MenuItem
          text="Open Tab"
          data={{ action: "opentab" }}
          icon="windows"
        />
        {canDeleteLocation && (
          <MenuItem
            text="Delete"
            data={{ action: "delete" }}
            icon={IconDefinitions.delete}
          />
        )}
      </ODataGrid>
      <AddLocationModal
        showState={showAddState}
        showAddState={showAddState}
        onSubmit={addItem}
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Delete ${deleteItemState.value?.name}`}
        message={`You are about to delete this location, it cannot be undone. Are you sure you want to delete ${deleteItemState.value.name}?`}
        confirmText="Delete"
        onConfirm={() => deleteItem(deleteItemState.value)}
      />
    </TitlelessCard>
  );
}

export default LocationsIndexPage;
