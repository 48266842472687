import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFontAwesomeIcon } from "./IconDefinitions";

interface IconAndTextProps extends React.PropsWithChildren {
  iconName?: string;
  text: string | null;
  image?: string;
  className?: string;
  textClassName?: string;
}

export function IconAndText(props: IconAndTextProps) {
  let i, span, image;

  if (props.iconName) {
    const iconObject = getFontAwesomeIcon(props.iconName);
    i = <FontAwesomeIcon icon={iconObject} className="fa text-md fa-fw" />;
  }
  if(props.image) {
    image = <img src={props.image} alt="logo" width={30} />;
  }

  if (props.text) {
    span = props.text;
  }

  return (
    <span className={`icon-and-text d-flex align-items-center ${props.className ?? ""}`}>
      {image}
      {i}
      <span className={`${props.textClassName} ms-1`}>{span}</span>
    </span>
  );
}

export default IconAndText;

