import { AddressEditor, addressValidationSchema, Button, IconAndText, IconDefinitions, Card, SpinnerDefault } from "../../components";
import * as yup from "yup";
import { Formik } from "formik";
import { Form, Button as BSButton } from "react-bootstrap";
import { AddressModel } from "../../data/entities";
import { useCRUDHandlers, useSetPageTitle, useUrlParams } from "../../hooks";
import { AddressService, LocationService } from "../../services";
import useAsync from "../../hooks/useAsync";
import { usePageActions } from "../PageLayout";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app";
import usePermission from "../../hooks/usePermission";
import { PermissionDefinitions } from "../../security";

export function LocationAddressPage() {

  const id = parseInt(useUrlParams("id"));
  const addressService = new AddressService();
  const { editItem } = useCRUDHandlers<AddressModel>(addressService, "Address");
  const submitRef = useRef<HTMLButtonElement>(null);
  const { value: location } = useAsync(() => new LocationService().get(id), [id]);
  const { value: address, error, loading } = useAsync(() => new AddressService().get(location?.addressId!), [location?.addressId]);

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canEditLocation = usePermission(PermissionDefinitions.location.edit(tenantId!));

  const validationSchema = yup.object().shape({
    address: addressValidationSchema
  });

  useSetPageTitle("Address", []);
  usePageActions(
    canEditLocation && (
      <Button onClick={() => submitRef.current?.click()}>
        <IconAndText iconName={IconDefinitions.save} text="Save" />
      </Button>
    ),
    [canEditLocation]
  );

  if (error) return <div>Error loading address: {error.message}</div>;
  if (!address || loading) return <SpinnerDefault />;

  return (
    <Card title="Address">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          address: {
            streetAddress: address.streetAddress,
            streetAddress2: address.streetAddress2 ?? "",
            city: address.city,
            postalCode: address.postalCode,
            regionId: address.regionId,
          },
        }}
        onSubmit={(values) => {
          const result = {
            ...address,
            streetAddress: values.address.streetAddress,
            streetAddress2: values.address.streetAddress2,
            city: values.address.city,
            postalCode: values.address.postalCode,
            regionId: values.address.regionId,
          } as AddressModel;
          editItem(result);
        }}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit} noValidate>
              <AddressEditor disabled={!canEditLocation} />
              <BSButton ref={submitRef} type="submit" className="d-none" />
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
}

export default LocationAddressPage;

