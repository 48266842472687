import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFontAwesomeIcon } from "./";
import React from "react";

interface IconProps {
  iconName: string;
  className?: string;
  onClick?: (e?: React.MouseEvent) => void;
}

export function Icon(props: IconProps) {
  const { iconName } = props;
  const iconObject = getFontAwesomeIcon(iconName);
  return (
    <FontAwesomeIcon
      icon={iconObject}
      className={`fa far fa-fw ${props.className}`}
      onClick={props.onClick}
    />
  );
}

export default Icon;

