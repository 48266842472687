import React, { useState } from "react";
import style from "./styles/TopbarSubmenuItem.module.scss";
import IconAndText from "../../icons/IconAndText";
import { UrlService } from "../../../services";
import { Link } from "../..";
import { v4 as uuidv4 } from "uuid";
export interface SubmenuItem {
  label: string;
  url: string;
  params?: Map<string, string>;
  icon?: string;
  activeMatch?: string | string[];
  activeMatchExact?: boolean;
  ifActive?: string;
  innerItems?: Array<SubmenuItem>;
  clickHandler?: () => void;
}

export interface TopbarSubmenuItemProps extends React.PropsWithChildren {
  item: SubmenuItem;
  handleClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export function TopbarSubmenuItem(props: TopbarSubmenuItemProps) {
  const {
    label,
    url,
    params,
    icon,
    activeMatch,
    activeMatchExact,
    innerItems,
    clickHandler,
    ifActive,
  } = props.item;

  const [showSubMenu, setShowSubMenu] = useState(false);

  let extraClasses = "";
  if (activeMatch) {
    extraClasses += UrlService.isUrlActive(
      activeMatch,
      activeMatchExact ?? true
    )
      ? "active border-bottom border-primary border-2"
      : "";
  }

  let rootComponent: React.JSX.Element;

  const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
    //The timeout is needed to allow the navigation event to fire first
    setTimeout(() => {
      setShowSubMenu(false);
    }, 200);
  };

  if (innerItems) {
    extraClasses += " p-0 ";
    rootComponent = (
      <div onBlur={handleBlur}>
        <button
          className="dropdown-toggle"
          type="button"
          id={uuidv4()}
          title={label}
          onClick={(e) => {
            e.preventDefault();
            setShowSubMenu(!showSubMenu);
          }}
          data-bs-toggle="dropdown"
          aria-expanded="true"
        >
          <IconAndText iconName={""} text={label}></IconAndText>
        </button>
        <ul
          className={`dropdown-menu bg-light ${
            showSubMenu ? "d-block" : ""
          }`}
        >
          {innerItems.map((item) => (
            <TopbarSubmenuItem key={uuidv4()} item={item}></TopbarSubmenuItem>
          ))}
        </ul>
      </div>
    );
  } else {
    rootComponent = (
      <Link
        url={!clickHandler ? url : "#"}
        params={params}
        className="btn"
      >
        <IconAndText iconName={""} text={label}></IconAndText>
      </Link>
    );
  }

  return (
    <li
      className={`${style.submenuitem} nav-item py-1 rounded-0 ${extraClasses} ${ifActive}`} onClick={clickHandler}
    >
      {rootComponent}
    </li>
  );
}

export default TopbarSubmenuItem;

