import HttpClient from "../../../api/HttpClient";
import { InviteRequest } from "../../../models";
import { UserInviteModel } from "../../entities";
import BaseRepository from "../BaseRepository";

export class UserInviteRepo extends BaseRepository{
  protected client = new HttpClient();
  protected baseUrl = 'userinvite';

  async insert(request: InviteRequest): Promise<UserInviteModel> {
    const response = await this.client.post(`${this.baseUrl}`, request);
    return this.handleResponse<UserInviteModel>(response)!;
  }

  async delete(id: number): Promise<boolean> {
    const response = await this.client.delete(`${this.baseUrl}/${id}`);
    return this.handleResponse<boolean>(response)!;
  }

}

export default UserInviteRepo;
