import IconAndText from "../icons/IconAndText";

interface ActionItemProps {
  text: string;
  icon: string;
  onClick: () => void;
  additionalClass?: string;
}

export function ActionItem(props: ActionItemProps) {

  return (
    <li className={`nav-item ${props.additionalClass ?? ""}`}>
      <span className="nav-link" title={props.text} onClick={props.onClick}>
        <IconAndText textClassName="ms-3 overflow-hidden text-nowrap" iconName={props.icon} text={props.text}></IconAndText>
      </span>
    </li>
  );
}

export default ActionItem;
