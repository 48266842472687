import { routes } from "../../../app/routes";
import { Role } from "../../../models";
import { UserService } from "../../../services";
import { IconDefinitions } from "../../icons";

// Helper function to check if user has any of the required roles
export const userHasRoles = (requiredRoles: Role[]) => {
  return UserService.isInRoles(requiredRoles);
};

export const useMenuItems = () => {
  const menuItems = [
    {
      text: "Users",
      url: routes.user.index,
      icon: IconDefinitions.users,
      activeMatchUrl: [
        routes.user.index,
        routes.user.details,
        routes.user.access,
        routes.user.delete,
        routes.user.invite,
      ],
      roles: [Role.administrator, Role.tenant_admin, Role.owner, Role.manager],
    },
    {
      text: "Service Types",
      url: routes.serviceType.index,
      icon: IconDefinitions.gear,
      activeMatchUrl: [
        routes.serviceType.index,
        routes.serviceType.details,
        routes.serviceType.service,
      ],
      roles: [Role.administrator, Role.tenant_admin, Role.owner, Role.manager],
    },
    {
      text: "Locations",
      url: routes.location.index,
      icon: IconDefinitions.location,
      activeMatchUrl: [
        routes.location.index,
        routes.location.details,
        routes.location.address,
        routes.location.pricing,
        routes.location.provider.index,
        routes.location.provider.details,
        routes.location.provider.services,
        routes.location.provider.schedule,
      ],
      roles: [Role.administrator, Role.tenant_admin, Role.owner, Role.manager],
    },
    {
      text: "Organizations",
      url: routes.organization.index,
      icon: IconDefinitions.organization,
      activeMatchUrl: [
        routes.organization.index,
        routes.organization.details,
      ],
      roles: [Role.administrator, Role.tenant_admin, Role.owner, Role.manager],
    },
  ];

  return menuItems.filter(item => userHasRoles(item.roles));
};

export default useMenuItems;
