import React, { PropsWithChildren, useEffect, useState } from 'react'
import {
  DropDownListBlurEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import DropdownOption from './DropdownOption';
import SearchableDropdown from './SearchableDropdown';
import { ServiceService } from '../../services';
import ServiceModel from '../../data/entities/booking/ServiceModel';

interface SearchableServiceDropdownProps extends PropsWithChildren {
  value?: any;
  name: string;
  isInvalid?: boolean;
  onBlur?: (e: DropDownListBlurEvent) => void;
  onChange?: (e: DropDownListChangeEvent) => void;
  onSelect?: (value: DropdownOption) => void;
  placeholder?: string;
}

export const SearchableServiceDropdown = React.forwardRef(
  (
    props: Readonly<SearchableServiceDropdownProps>,
    ref: React.ForwardedRef<HTMLSelectElement | null>
  ) => {
    const serviceService = new ServiceService();
    const [options, setOptions] = useState<DropdownOption[]>([]);

    useEffect(() => {
      const fetchData = async () => {
        let services = await serviceService.getAll();

        const newOptions = services.map<DropdownOption>((service: ServiceModel) => ({
          value: service.id?.toString() ?? "",
          text: `${service.name}`,
        }));

        setOptions(newOptions);
      };

      fetchData();
    }, []);

    const getValue = () => {
      if (props.value) {
        return options.find((option) => option.value === props.value?.toString());
      }
    }

    return (
      <SearchableDropdown
        defaultItem={{ text: "Select a Service", value: "" }}
        label="Service"
        name={props.name}
        options={options}
        value={getValue()}
        onChange={props.onChange}
        onSelect={props.onSelect}
        isInvalid={props.isInvalid}
      >
        {props.children}
      </SearchableDropdown>
    );
  }
);

export default SearchableServiceDropdown
