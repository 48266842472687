import React from "react";
import style from "./styles/TopbarSubmenu.module.scss";
import { TopbarSubmenuItem, SubmenuItem } from "./TopbarSubmenuItem";
import { getUuid } from "../../../utils";

interface TopbarSubmenuProps extends React.PropsWithChildren {
  items?: SubmenuItem[];
  className?: string;
}

export function TopbarSubmenu(props: TopbarSubmenuProps) {
  const itemsList = [...(props.items ?? [])];

  return (
    <ul className={`topbar ${style.submenu} shadow-sm border m-2 ps-3 mx-3 ${style.nav} ${props.className} `}>
      {itemsList.map((item) => (
        <TopbarSubmenuItem key={getUuid()} item={item}/>
      ))}
    </ul>
  );
}

export default TopbarSubmenu;