import { BaseEntityService } from "../BaseEntityService"
import { TenantModel } from "../../data/entities";
import { TenantODataRepo } from "../../data/repo/organization/TenantODataRepo";
import TenantRepo from "../../data/repo/organization/TenantRepo";
import { store } from "../../app/store/store";


export class TenantService extends BaseEntityService<TenantModel, TenantRepo, TenantODataRepo> {
  constructor(){
    super(new TenantRepo(), new TenantODataRepo());
  }

  static readonly getTenantId = () => store.getState().authentication.tenantId ?? -1;

}

export default TenantService;
