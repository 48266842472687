import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { PageBreadcrumbProps, PageLayout } from "../../PageLayout"
import ServiceTypesTopbar from "./ServiceTypesIndexTopbar";
import { generateServiceTypesBreadcrumbs } from "./ServiceTypesBreadcrumbs";
import { BreadcrumbProps } from "../../../components";
import { PageTitleProvider, usePageTitle } from "../../../hooks";

function BaseLayout() {

  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const { title } = usePageTitle();

  useEffect(() => {
    const newBreadcrumbs:BreadcrumbProps = generateServiceTypesBreadcrumbs(location.pathname);
    setBreadcrumbs(newBreadcrumbs);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <PageLayout topbar={<ServiceTypesTopbar />} title={title} breadcrumbs={breadcrumbs}>
      <Outlet />
    </PageLayout >
  )
}

export function ServiceTypesIndexLayout() {
  // Wrap the BaseUserLayout in a PageTitleProvider.
  // This prevents an error of the context not being initialized when the usePageTitle hook is called.
  return (
    <PageTitleProvider>
      <BaseLayout />
    </PageTitleProvider>
  );
}

export default ServiceTypesIndexLayout

