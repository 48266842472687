import {
  Button,
  SlideOutModal,
  SlideOutModalProps,
} from "../../../components";
import { Button as BSButton, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import * as yup from 'yup';
import { Formik } from 'formik';
import { useRef } from "react";
import { ScheduleType } from "../../../data/entities/booking/ScheduleType";

export interface AddScheduleModalProps extends SlideOutModalProps {
  onSubmit: any;
  providerId: number;
  scheduleType: ScheduleType;
  label: string;
}

export function AddScheduleModal(props: Readonly<AddScheduleModalProps>) {

  const submitRef = useRef<HTMLButtonElement>(null);

  const validationSchema = yup.object().shape({
    providerId: yup.number().required(),
    scheduleType: yup
      .mixed<ScheduleType>()
      .oneOf([ScheduleType.Default, ScheduleType.Override])
      .required(),
    effectiveDate: yup.date().required('Effective Date is required'),
    effectiveEndDate: yup.date().when('scheduleType', (scheduleType: ScheduleType | any[], schema) => {
      return (Object.values(ScheduleType) as ScheduleType[]).includes(scheduleType as ScheduleType)
        ? schema.required("Effective End Date is required")
        : schema.notRequired();
    })
  });

  return (
    <SlideOutModal
      {...props}
      title={`Add ${props.label}`}
      size="lg"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => {
                console.log("clicked visible button");
                submitRef.current?.click()
              }} variant="primary" className="w-100">
                Save
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          providerId: props.providerId,
          scheduleType: props.scheduleType,
          effectiveDate: new Date().toISOString().split('T')[0],
          effectiveEndDate: props.scheduleType === ScheduleType.Override ? new Date().toISOString().split('T')[0] : null,
          scheduleBlocks: [],
        }}
        onSubmit={(values) => {
          const schedule = {
            providerId: values.providerId,
            scheduleType: values.scheduleType,
            effectiveDate: values.effectiveDate,
            effectiveEndDate: values.effectiveEndDate,
            scheduleBlocks: values.scheduleBlocks
          }
          props.onSubmit(schedule);
        }}
      >
        {({ handleSubmit, errors, values, touched, handleChange }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="effectiveDate">
                    <FloatingLabel label="Effective Date" className="mb-3">
                      <Form.Control
                        type="date"
                        name="effectiveDate"
                        placeholder="Effective Date"
                        value={values.effectiveDate}
                        onChange={handleChange}
                        isInvalid={touched.effectiveDate && !!errors.effectiveDate}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.effectiveDate}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  {props.scheduleType === ScheduleType.Override && (
                    <Form.Group controlId="effectiveEndDate">
                      <FloatingLabel label="Effective End Date" className="mb-3">
                        <Form.Control
                          type="date"
                          name="effectiveEndDate"
                          placeholder="Effective End Date"
                          value={values.effectiveEndDate ?? ''}
                          onChange={handleChange}
                          isInvalid={touched.effectiveEndDate && !!errors.effectiveEndDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.effectiveEndDate}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <BSButton ref={submitRef} type="submit" onClick={()=>{
                console.log("clicked hidden button");
                console.log(errors);
              }}className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default AddScheduleModal;
