import { routes } from "../../../app/routes";
import {
  TopbarSubmenu,
  IconDefinitions,
  SubmenuItem,
} from "../../../components";
import { useEffect, useMemo, useState } from "react";
import { useUrlParams } from "../../../hooks";

export const ServiceTypesIndexTobpar = (props: any) => {

  const returnUrl = useUrlParams("returnUrl");
  const id = useUrlParams("id");

  const defaultSubmenuItems: SubmenuItem[] = useMemo(
    (): SubmenuItem[] => [
      {
        label: "Service Types",
        icon: IconDefinitions.hand,
        url: routes.serviceType.index,
        activeMatch: routes.serviceType.index,
        activeMatchExact: true,
        params: new Map([["id", id]]),
      },
    ],
    [id]
  );

  useEffect(() => {
    const backItem: SubmenuItem = {
      label: "Back",
      icon: IconDefinitions.back,
      url: returnUrl ?? "",
    };

    if (returnUrl) {
      setSubmenuItems([backItem, ...defaultSubmenuItems]);
    } else {
      setSubmenuItems(defaultSubmenuItems);
    }
  }, [defaultSubmenuItems, id, returnUrl]);

  const [submenuItems, setSubmenuItems] =
    useState<SubmenuItem[]>(defaultSubmenuItems);

  return <TopbarSubmenu className="p-0" items={submenuItems} />;
};

export default ServiceTypesIndexTobpar;

