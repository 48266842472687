import { useRef } from "react";
import {
  Button,
  SlideOutModal,
  SearchableLocationDropdown,
  SlideOutModalProps,
  HandleDropDownListChange,
} from "../../../components";
import * as yup from "yup";
import { Formik } from "formik";
import { Form, Row, Col, Button as BSButton } from "react-bootstrap";
import { sharedProperty, useUrlParams } from "../../../hooks";
import { AddLocationRequest } from "../../../models";

export interface AddUserLocationModalProps extends SlideOutModalProps {
  showAddState: sharedProperty<boolean>;
  onSubmit: any;
}

export function AddUserLocationModal(props: Readonly<AddUserLocationModalProps>) {

  const userId = useUrlParams("id");
  const submitRef = useRef<HTMLButtonElement>(null);
  const validationSchema = yup.object().shape({
    locationId: yup.number().required("Location is required"),
  });

  return (
    <SlideOutModal
      {...props}
      title="Add Location"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => submitRef.current?.click()} variant="primary" className="w-100">
                Add
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          locationId: null
        }}
        onSubmit={async (values) => {
          const request = {
            ...values
          };
          props.onSubmit(parseInt(userId), request as AddLocationRequest);
        }}
      >
        {({ values, handleSubmit, setFieldValue, touched, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="locationId">
                    <SearchableLocationDropdown
                      name="locationId"
                      value={values.locationId}
                      onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                      isInvalid={touched.locationId && !!errors.locationId}
                    >
                      <Form.Control.Feedback type="invalid">
                        {errors.locationId}
                      </Form.Control.Feedback>
                    </SearchableLocationDropdown>
                  </Form.Group>
                </Col>
              </Row>
              <BSButton ref={submitRef} type="submit" size="lg" className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default AddUserLocationModal;
