export enum RequirementName {
  LocationAccess = 'LocationAccess',
  LocationOwner = 'LocationOwner',
  LocationOrganizationOwner = 'LocationOrganizationOwner',
  LocationTenantAdministrator = 'LocationTenantAdministrator',
  OrganizationAccess = 'OrganizationAccess',
  OrganizationOwner = 'OrganizationOwner',
  OrganizationTenantAdministrator = 'OrganizationTenantAdministrator',
  TenantAdministrator = 'TenantAdministrator',
}

export const PermissionDefinitions = {
  location: {
    get: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    create: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    edit: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    delete: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
  },
  provider: {
    get: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    create: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    edit: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    delete: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
  },
  serviceType: {
    get: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    create: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    edit: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    delete: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
  },
  service: {
    get: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    create: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    edit: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    delete: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
  },
  organization: {
    get: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    create: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    edit: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    delete: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
  },
  user: {
    get: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    create: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    edit: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    delete: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
  },
  access: {
    get: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    create: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    edit: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
    delete: (id: string | number) => generateTenantPolicy(id, RequirementName.TenantAdministrator),
  }
};

// #region Policy String Generators
const separator = '|';

const generateSystemAdministratorPolicy = (): string => {
  return 'SystemAdministrator';
};

const generateTenantPolicy = (
  idParameterName: string | number,
  requirementName: string
): string => {
  return `Tenant${separator}${idParameterName}${separator}${'Inline'}${separator}${requirementName}`;
};

const generateOrganizationPolicy = (
  idParameterName: string | number,
  requirementName: string
): string => {
  return `Organization${separator}${idParameterName}${separator}${'Inline'}${separator}${requirementName}`;
};

const generateLocationPolicy = (
  idParameterName: string | number,
  requirementName: string
): string => {
  return `Location${separator}${idParameterName}${separator}${'Inline'}${separator}${requirementName}`;
};
// #endregion

export {
  generateSystemAdministratorPolicy,
  generateTenantPolicy,
  generateOrganizationPolicy,
  generateLocationPolicy,
};
