import {
  Button,
  AddressEditor,
  addressValidationSchema,
  SlideOutModal,
  SlideOutModalProps,
  SearchableOrganizationDropdown
} from "../../../components";
import { Button as BSButton, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import * as yup from 'yup';
import { Formik } from 'formik';
import { useRef } from "react";
import { HandleDropDownListChange } from "../../../components/SearchableDropdown";

export interface AddLocationModalProps extends SlideOutModalProps {
  onSubmit: any;
}

export function AddLocationModal(props: Readonly<AddLocationModalProps>) {

  const submitRef = useRef<HTMLButtonElement>(null);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    businessCode: yup.string().notRequired(),
    organizationId: yup.number().required("Organization is required"),
    address: addressValidationSchema,
  });

  return (
    <SlideOutModal {...props} title="Add New Location"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => submitRef.current?.click()} variant="primary" className="w-100">
                Add
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: '',
          businessCode: '',
          organizationId: null,
          address: {
            streetAddress: '',
            streetAddress2: null,
            city: '',
            postalCode: '',
            regionId: null,
          },
        }}
        onSubmit={async (values) => {
          const location = {
            name: values.name,
            businessCode: values.businessCode,
            organizationId: values.organizationId,
            address: values.address
          };
          props.onSubmit(location);
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue, touched, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FloatingLabel label="Location Name" className="mb-3">
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Location Name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FloatingLabel label="Business Code" className="mb-3">
                    <Form.Control
                      type="text"
                      name="businessCode"
                      placeholder="Business Code"
                      value={values.businessCode}
                      onChange={handleChange}
                      isInvalid={touched.businessCode && !!errors.businessCode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.businessCode}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="organizationId">
                    <SearchableOrganizationDropdown
                      name="organizationId"
                      value={values.organizationId}
                      onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                      isInvalid={touched.organizationId && !!errors.organizationId}
                    >
                      <Form.Control.Feedback type="invalid">
                        {errors.organizationId}
                      </Form.Control.Feedback>
                    </SearchableOrganizationDropdown>
                  </Form.Group>
                </Col>
              </Row>

              <hr className="mb-4 p-0"/>

              <AddressEditor/>

              <BSButton ref={submitRef} type="submit" onClick={() => {
                console.log("submitting", errors);
              }} className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default AddLocationModal;
