import { BaseEntityService } from "../BaseEntityService"
import { ServiceTypeModel } from "../../data/entities/booking/ServiceTypeModel";
import { ServiceTypeODataRepo, ServiceTypeRepo } from "../../data/repo";

export class ServiceTypeService extends BaseEntityService<ServiceTypeModel, ServiceTypeRepo, ServiceTypeODataRepo> {
  constructor(){
    super(new ServiceTypeRepo(), new ServiceTypeODataRepo());
  }
}

export default ServiceTypeService;
