import { useRef } from "react";
import {
  Button,
  HandleDropDownListChange,
  SearchableOrganizationDropdown,
  SlideOutModal,
  SlideOutModalProps,
} from "../../../components";
import * as yup from "yup";
import { Formik } from "formik";
import { Form, Row, Col, Button as BSButton } from "react-bootstrap";
import { sharedProperty, useUrlParams } from "../../../hooks";
import { AddOrganizationRequest } from "../../../models";

export interface AddUserOrganizationModalProps extends SlideOutModalProps {
  showAddState: sharedProperty<boolean>;
  onSubmit: any;
}

export function AddUserOrganizationModal(props: Readonly<AddUserOrganizationModalProps>) {

  const userId = useUrlParams("id");
  const submitRef = useRef<HTMLButtonElement>(null);
  const validationSchema = yup.object().shape({
    organizationId: yup.number().required("Organization is required"),
  });


  return (
    <SlideOutModal
      {...props}
      title="Add Organization"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => submitRef.current?.click()} variant="primary" className="w-100">
                Add
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          organizationId: null
        }}
        onSubmit={async (values) => {
          const request = {
            ...values
          };
          props.onSubmit(parseInt(userId), request as AddOrganizationRequest);
        }}
      >
        {({ values, handleSubmit, setFieldValue, touched, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="organizationId">
                    <SearchableOrganizationDropdown
                      name="organizationId"
                      value={values.organizationId}
                      onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                      isInvalid={touched.organizationId && !!errors.organizationId}
                    >
                      <Form.Control.Feedback type="invalid">
                        {errors.organizationId}
                      </Form.Control.Feedback>
                    </SearchableOrganizationDropdown>
                  </Form.Group>
                </Col>
              </Row>
              <BSButton ref={submitRef} type="submit" size="lg" className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default AddUserOrganizationModal;
