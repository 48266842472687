import { routes } from "../../../app/routes";
import {
  TopbarSubmenu,
  IconDefinitions,
  SubmenuItem,
} from "../../../components";
import { useMemo } from "react";
import { useQueryParams, useUrlParams } from "../../../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../app";
import usePermission from "../../../hooks/usePermission";
import { PermissionDefinitions } from "../../../security";

export const LocationTopbar = (props: any) => {
  const id = useUrlParams("id");
  const { getQueryParam } = useQueryParams();
  const returnUrl = getQueryParam("returnurl");

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canGetLocation = usePermission(PermissionDefinitions.location.edit(tenantId!));

  const submenuItems: SubmenuItem[] = useMemo((): SubmenuItem[] => {
    const items: SubmenuItem[] = [
      {
        label: "Back",
        icon: IconDefinitions.back,
        url: returnUrl ?? routes.location.index,
      },
      {
        label: "Details",
        icon: IconDefinitions.squareInfo,
        url: routes.location.details,
        activeMatch: routes.location.details,
        activeMatchExact: true,
        params: new Map([["id", id]]),
      },
      {
        label: "Address",
        icon: IconDefinitions.mapMarker,
        url: routes.location.address,
        activeMatch: routes.location.address,
        activeMatchExact: true,
        params: new Map([["id", id]]),
      },
      {
        label: "Providers",
        icon: IconDefinitions.hand,
        url: routes.location.provider.index,
        activeMatch: [
          routes.location.provider.index,
          routes.location.provider.details,
          routes.location.provider.services,
          routes.location.provider.schedule,
        ],
        params: new Map([["id", id]]),
      },
    ];

    if (canGetLocation) {
      items.push(
        {
          label: "Pricing",
        icon: IconDefinitions.moneyBill,
        url: routes.location.pricing,
        activeMatch: routes.location.pricing,
        activeMatchExact: true,
        params: new Map([["id", id]]),
        },
      );
    }

    return items;
  }, [id, canGetLocation]);

  return <TopbarSubmenu className="p-0" items={submenuItems}></TopbarSubmenu>;
};

export default LocationTopbar;

