import { useParams } from "react-router-dom";
import {
  Button,
  IconAndText,
  IconDefinitions,
  Card,
  SpinnerDefault,
} from "../../components";
import { UrlService, UserService } from "../../services";
import { UserModel } from "../../data/entities";
import { showSuccessMessage } from "../../utils";
import useAsync from "../../hooks/useAsync";
import { Formik } from "formik";
import * as yup from "yup";
import { Col, FloatingLabel, Form, Row, Button as BSButton } from "react-bootstrap";
import { useSetPageTitle, useSpinner } from "../../hooks";
import { useRef } from "react";
import { usePageActions } from "../PageLayout";
import { useSelector } from "react-redux";
import { RootState } from "../../app";
import usePermission from "../../hooks/usePermission";
import { PermissionDefinitions } from "../../security";

export function UserDetailsPage() {
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");
  const submitRef = useRef<HTMLButtonElement>(null);

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canEditUser = usePermission(PermissionDefinitions.user.edit(tenantId!));

  const { showSpinner, show, hide } = useSpinner();

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
  });

  const { loading, value: user, error } = useAsync(async () => await new UserService().get(id), [id]);

  useSetPageTitle(user?.email ?? "", [user]);
  usePageActions(
    canEditUser && (
      <Button onClick={() => submitRef.current?.click()} showSpinner={showSpinner}>
        <IconAndText iconName={IconDefinitions.save} text="Save" />
      </Button>
    ),
    [canEditUser]
  );

  if (!user || loading) return <SpinnerDefault />;
  if (error) {
    return (
      <Card title="Failed to load data.">
        <div>
          <em>No Profile data for this User.</em>
        </div>
      </Card>
    );
  }
  return (
    <Card title="Profile">
      <Formik
        validationSchema={validationSchema}
        initialValues = {{
          userName: user?.userName ?? "",
          firstName: user?.firstName ?? "",
          lastName: user?.lastName ?? "",
        }}
        onSubmit = {async (values) => {
          show();
          try {
            const data = {
              ...user,
              ...values
            } as UserModel;
            const isUpdated = await new UserService().update(id, data);
            if (isUpdated) {
              showSuccessMessage(`Profile updated successfully.`);
            };
          } catch (e) {
            console.error("Error updating user profile", e);
          }
          hide();
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, touched, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FloatingLabel label="Username" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Username"
                      name="userName"
                      value={values.userName}
                      disabled={true}
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FloatingLabel label="First Name" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      disabled={!canEditUser}
                      isInvalid={touched.firstName && !!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FloatingLabel label="Last Name" className="mb-3">
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={!canEditUser}
                      isInvalid={touched.lastName && !!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <BSButton ref={submitRef} type="submit" className="d-none" />

            </Form>
          )
        }}
      </Formik>
    </Card>
  );
}

export default UserDetailsPage;

