import React, { PropsWithChildren, useEffect, useState } from 'react'
import {
  DropDownListBlurEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import DropdownOption from './DropdownOption';
import SearchableDropdown from './SearchableDropdown';
import { ProviderTypeEnum } from '../../data/entities/booking/ProviderTypeEnum';

interface SearchableProviderTypeDropdownProps extends PropsWithChildren {
  value?: any;
  name: string;
  isInvalid?: boolean;
  onBlur?: (e: DropDownListBlurEvent) => void;
  onChange?: (e: DropDownListChangeEvent) => void;
  onSelect?: (value: DropdownOption) => void;
  placeholder?: string;
}

export const SearchableProviderTypeDropdown = React.forwardRef(
  (
    props: Readonly<SearchableProviderTypeDropdownProps>,
    ref: React.ForwardedRef<HTMLSelectElement | null>
  ) => {
    const [options, setOptions] = useState<DropdownOption[]>([]);


    useEffect(() => {
      // Convert the ProviderTypeEnum into DropdownOption array
      const enumOptions = Object.entries(ProviderTypeEnum).map<DropdownOption>(([key, value]) => ({
        value: value, // Assign the enum's value to the value property
        text: value,    // Use the enum key as the text for the dropdown
      }));

      setOptions(enumOptions);
    }, []);

    const getValue = () => {
      if (props.value) {
        return options.find((option) => option.value === props.value?.toString());
      }
    }

    return (
      <SearchableDropdown
        defaultItem={{ text: "Select a Type", value: "" }}
        label="Type"
        name={props.name}
        options={options}
        value={getValue()}
        onChange={props.onChange}
        onSelect={props.onSelect}
        isInvalid={props.isInvalid}
      >
        {props.children}
      </SearchableDropdown>
    );
  }
);

export default SearchableProviderTypeDropdown
