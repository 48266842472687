import { ErrorMessage, Field } from 'formik';
import { Icon } from '../icons';
import style from './styles/Schedule.module.scss';

interface ScheduleBlockEditorProps {
  blockIndex: number;
  remove: (index: number) => void;
}

export const ScheduleBlockEditor =  ({ blockIndex, remove } : ScheduleBlockEditorProps) => {
  return (
    <>
      <div className='d-flex align-items-center py-2'>
        <div>
          <Field
            type="time"
            name={`scheduleBlocks[${blockIndex}].startTime`}
            className={style.input}
          />
          <span className="mx-2">-</span>
          <Field
            type="time"
            name={`scheduleBlocks[${blockIndex}].endTime`}
            className={style.input}
          />
          </div>
        <Icon onClick={() => remove(blockIndex)} className={`ms-3 h-100 cursor-pointer ${style.delete}`} iconName="times" />
      </div>
      <ErrorMessage
        name={`scheduleBlocks[${blockIndex}].startTime`}
        component="div"
        className="text-danger"
      />
      <ErrorMessage
        name={`scheduleBlocks[${blockIndex}].endTime`}
        component="div"
        className="text-danger"
      />
    </>
  );
};

export default ScheduleBlockEditor;
