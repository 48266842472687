import { GridCellProps } from "@progress/kendo-react-grid";
import {
  Breakpoints,
  Button,
  ConfirmationModal,
  Icon,
  IconAndText,
  IconDefinitions,
  ODataGrid,
  ResponsiveGridColumn,
  TitlelessCard,
} from "../../components";
import { MenuItem } from "@progress/kendo-react-layout";
import { useGridActions, useSetPageTitle } from "../../hooks";
import { UserInviteModel } from "../../data/entities";
import { router, routes } from "../../app/routes";
import { UserInviteService } from "../../services";
import { useCallback, useRef } from "react";
import { usePageActions } from "../PageLayout";
import { useExpiryDate } from "./hooks";
import { AddUserInviteModal } from "./components";
import { InviteRequest } from "../../models";
import { showErrorMessage, showSuccessMessage } from "../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../app";
import usePermission from "../../hooks/usePermission";
import { PermissionDefinitions } from "../../security";

export function UserInvitationsPage() {

  const userInviteService = new UserInviteService();
  const { onClick, onSelect, showDelete, showAddState, showDeleteState, deleteItemState } = useGridActions<UserInviteModel>(routes.user.invite);
  const gridRef = useRef<ODataGrid>(null);
  const { getExpiryDateCell } = useExpiryDate();

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canAddUser = usePermission(PermissionDefinitions.user.create(tenantId!));
  const canDeleteUser = usePermission(PermissionDefinitions.user.delete(tenantId!));

  useSetPageTitle("Invitations", []);

  const addInviteHandler = async (request: InviteRequest) => {
    try {
      const ok = await userInviteService.insert(request);
      if (ok) {
        showSuccessMessage(`Invitation sent.`);
        showAddState?.setter(false);
      }
    } catch (error) {
      showErrorMessage(`Error occurred while trying to execute insert: ${error}`);
    } finally {
      gridRef.current?.refreshData();
    }
  };

  const deleteInviteHandler = async (item: UserInviteModel) => {
    try {
      const ok = await userInviteService.delete(item.id);
      if (ok) {
        showSuccessMessage(`Invitation deleted.`);
        showDeleteState?.setter(false);
      }
    } catch (error) {
      showErrorMessage(`Error occurred while trying to execute delete: ${error}`);
    } finally {
      gridRef.current?.refreshData();
    }
  }

  usePageActions(
    canAddUser && (
      <Button
        onClick={() => {
          showAddState.setter(true);
        }}
      >
        <IconAndText iconName={IconDefinitions.add} text="Invite" />
      </Button>
    )
  );

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <>
        <tr>
          {canAddUser && (
            <td>
              <Icon
                iconName={IconDefinitions.externalLink}
                className="ml-2 text-primary"
                onClick={() => { router.navigateWithParams(routes.account.register.index, new Map([["id", props.dataItem.id], ["key", props.dataItem.key]]), true); }}
              />
            </td>
          )}
          { canDeleteUser && (
            <td className="text-center">
              <Icon
                iconName={IconDefinitions.delete}
                className="text-danger"
                onClick={() => showDelete(props.dataItem)}
              />
            </td>
          )}
        </tr>
      </>
    ),
    [showDelete, canDeleteUser]
  );

  return (
    <TitlelessCard className="p-0 m-0" bodyClassName="p-0">
      <ODataGrid
        ref={gridRef}
        getData={(oDataQuery) => userInviteService.query(`${oDataQuery}`)}
        onRowMenuSelect={onSelect}
        onRowClick={onClick}
        sort={[{ field: "expiryDate", dir: "desc" }]}
        scrollable="scrollable"
      >
        <ResponsiveGridColumn
          title="Email"
          field="email"
          sortable={true}
        />
        <ResponsiveGridColumn
          title="Expires"
          field="expiryDate"
          sortable={true}
          filterable={false}
          cell={getExpiryDateCell}
        />
        <ResponsiveGridColumn
          title="Used"
          field="isUsed"
          sortable={true}
          filterable={false}
          width={100}
          cell={(props) => <td>{props.dataItem.isUsed ? "Yes" : "No"}</td>}
        />
        <ResponsiveGridColumn
          className="column-url"
          field="url"
          title=" "
          sortable={false}
          filterable={false}
          headerCell={() => null}
          cell={canDeleteUser == true ? commandsCell : undefined}
          width={80}
          breakpoint={Breakpoints.sm}
        />
        {canDeleteUser && (
          <MenuItem
            text="Delete"
            data={{ action: "delete" }}
            icon={IconDefinitions.delete}
          />
        )}
      </ODataGrid>
      <AddUserInviteModal
        showAddState={showAddState}
        showState={showAddState}
        onSubmit={addInviteHandler}
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Delete ${deleteItemState.value?.email}`}
        message={`You are about to delete this organization, it cannot be undone. Are you sure you want to delete ${deleteItemState.value.email}?`}
        confirmText="Delete"
        onConfirm={() => deleteInviteHandler(deleteItemState.value)}
      />
    </TitlelessCard>
  );
}

export default UserInvitationsPage;

