import { useEffect, useState, useCallback } from "react";
import { useUrlParams, useAsync } from "../../../hooks";
import { UserRoleAssignmentService } from "../../../services/authentication/UserRoleAssignmentService";
import { TenantRoleAssignmentModel } from "../../../data/entities/assignments/TenantRoleAssignmentModel";
import OrganizationRoleAssignmentModel from "../../../data/entities/assignments/OrganizationRoleAssignmentModel";
import { LocationRoleAssignmentModel } from "../../../data/entities/assignments/LocationRoleAssignmentModel";

export function useAccess() {
  const userRoleAssignmentService = new UserRoleAssignmentService();

  const userId = parseInt(useUrlParams("id"));
  const [refreshKey, setRefreshKey] = useState(0);

  const { value: userRoleAssignment, error, loading } = useAsync(() => userRoleAssignmentService.getAll(userId), [userId, refreshKey]);

  const [globalRoles, setGlobalRoles] = useState<string[]>([]);
  const [tenantRoleAssignments, setTenantRoleAssignments] = useState<TenantRoleAssignmentModel[]>();
  const [organizationRoleAssignments, setOrganizationAssignments] = useState<OrganizationRoleAssignmentModel[]>();
  const [locationRoleAssignments, setLocationRoleAssignments] = useState<LocationRoleAssignmentModel[]>();

  useEffect(() => {
    setGlobalRoles(userRoleAssignment?.globalRoleNames ?? []);
    setTenantRoleAssignments(userRoleAssignment?.tenants);
    setOrganizationAssignments(userRoleAssignment?.organizations);
    setLocationRoleAssignments(userRoleAssignment?.locations);
  }, [userRoleAssignment]);

  const refreshData = useCallback(() => {
    setRefreshKey(prevKey => prevKey + 1);
  }, []);

  return {
    globalRoles,
    tenantRoleAssignments,
    organizationRoleAssignments,
    locationRoleAssignments,
    loading,
    error,
    refreshData
  }
}
