import React, { useState, useEffect } from 'react';
import { SlideOutModal, SlideOutModalProps } from '../../../components';
import ServiceModel from '../../../data/entities/booking/ServiceModel';
import { sharedProperty} from '../../../hooks';
import ServiceTopbar from '../layout/ServiceTopbar';
import { ServiceDetailsPage, ServiceLocationsPage } from '../';

interface ManageServiceModalProps extends SlideOutModalProps {
  dataItemState: sharedProperty<ServiceModel>;
  showState: sharedProperty<boolean>;
  onSubmit: any;
}

export function ManageServiceModal(props: Readonly<ManageServiceModalProps>) {

  const [activeTab, setActiveTab] = useState<string>('Details');

  useEffect(() => {
    setActiveTab('Details');
  }, [props.dataItemState.value.id])

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <SlideOutModal {...props}
      title={`${props.dataItemState.value.name}`}
      bodyClassName="p-0"
      size='lg'
      topbar={
        <ServiceTopbar onChangeTab={handleTabChange} />
      }
      onHide={() => { setActiveTab('Details') }}
    >
      {activeTab === 'Details' && (
        <ServiceDetailsPage dataItemState={props.dataItemState} showState={props.showState} onSubmit={props.onSubmit} />
      )}

      {activeTab === 'Locations' && (
        <ServiceLocationsPage serviceId={props.dataItemState.value.id} showState={props.showState}/>
      )}
      {props.children}
    </SlideOutModal>
  )
}

export default ManageServiceModal;
