import { BaseEntityService } from "../BaseEntityService"
import { ScheduleRepo, ScheduleODataRepo } from "../../data/repo";
import { Schedule } from "../../data/entities/booking/Schedule";
import { ScheduleBlock } from "../../data/entities/booking/ScheduleBlock";


export class ScheduleService extends BaseEntityService<Schedule, ScheduleRepo, ScheduleODataRepo> {
  constructor(){
    super(new ScheduleRepo(), new ScheduleODataRepo());
  }

  async getDefaultSchedulesAsync(providerId: number): Promise<Schedule[]> {
    return this.repo.getDefaultSchedulesAsync(providerId);
  }

  async getExceptionSchedulesAsync(providerId: number): Promise<Schedule[]> {
    return this.repo.getExceptionSchedulesAsync(providerId);
  }

  async setScheduleBlocksAsync(scheduleId: number, scheduleBlocks: ScheduleBlock[]): Promise<void> {
    return this.repo.setScheduleBlocksAsync(scheduleId, scheduleBlocks);
  }

  async cloneAsync(id: number, effectiveDate: Date): Promise<boolean> {
    return this.repo.cloneAsync(id, effectiveDate);
  }

}

export default ScheduleService;
