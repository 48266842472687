import React, { PropsWithChildren, useEffect, useState } from 'react'
import {
  DropDownListBlurEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import DropdownOption from './DropdownOption';
import SearchableDropdown from './SearchableDropdown';
import { TenantService } from '../../services';
import { TenantModel } from '../../data/entities';

interface SearchableTenantDropdownProps extends PropsWithChildren {
  value?: any;
  name: string;
  isInvalid?: boolean;
  onBlur?: (e: DropDownListBlurEvent) => void;
  onChange?: (e: DropDownListChangeEvent) => void;
  onSelect?: (value: DropdownOption) => void;
  placeholder?: string;
}

export const SearchableTenantDropdown = React.forwardRef(
  (
    props: Readonly<SearchableTenantDropdownProps>,
    ref: React.ForwardedRef<HTMLSelectElement | null>
  ) => {
    const tenantService = new TenantService();
    const [options, setOptions] = useState<DropdownOption[]>([]);

    useEffect(() => {
      const fetchData = async () => {
        let organizations = await tenantService.getAll();

        const newOptions = organizations.map<DropdownOption>((tenant: TenantModel) => ({
          value: tenant.id?.toString() ?? "",
          text: `${tenant.name}`,
        }));

        setOptions(newOptions);
      };

      fetchData();
    }, []);

    const getValue = () => {
      if (props.value) {
        return options.find((option) => option.value === props.value?.toString());
      }
    }

    return (
      <SearchableDropdown
        defaultItem={{ text: "Select a Tenant", value: "" }}
        label="Tenant"
        name={props.name}
        options={options}
        value={getValue()}
        onChange={props.onChange}
        onSelect={props.onSelect}
        isInvalid={props.isInvalid}
      >
        {props.children}
      </SearchableDropdown>
    );
  }
);

export default SearchableTenantDropdown
