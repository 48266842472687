import {
  Button,
  SlideOutModal,
  SlideOutModalProps,
} from "../../../components";
import { sharedProperty } from "../../../hooks";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useRoles } from "../hooks/useRoles";
import { UserEntityModel } from "../../../models/user/roleassignments/UserEntityModel";

export interface ManageRolesModalProps extends SlideOutModalProps {
  showAddState: sharedProperty<boolean>;
  onSubmit: any;
  entity: UserEntityModel;
}

export function ManageRolesModal(props: Readonly<ManageRolesModalProps>) {

  const { roles, handleSubmit, onSelectionChange, onHeaderSelectionChange } = useRoles(props.entity, props.onSubmit);

  return (
    <SlideOutModal
      {...props}
      title={`${props.entity.name} Roles`}
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={handleSubmit} variant="primary" className="w-100">
                Save
              </Button>
            </div>
          </div>
        </div>
      }
    >

      <Grid
        data={roles?.sort((a, b) => a.name.localeCompare(b.name))}
        dataItemKey="id"
        selectedField="selected"
        selectable={{
          enabled: false,
          drag: false,
          cell: false,
          mode: "multiple",
        }}
        filterable={false}
        sortable={false}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        scrollable="none"
        sort={[{ field: "name", dir: "asc" }]}
      >
        <GridColumn
          field={"selected"}
          width="50px"
          title=" "
          headerSelectionValue={
            !roles || roles.every((item) => item.selected)
          }
        />
        <GridColumn
          field={'name'}
          title={'Name'}
        />
      </Grid>
    </SlideOutModal>
  );
}

export default ManageRolesModal;
