import {
  Button,
  HandleDropDownListChange,
  SearchableTenantDropdown,
  SlideOutModal,
  SlideOutModalProps,
} from "../../../components";
import { Button as BSButton, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import * as yup from 'yup';
import { Formik } from 'formik';
import { useRef } from "react";
import ProviderModel from "../../../data/entities/booking/ProviderModel";
import { ProviderTypeEnum } from "../../../data/entities/booking/ProviderTypeEnum";
import SearchableProviderTypeDropdown from "../../../components/SearchableDropdown/SearchableProviderTypeDropdown";
import SearchableUserDropdown from "../../../components/SearchableDropdown/SearchableUserDropdown";
import ReactTextareaAutosize from "react-textarea-autosize";
import { useUrlParams } from "../../../hooks";

export interface AddProviderModalProps extends SlideOutModalProps {
  onSubmit: any;
}

export function AddProviderModal(props: Readonly<AddProviderModalProps>) {

  const submitRef = useRef<HTMLButtonElement>(null);
  const locationId = parseInt(useUrlParams("id"));
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    providerType: yup.string()
      .oneOf(Object.values(ProviderTypeEnum))
      .required("Provider Type is required"),
    description: yup.string().notRequired(),
    capacity: yup.number().min(0, "Capacity must be greater than or equal to 0").required("Capacity is required"),
    locationId: yup.number().required("Location is required"),
    tenantId: yup.number().required("Tenant is required"),
    userId: yup.number().when('providerType', (providerType: ProviderTypeEnum | any[], schema) => {
      return (Object.values(ProviderTypeEnum) as ProviderTypeEnum[]).includes(providerType as ProviderTypeEnum)
        ? schema.required("User is required")
        : schema.notRequired();
    })
  });


  return (
    <SlideOutModal {...props} title="New Provider"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => submitRef.current?.click()} variant="primary" className="w-100">
                Add
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          id: 0,
          name: '',
          providerType: '' as ProviderTypeEnum,
          description: '',
          capacity: 0,
          userId: null,
          locationId: 0,
          tenantId: 0,
        }}
        onSubmit={async (values) => {
          const provider = {
            providerType: values.providerType,
            description: values.description,
            capacity: values.capacity,
            userId: values.providerType == ProviderTypeEnum.person ? values.userId : null,
            locationId: locationId,
            tenantId: values.tenantId,
            name: values.name
          };
          props.onSubmit(provider as ProviderModel);
        }}
      >
        {({ values, handleSubmit, handleChange, touched, setFieldValue, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="providerType">
                      <SearchableProviderTypeDropdown
                        name="providerType"
                        value={values.providerType}
                        placeholder="Location"
                        onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                        isInvalid={touched.providerType && !!errors.providerType}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.providerType}
                      </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              {values.providerType === ProviderTypeEnum.person && (
                <Row>
                  <Col>
                    <Form.Group controlId="userId">
                        <SearchableUserDropdown
                          name="userId"
                          value={values.userId}
                          placeholder="User"
                          onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                          isInvalid={touched.userId && !!errors.userId}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.userId}
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Form.Group controlId="tenantId">
                      <SearchableTenantDropdown
                        name="tenantId"
                        value={values.tenantId}
                        placeholder="Tenant"
                        onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                        isInvalid={touched.tenantId && !!errors.tenantId}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.tenantId}
                      </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="name">
                    <FloatingLabel label="Name" className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={touched.name && !!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="description">
                    <FloatingLabel label="Description" className="mb-3">
                      <ReactTextareaAutosize
                        minRows={4}
                        className="form-control"
                        placeholder="Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="capacity">
                    <FloatingLabel label="Capacity" className="mb-3">
                      <Form.Control
                        type="number"
                        name="capacity"
                        placeholder="Capacity"
                        value={values.capacity}
                        onChange={handleChange}
                        isInvalid={touched.capacity && !!errors.capacity}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.capacity}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <BSButton ref={submitRef} type="submit" size="lg" className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default AddProviderModal;
