import { Outlet } from "react-router-dom";
import { PageTitleProvider, useAsync, useSetPageTitle, useUrlParams  } from "../../../hooks";
import { ProviderService } from "../../../services";
import { ErrorPage } from "../../error";
import SidebarSubmenu from "../../../components/Submenu/Sidebar/SidebarSubmenu";
import { router, routes } from "../../../app/routes";

function BaseLayout() {


  const locationId = parseInt(useUrlParams("id"));
  const providerId = parseInt(useUrlParams("providerId"));
  const idMap = new Map([["id", locationId?.toString() ?? ""], ["providerId", providerId?.toString() ?? ""]]);
  return (
  <div className="row">
    <div className="col-24 col-lg-3">
      <SidebarSubmenu menuItems={[
          { text: "Details", url: router.getUrl(routes.location.provider.details, idMap)},
          { text: "Services", url: router.getUrl(routes.location.provider.services, idMap)},
          { text: "Schedule", url: router.getUrl(routes.location.provider.schedule, idMap)},
        ]}
      />
    </div>
    <div className="col-24 col-lg-21 position-relative">
      <Outlet />
    </div>
  </div>
  )
}

export function ProviderLayout() {
  const providerService = new ProviderService();
  const providerId = parseInt(useUrlParams("providerId"));
  const { value: providerData, loading: providerLoading, error: providerError }
    = useAsync(() => providerService.get(providerId), [providerId]);

  useSetPageTitle(`Providers: ${providerData?.name ?? ""}`, []);

  if(providerLoading) return <></>
  if(providerError){
    return <ErrorPage message={providerError.message} />
  }
  // Wrap the BaseUserLayout in a PageTitleProvider.
  // This prevents an error of the context not being initialized when the usePageTitle hook is called.

  return (
    <PageTitleProvider>
      <BaseLayout />
    </PageTitleProvider>
  );
}

export default ProviderLayout
