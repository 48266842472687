import React, { PropsWithChildren, useEffect, useState } from 'react'
import {
  DropDownListBlurEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import DropdownOption from './DropdownOption';
import SearchableDropdown from './SearchableDropdown';
import { UserService } from '../../services';
import { UserModel } from '../../data/entities';

interface SearchableUserDropdownProps extends PropsWithChildren {
  value?: any;
  name: string;
  isInvalid?: boolean;
  onBlur?: (e: DropDownListBlurEvent) => void;
  onChange?: (e: DropDownListChangeEvent) => void;
  onSelect?: (value: DropdownOption) => void;
  placeholder?: string;
}

export const SearchableUserDropdown = React.forwardRef(
  (
    props: Readonly<SearchableUserDropdownProps>,
    ref: React.ForwardedRef<HTMLSelectElement | null>
  ) => {
    const userService = new UserService();
    const [options, setOptions] = useState<DropdownOption[]>([]);

    useEffect(() => {
      const fetchData = async () => {
        let users = await userService.getAll();

        const newOptions = users.map<DropdownOption>((user: UserModel) => ({
          value: user.id?.toString() ?? "",
          text: `${user.email}`,
        }));

        setOptions(newOptions);
      };

      fetchData();
    }, []);

    const getValue = () => {
      if (props.value) {
        return options.find((option) => option.value === props.value?.toString());
      }
    }

    return (
      <SearchableDropdown
        defaultItem={{ text: "Select a User", value: "" }}
        label="User"
        name={props.name}
        options={options}
        value={getValue()}
        onChange={props.onChange}
        onSelect={props.onSelect}
        isInvalid={props.isInvalid}
      >
        {props.children}
      </SearchableDropdown>
    );
  }
);

export default SearchableUserDropdown
