import { useRef } from "react";
import {
  Button,
  HandleDropDownListChange,
  SearchableTenantDropdown,
  SlideOutModal,
  SlideOutModalProps,
} from "../../../components";
import * as yup from "yup";
import { Formik } from "formik";
import { Form, Row, Col, FloatingLabel, Button as BSButton } from "react-bootstrap";
import { OrganizationModel } from "../../../data/entities";
import { sharedProperty } from "../../../hooks";

export interface AddOrganizationModalProps extends SlideOutModalProps {
  showAddState: sharedProperty<boolean>;
  onSubmit: any;
}

export function AddOrganizationModal(props: Readonly<AddOrganizationModalProps>) {

  const submitRef = useRef<HTMLButtonElement>(null);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Service Name is required"),
    tenantId: yup.number().required("Tenant is required"),
  });

  return (
    <SlideOutModal
      {...props}
      title="Add New Organization"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => submitRef.current?.click()} variant="primary" className="w-100">
                Add
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: '',
          tenantId: null
        }}
        onSubmit={async (values) => {
          const organization = {
            ...values
          } as OrganizationModel;
          props.onSubmit(organization);
          props.showAddState.setter(false);
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue, touched, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FloatingLabel label="Organization Name" className="mb-3">
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Organization Name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="tenantId">
                    <SearchableTenantDropdown
                      name="tenantId"
                      value={values.tenantId}
                      placeholder="Organization"
                      onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                      isInvalid={touched.tenantId && !!errors.tenantId}
                    >
                      <Form.Control.Feedback type="invalid">
                        {errors.tenantId}
                      </Form.Control.Feedback>
                    </SearchableTenantDropdown>
                  </Form.Group>
                </Col>
              </Row>

              <BSButton ref={submitRef} type="submit" size="lg" className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default AddOrganizationModal;
