import { AccountService, TokenService, UserService } from "../services";
import { router, routes } from "../app/routes";
import { useQueryParams, useSpinner } from '.';
import { store, setToken, setIsLoggedIn, setTokenData, setUserInfo, setTenantId, setUserTenants } from "../app";
import { RoleScopeEnum } from "../data/entities/user/RoleScopeEnum";

export function useAuthentication() {
  const { showSpinner, show, hide } = useSpinner();
  const { getQueryParam } = useQueryParams();

  const accountService = new AccountService();

  const loginUser = async (email: string, password: string, rememberMe: boolean, tenantId?: number) => {
    try {
      show();
      const loginResponse = await accountService.login(email, password, rememberMe, tenantId);

      if (loginResponse?.success){

        if(!loginResponse?.token) return;
        store.dispatch(setToken(loginResponse.token));

        const tokenData = TokenService.getTokenData(loginResponse.token);

        if (rememberMe) localStorage.setItem('userToken', loginResponse.token);

        const userRoles = loginResponse.user?.roles.filter(
          ur =>
            ur.role?.scope === RoleScopeEnum.Global ||
            ur.userTenant?.tenantId === loginResponse.tenantId
        );

        // Get tenant from tenant service using tenantId and set the redux tenant.
        if(loginResponse.tenantId)
            store.dispatch(setTenantId(loginResponse.tenantId));


        store.dispatch(setIsLoggedIn(true));
        store.dispatch(setTokenData(tokenData));
        store.dispatch(setUserTenants(loginResponse.tenants));
        store.dispatch(setUserInfo({
          ...loginResponse.user,
          roles: userRoles
        }));

        const returnUrl = getQueryParam("returnUrl");
        const destination = returnUrl ?? routes.home;
        router.navigate(destination);
      } else {
        router.navigate(routes.account.tenantSelection, {
          state: { email, password, rememberMe, tenants: loginResponse?.tenants }
        });
      }
    } catch (error) {
      console.error(error);

    } finally {
      hide();
    }
  };

  // Switch Tenants
  const switchTenant = async (tenantId: number) => {
    const loggedUser = store.getState().authentication.userInfo;
    // force logout and login with new tenant
    UserService.signout();
    if(loggedUser)
      loginUser(loggedUser?.userName, "", false, tenantId);
  }

  return { loginUser, switchTenant, showSpinner };
}

export default useAuthentication;
