import {
  Button,
  SlideOutModal,
  SlideOutModalProps,
  Toggle,
} from "../../../components";
import { Button as BSButton, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import * as yup from 'yup';
import { Formik } from 'formik';
import { TenantService } from "../../../services";
import { sharedProperty, useUrlParams } from "../../../hooks";
import ServiceModel from "../../../data/entities/booking/ServiceModel";
import { useRef } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";

export interface AddServiceModalProps extends SlideOutModalProps {
  showAddState: sharedProperty<boolean>;
  onSubmit: any;
}

export function AddServiceModal(props: Readonly<AddServiceModalProps>) {

  const id = parseInt(useUrlParams("id"));
  const submitRef = useRef<HTMLButtonElement>(null);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Service Name is required"),
    description: yup.string().notRequired(),
    defaultPrice: yup.number().required("Default price is required"),
    hideProviders: yup.boolean().notRequired()
  });


  return (
    <SlideOutModal
      {...props}
      title="Add New Service"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => submitRef.current?.click()} variant="primary" className="w-100">
                Add
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: '',
          description: '',
          defaultPrice: 0,
          hideProviders: true
        }}
        onSubmit={async (values) => {
          const service = {
            ...values,
            tenantId: TenantService.getTenantId(),
            serviceTypeId: id,
          } as ServiceModel;
          props.onSubmit(service);
          props.showAddState.setter(false);
        }}
      >
        {({ values, handleSubmit, handleChange, touched, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="serviceName">
                    <FloatingLabel label="Service Name" className="mb-3">
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Service Name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={touched.name && !!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="defaultPrice">
                    <FloatingLabel label="Default Price" className="mb-3">
                      <Form.Control
                        type="number"
                        name="defaultPrice"
                        placeholder="Default Price"
                        value={values.defaultPrice}
                        onChange={handleChange}
                        isInvalid={touched.defaultPrice && !!errors.defaultPrice}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.defaultPrice}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="description">
                    <FloatingLabel label="Description" className="mb-3">
                      <ReactTextareaAutosize
                        minRows={4}
                        className="form-control"
                        placeholder="Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="hideProviders">
                    <Form.Check type="switch" className="d-flex align-items-center px-0">
                      <Form.Check.Label>Hide Providers</Form.Check.Label>
                      <Form.Check.Input
                        onChange={handleChange}
                        checked={values.hideProviders}
                        className="fs-3 ms-2 mt-0 me-2"
                      />
                    </Form.Check>
                  </Form.Group>
                </Col>
              </Row>

              <BSButton ref={submitRef} type="submit" size="lg" className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default AddServiceModal;
