import {
  Button,
  SlideOutModal,
  SlideOutModalProps,
} from "../../../components";
import { Col, FloatingLabel, Form, Row, Button as BSButton } from "react-bootstrap";
import * as yup from 'yup';
import { Formik } from 'formik';
import { TenantService } from "../../../services";
import ServiceTypeModel from "../../../data/entities/booking/ServiceTypeModel";
import { useRef } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";
import { sharedProperty } from "../../../hooks";

export interface AddServiceTypeModalProps extends SlideOutModalProps {
  onSubmit: any;
  showAddState: sharedProperty<boolean>;
}

export function AddServiceTypeModal(props: Readonly<AddServiceTypeModalProps>) {

  const submitRef = useRef<HTMLButtonElement>(null);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Service Type Name is required"),
    description: yup.string().notRequired(),
  });


  return (
    <SlideOutModal
      {...props}
      title="New Service Type"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => submitRef.current?.click()} variant="primary" className="w-100">
                Add
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: '',
          description: '',
        }}
        onSubmit={async (values) => {
          const serviceType = {
            name: values.name,
            description: values.description,
            tenantId: TenantService.getTenantId(),
          } as ServiceTypeModel;
          props.onSubmit(serviceType);
        }}
      >
        {({ values, handleSubmit, handleChange, touched, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="serviceTypeName">
                    <FloatingLabel label="Service Type Name" className="mb-3">
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Service Type Name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={touched.name && !!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="description">
                    <FloatingLabel label="Description" className="mb-3">
                      <ReactTextareaAutosize
                        minRows={4}
                        className="form-control"
                        placeholder="Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <BSButton ref={submitRef} type="submit" size="lg" className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default AddServiceTypeModal;
