/**
 * Defines the routes for the application.
 */
export const routes = {
  home: "/",
  account: {
    index: "/account",
    login: "/account/login",
    tenantSelection: "/account/tenant-selection",
    register: {
      index: "/account/register/:id/:key",
      confirmation: "/account/register/confirmation",
    },
    password: {
      forgot: {
        index: "/account/forgotPassword",
        confirmation: "/account/forgotPasswordConfirmation",
      },
      reset: {
        index: "/account/resetPassword",
        confirmation: "/account/resetPasswordConfirmation",
      },
    },
    access: {
      request: {
        index: "/account/registrationRequest",
        confirmation: "/account/registrationRequestConfirmation",
      },
    },
  },
  profile: {
    index: "/profile",
    changePassword: "/profile/change-password",
  },
  user: {
    index: "/user",
    details: "/user/:id",
    access: "/user/:id/access",
    delete: "/user/:id/delete",
    invite: "/user/invite",
  },
  provider: {
    index: "/provider",
    details: "/provider/:id",
    service: "/provider/:id/service",
  },
  serviceType: {
    index: "/service-type",
    details: "/service-type/:id",
    service: "/service-type/:id/service",
  },
  log: {
    index: "/log",
  },
  tenants: {
    index: "/tenant",
  },
  organization: {
    index: "/organization",
    details: "/organization/:id",
    location: "/organization/:id/location"
  },
  location: {
    index: "/location",
    details: "/location/:id",
    address: "/location/:id/address",
    pricing: "/location/:id/pricing",
    provider: {
      index: "/location/:id/provider",
      details: "/location/:id/provider/:providerId",
      services: "/location/:id/provider/:providerId/services",
      schedule: "/location/:id/provider/:providerId/schedule",
    }
  },
};

export default routes;


