import HttpClient from "../../../api/HttpClient";
import { UserRoleAssignmentModel } from "../../../data/entities/user/UserRoleAssignmentModel";
import { AddLocationRequest, AddOrganizationRequest, SetOrganizationRolesRequest, SetLocationRolesRequest } from "../../../models";
import { UserOrganizationModel } from "../../entities/user/UserOrganizationModel";
import BaseRepository from "../BaseRepository";

export class UserRoleAssignmentRepo extends BaseRepository{
  protected client = new HttpClient();
  protected baseUrl = 'userroleassignment';

  async getAll(userId: number): Promise<UserRoleAssignmentModel> {
    const response = await this.client.get(`${this.baseUrl}/${userId}`);
    return this.handleResponse<UserRoleAssignmentModel>(response)!;
  }

  async addOrganization(userId: number, request: AddOrganizationRequest): Promise<boolean> {
    const response = await this.client.post(`${this.baseUrl}/${userId}/addorganization`, request);
    return this.handleResponse(response);
  }

  async addLocation(userId: number, request: AddLocationRequest): Promise<boolean> {
    const response = await this.client.post(`${this.baseUrl}/${userId}/addlocation`, request);
    return this.handleResponse(response);
  }

  async setLocationRoles(userId: number, request: SetLocationRolesRequest): Promise<boolean> {
    const response = await this.client.post(`${this.baseUrl}/${userId}/setlocationroles`, request);
    return this.handleResponse(response);
  }

  async setOrganizationRoles(userId: number, request: SetOrganizationRolesRequest): Promise<boolean> {
    const response = await this.client.post(`${this.baseUrl}/${userId}/setorganizationroles`, request);
    return this.handleResponse(response);
  }

  async deleteOrganization(userId: number, organizationId: number): Promise<boolean> {
    const response = await this.client.delete(`${this.baseUrl}/${userId}/organization/${organizationId}`);
    return this.handleResponse(response);
  }

  async deleteLocation(userId: number, locationId: number): Promise<boolean> {
    const response = await this.client.delete(`${this.baseUrl}/${userId}/location/${locationId}`);
    return this.handleResponse(response);
  }
}

export default UserRoleAssignmentRepo;
