import { useRef } from "react";
import {
  Button,
  HandleDropDownListChange,
  SearchableTenantDropdown,
  SlideOutModal,
  SlideOutModalProps,
} from "../../../components";
import * as yup from "yup";
import { Formik } from "formik";
import { Form, Row, Col, FloatingLabel, Button as BSButton } from "react-bootstrap";
import { sharedProperty } from "../../../hooks";
import { InviteRequest } from "../../../models";
import { UserInviteService } from "../../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../../app";

export interface AddUserInviteModalProps extends SlideOutModalProps {
  showAddState: sharedProperty<boolean>;
  onSubmit: any;
}

export function AddUserInviteModal(props: Readonly<AddUserInviteModalProps>) {

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);

  const submitRef = useRef<HTMLButtonElement>(null);
  const validationSchema = yup.object().shape({
    email: yup.string().required("Email is required"),
    tenantId: yup.number().required("Tenant is required"),
  });

  return (
    <SlideOutModal
      {...props}
      title="Invite New User"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => submitRef.current?.click()} variant="primary" className="w-100">
                Invite
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          email: '',
          tenantId: tenantId
        }}
        onSubmit={async (values) => {
          const request = {
            ...values
          };
          props.onSubmit(request as InviteRequest);
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue, touched, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FloatingLabel label="Email" className="mb-3">
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={touched.email && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <BSButton ref={submitRef} type="submit" size="lg" className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default AddUserInviteModal;
