import { BaseEntityService } from "../BaseEntityService"
import { ServiceModel } from "../../data/entities/booking/ServiceModel";
import { ServiceRepo, ServiceODataRepo } from "../../data/repo";


export class ServiceService extends BaseEntityService<ServiceModel, ServiceRepo, ServiceODataRepo> {
  constructor(){
    super(new ServiceRepo(), new ServiceODataRepo());
  }
}

export default ServiceService;
