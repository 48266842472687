import { Schedule } from "../../../entities/booking/Schedule";
import BaseDataRepository from "../../BaseDataRepository";
export class ScheduleRepo extends BaseDataRepository<Schedule> {
  protected baseUrl = 'schedule';

  async getDefaultSchedulesAsync(providerId: number): Promise<Schedule[]> {
    const response = await this.client.get(`${this.baseUrl}/default/${providerId}`);
    return this.handleResponse<Schedule[]>(response)!;
  }

  async getExceptionSchedulesAsync(providerId: number): Promise<Schedule[]> {
    const response = await this.client.get(`${this.baseUrl}/override/${providerId}`);
    return this.handleResponse<Schedule[]>(response)!;
  }

  async setScheduleBlocksAsync(scheduleId: number, scheduleBlocks: any): Promise<void> {
    const response = await this.client.post(`${this.baseUrl}/${scheduleId}/blocks`, scheduleBlocks);
    return this.handleResponse<void>(response)!;
  }

  async cloneAsync(id: number, effectiveDate: Date): Promise<boolean> {
    const response = await this.client.post(`${this.baseUrl}/${id}/clone`, effectiveDate);
    return this.handleResponse<boolean>(response)!;
  }

}

export default ScheduleRepo;
