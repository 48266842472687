import { UserOrganizationModel } from "../../data/entities/user/UserOrganizationModel";
import { UserRoleAssignmentModel } from "../../data/entities/user/UserRoleAssignmentModel";
import UserRoleAssignmentRepo from "../../data/repo/authentication/UserRoleAssignmentRepo";
import { AddLocationRequest, AddOrganizationRequest, SetLocationRolesRequest, SetOrganizationRolesRequest } from "../../models";

export class UserRoleAssignmentService {

  protected repo = new UserRoleAssignmentRepo();

  async getAll(userId: number): Promise<UserRoleAssignmentModel> {
    return await this.repo.getAll(userId);
  }

  async addUserOrganization(userId: number, request: AddOrganizationRequest): Promise<boolean> {
    return await this.repo.addOrganization(userId, request);
  }

  async addUserLocation(userId: number, request: AddLocationRequest): Promise<boolean> {
    return await this.repo.addLocation(userId, request);
  }

  async setLocationRoles(userId: number, request: SetLocationRolesRequest): Promise<boolean> {
    return await this.repo.setLocationRoles(userId, request);
  }

  async setOrganizationRoles(userId: number, request: SetOrganizationRolesRequest): Promise<boolean> {
    return await this.repo.setOrganizationRoles(userId, request);
  }

  async deleteOrganization(userId: number, organizationId: number): Promise<boolean> {
    return await this.repo.deleteOrganization(userId, organizationId);
  }

  async deleteLocation(userId: number, locationId: number): Promise<boolean> {
    return await this.repo.deleteLocation(userId, locationId);
  }

}
