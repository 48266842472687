import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { PageBreadcrumbProps, PageLayout } from "../../PageLayout"
import UsersIndexTopbar from "./UsersIndexTopbar";
import { generateUserBreadcrumbs } from "./UsersBreadcrumbs";
import { BreadcrumbProps } from "../../../components";
import { PageTitleProvider, usePageTitle } from "../../../hooks";


function BaseLayout() {

  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const { title } = usePageTitle();

  useEffect(() => {
    const newBreadcrumbs:BreadcrumbProps = generateUserBreadcrumbs(location.pathname);
    setBreadcrumbs(newBreadcrumbs);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <PageLayout topbar={<UsersIndexTopbar />} title={title} breadcrumbs={breadcrumbs}>
      <Outlet />
    </PageLayout >
  )
}

export function UsersIndexLayout() {
  // Wrap the BaseUserLayout in a PageTitleProvider.
  // This prevents an error of the context not being initialized when the usePageTitle hook is called.
  return (
    <PageTitleProvider>
      <BaseLayout />
    </PageTitleProvider>
  );
}

export default UsersIndexLayout

