import { router, routes } from "../../../app/routes";

interface BreadcrumbProps {
  showHome: boolean;
  values: { label: string; url: string | null }[];
}

export function generateOrganizationBreadcrumbs(
  path: string,
  organizationName?: string,
  organizationId?: number,
): BreadcrumbProps {

  const idMap = new Map([["Id", organizationId?.toString() ?? ""]]);

  switch (path) {
    case router.getUrl(routes.organization.index, idMap):
      return {
        showHome: true,
        values: [{ label: "Organizations", url: null }],
      };
      case router.getUrl(routes.organization.details, idMap):
        return {
          showHome: true,
          values: [
            { label: "Organizations", url: routes.organization.index },
            {
              label: organizationName ?? "",
              url: null,
            },
          ],
        };

    default:
      return { showHome: false, values: [] };
  }
}


export default generateOrganizationBreadcrumbs;

