import { ODataGrid, ResponsiveGridColumn, TitlelessCard } from "../../components";
import { useCRUDHandlers, useSetPageTitle, useSharedState, useUrlParams } from "../../hooks";
import { useRef } from "react";
import ServiceLocationModel from "../../data/entities/booking/ServiceLocationModel";
import ServiceLocationService from "../../services/booking/ServiceLocationService";

export function LocationPricingPage() {

  const id = parseInt(useUrlParams("id"));
  const gridRef = useRef<ODataGrid>(null);
  const gridData = useSharedState<Array<ServiceLocationModel>>([]);
  const serviceLocationService = new ServiceLocationService();
  const { getItems } = useCRUDHandlers<ServiceLocationModel>(serviceLocationService, "Service Location");

  useSetPageTitle("Pricing", []);

  return (
    <TitlelessCard bodyClassName="p-0">
      <ODataGrid
        ref={gridRef}
        getData={(oDataQuery) => getItems(`${oDataQuery}&$expand=Service&$filter=locationId eq ${id}`) }
        dataState={gridData}
        sort={[{ field: "service.name", dir: "desc"}]}
        scrollable="scrollable"
        pageSize={10}
      >
        <ResponsiveGridColumn
          title="Service"
          field="service.name"
          sortable={true}
        />
        <ResponsiveGridColumn
          title="Price"
          field="price"
          format="{0:C}"
          sortable={true}
        />
      </ODataGrid>
    </TitlelessCard>
  );
}

export default LocationPricingPage;

