import {
  Card,
  HandleDropDownListChange,
  SearchableTenantDropdown,
} from "../../components";

import * as yup from "yup";
import { Formik } from "formik";
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import ReactTextareaAutosize from "react-textarea-autosize";
import { ProviderService } from "../../services";
import { useAsync, useCRUDHandlers, useSetPageTitle, useUrlParams } from "../../hooks";
import { ProviderTypeEnum } from "../../data/entities/booking/ProviderTypeEnum";
import SearchableProviderTypeDropdown from "../../components/SearchableDropdown/SearchableProviderTypeDropdown";
import SearchableUserDropdown from "../../components/SearchableDropdown/SearchableUserDropdown";
import ProviderModel from "../../data/entities/booking/ProviderModel";

interface ProviderDetailsPageProps {
}

export function ProviderDetailsPage(props: Readonly<ProviderDetailsPageProps>) {

  const providerService = new ProviderService();
  const providerId = parseInt(useUrlParams("providerId"));
  const {value: provider} = useAsync(() => providerService.get(providerId), [providerId]);
  const { editItem } = useCRUDHandlers<ProviderModel>(providerService, "Provider");
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    providerType: yup.string()
      .oneOf(Object.values(ProviderTypeEnum))
      .required("Provider Type is required"),
    description: yup.string().notRequired(),
    capacity: yup.number().min(0, "Capacity must be greater than or equal to 0").required("Capacity is required"),
    tenantId: yup.number().required("Tenant is required"),
    userId: yup.number().when('providerType', (providerType: ProviderTypeEnum | any[], schema) => {
      return (Object.values(ProviderTypeEnum) as ProviderTypeEnum[]).includes(providerType as ProviderTypeEnum)
        ? schema.required("User is required")
        : schema.notRequired();
    })
  });


  useSetPageTitle("Details", [providerId]);

  if(provider === null || provider === undefined) return null;

  return (
    <Card title="Details">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: provider.name,
          providerType: provider.providerType,
          description: provider.description,
          capacity: provider.capacity,
          userId: provider.userId,
          tenantId: provider.tenantId
        }}
        onSubmit={(values) => {
          editItem({
            ...provider,
            ...values,
            userId: values.providerType === ProviderTypeEnum.person ? values.userId : null
          })
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="providerType">
                    <SearchableProviderTypeDropdown
                      name="providerType"
                      value={values.providerType}
                      placeholder="Location"
                      onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                      isInvalid={touched.providerType && !!errors.providerType}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.providerType}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {values.providerType === ProviderTypeEnum.person && (
              <Row>
                <Col>
                  <Form.Group controlId="userId">
                      <SearchableUserDropdown
                        name="userId"
                        value={values.userId}
                        placeholder="User"
                        onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                        isInvalid={touched.userId && !!errors.userId}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.userId}
                      </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <Form.Group controlId="tenantId">
                    <SearchableTenantDropdown
                      name="tenantId"
                      value={values.tenantId}
                      placeholder="Tenant"
                      onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
                      isInvalid={touched.tenantId && !!errors.tenantId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.tenantId}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <hr className="mt-0"/>

            <Row>
              <Col>
                <Form.Group controlId="name">
                  <FloatingLabel label="Name" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="description">
                  <FloatingLabel label="Description" className="mb-3">
                    <ReactTextareaAutosize
                      minRows={4}
                      className="form-control"
                      placeholder="Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="capacity">
                  <FloatingLabel label="Capacity" className="mb-3">
                    <Form.Control
                      type="number"
                      name="capacity"
                      placeholder="Capacity"
                      value={values.capacity}
                      onChange={handleChange}
                      isInvalid={touched.capacity && !!errors.capacity}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.capacity}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Card>

  );
}

export default ProviderDetailsPage;

