import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormikContext } from "formik";
import { HandleDropDownListChange, SearchableRegionDropdown } from "../SearchableDropdown";
import { AddressModel } from "../../data/entities";
import { PropsWithChildren } from "react";

interface AddressFormValues {
  address: AddressModel;
}

interface AddressEditorProps extends PropsWithChildren {
  disabled?: boolean;
}

export function AddressEditor(props: Readonly<AddressEditorProps>) {
  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<AddressFormValues>();

  return (
    <>
      <Row>
        <Col>
          <FloatingLabel label="Street Address" className="mb-3">
            <Form.Control
              type="text"
              name="address.streetAddress"
              placeholder="Street Address"
              value={values.address.streetAddress}
              onChange={handleChange}
              disabled={props.disabled}
              isInvalid={touched.address?.streetAddress && !!errors.address?.streetAddress}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address?.streetAddress?.toString()}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>

      <Row>
        <Col>
          <FloatingLabel label="Street Address 2" className="mb-3">
            <Form.Control
              type="text"
              name="address.streetAddress2"
              placeholder="Street Address 2"
              value={values.address?.streetAddress2 ?? ''}
              onChange={handleChange}
              disabled={props.disabled}
              isInvalid={touched.address?.streetAddress2 && !!errors.address?.streetAddress2}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address?.streetAddress2?.toString()}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>

      <Row>
        <Col>
          <FloatingLabel label="City" className="mb-3">
            <Form.Control
              type="text"
              name="address.city"
              placeholder="City"
              value={values.address?.city}
              onChange={handleChange}
              disabled={props.disabled}
              isInvalid={touched.address?.city && !!errors.address?.city}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address?.city?.toString()}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="regionId">
            <SearchableRegionDropdown
              name="address.regionId"
              value={values.address?.regionId}
              placeholder="Region"
              onChange={(e) => HandleDropDownListChange(e, setFieldValue)}
              disabled={props.disabled}
              isInvalid={touched.address?.regionId && !!errors.address?.regionId}
            >
              <Form.Control.Feedback type="invalid">
                {errors.address?.regionId?.toString()}
              </Form.Control.Feedback>
            </SearchableRegionDropdown>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <FloatingLabel label="Postal Code" className="mb-3">
            <Form.Control
              type="text"
              name="address.postalCode"
              placeholder="Postal Code"
              value={values.address?.postalCode}
              onChange={handleChange}
              disabled={props.disabled}
              isInvalid={touched.address?.postalCode && !!errors.address?.postalCode}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address?.postalCode?.toString()}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>

    </>
  );
}
