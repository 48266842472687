import * as yup from 'yup';

function parseTime(timeStr: string): number {
  const [hoursStr, minutesStr] = timeStr.split(':');
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  return hours * 60 + minutes;
}

export const validationSchema = yup.object().shape({
  scheduleId: yup.number().required(),
  scheduleBlocks: yup
    .array()
    .of(
      yup.object().shape({
        scheduleId: yup.number().required(),
        dayOfWeek: yup.number().required('Day of Week is required'),
        startTime: yup.string().required('Start Time is required'),
        endTime: yup
          .string()
          .required('End Time is required')
          .test('is-after-start', 'End Time must be after Start Time', function (value) {
            const { startTime } = this.parent;
            return value > startTime;
          }),
      })
    )
    .test('no-overlapping-times', function (scheduleBlocks) {
      const { createError } = this;
      if (!scheduleBlocks || scheduleBlocks.length === 0) return true;

      const blocksByDay: { [key: number]: Array<{ startTime: string; endTime: string; index: number }> } = {};

      scheduleBlocks.forEach((block: any, index: number) => {
        const { dayOfWeek, startTime, endTime } = block;
        if (!blocksByDay[dayOfWeek])
          blocksByDay[dayOfWeek] = [];
        blocksByDay[dayOfWeek].push({ startTime, endTime, index });
      });

      let isValid = true;
      const errors: any = {};

      Object.keys(blocksByDay).forEach((dayKey: any) => {
        const blocks = blocksByDay[dayKey];
        const times = blocks.map(({ startTime, endTime, index }) => ({
          start: parseTime(startTime),
          end: parseTime(endTime),
          index,
        }));

        times.sort((a, b) => a.start - b.start);

        for (let i = 0; i < times.length - 1; i++) {
          if (times[i].end > times[i + 1].start) {
            isValid = false;
            errors[dayKey] = `There's an overlapping schedule for this day. Please adjust the times to avoid conflicts`;
            break;
          }
        }
      });

      if (!isValid)
        return createError({ path: 'scheduleBlocks', message: errors });

      return true;
    })
});
