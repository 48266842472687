import { Button, IconAndText, IconDefinitions, Card } from "../../components";
import * as yup from "yup";
import { Formik } from "formik";
import { FloatingLabel, Form, Button as BSButton } from "react-bootstrap";
import ReactTextareaAutosize from "react-textarea-autosize";
import ServiceTypeModel from "../../data/entities/booking/ServiceTypeModel";
import { useCRUDHandlers, useSetPageTitle, useUrlParams } from "../../hooks";
import useAsync from "../../hooks/useAsync";
import { ServiceTypeService } from "../../services/booking";
import { useRef } from "react";
import { usePageActions } from "../PageLayout";
import { useSelector } from "react-redux";
import { RootState } from "../../app";
import usePermission from "../../hooks/usePermission";
import { PermissionDefinitions } from "../../security";

export function ServiceTypeDetailsPage() {

  const id = parseInt(useUrlParams("id"));
  const serviceTypeService = new ServiceTypeService();
  const { editItem } = useCRUDHandlers<ServiceTypeModel>(serviceTypeService, "Service Type");
  const submitRef = useRef<HTMLButtonElement>(null);
  const { value: serviceType, error, loading } = useAsync(() => serviceTypeService.get(id), [id]);

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canEditServiceType = usePermission(PermissionDefinitions.serviceType.edit(tenantId!));

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().notRequired(),
  });

  useSetPageTitle(serviceType?.name ?? "", [serviceType]);
  usePageActions(
    canEditServiceType && (
      <Button onClick={() => submitRef.current?.click()}>
        <IconAndText iconName={IconDefinitions.save} text="Save" />
      </Button>
    ),
    [canEditServiceType]
  );

  if (error) return <div>Error loading service type: {error.message}</div>;
  if (!serviceType || loading) return <></>;

  return (
    <Card title="Details">
      <Formik
        validationSchema={validationSchema}
        initialValues={{ name: serviceType.name, description: serviceType.description }}
        onSubmit={(values) => {
          const body = {
            ...serviceType,
            ...values,
          } as ServiceTypeModel;
          editItem(body);
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="col col-24">
                <FloatingLabel label="Name" className="mb-3">
                  <Form.Control
                      type="string"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      disabled={!canEditServiceType}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col col-24">
                <FloatingLabel label="Description" className="mb-3">
                  <ReactTextareaAutosize
                    minRows={4}
                    className="form-control"
                    placeholder="Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    disabled={!canEditServiceType}
                  />
                </FloatingLabel>
              </div>
            </div>

            <BSButton ref={submitRef} type="submit" className="d-none" />
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default ServiceTypeDetailsPage;

