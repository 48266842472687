import { BaseEntityService } from "../BaseEntityService"
import { ServiceLocationODataRepo, ServiceLocationRepo } from "../../data/repo";
import ServiceLocationModel from "../../data/entities/booking/ServiceLocationModel";

export class ServiceLocationService extends BaseEntityService<ServiceLocationModel, ServiceLocationRepo, ServiceLocationODataRepo> {
  constructor(){
    super(new ServiceLocationRepo(), new ServiceLocationODataRepo());
  }
}

export default ServiceLocationService;
