import { useParams } from "react-router-dom";
import { routes } from "../../../app/routes";
import {
  TopbarSubmenu,
  IconDefinitions,
  SubmenuItem,
} from "../../../components";
import { UrlService } from "../../../services";
import { useEffect, useMemo, useState } from "react";

export const LocationsIndexTopbar = (props: any) => {
  const params = useParams();
  const returnUrl = UrlService.getQueryParams("returnUrl");
  const id = params["id"] ?? "";

  const defaultSubmenuItems: SubmenuItem[] = useMemo(
    (): SubmenuItem[] => [
      {
        label: "Locations",
        icon: IconDefinitions.home,
        url: routes.location.index,
        activeMatch: routes.location.index,
        activeMatchExact: true,
        params: new Map([["id", id]]),
      },
    ],
    [id]
  );

  useEffect(() => {
    const backItem: SubmenuItem = {
      label: "Back",
      icon: IconDefinitions.back,
      url: returnUrl ?? "",
    };

    if (returnUrl) {
      setSubmenuItems([backItem, ...defaultSubmenuItems]);
    } else {
      setSubmenuItems(defaultSubmenuItems);
    }
  }, [defaultSubmenuItems, id, returnUrl]);

  const [submenuItems, setSubmenuItems] =
    useState<SubmenuItem[]>(defaultSubmenuItems);

  return <TopbarSubmenu className="p-0" items={submenuItems}></TopbarSubmenu>;
};

export default LocationsIndexTopbar;

