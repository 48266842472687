import { Outlet, useLocation } from "react-router-dom";
import PageLayout, { PageBreadcrumbProps } from "../../PageLayout";
import LocationTopbar from "./LocationTopbar";
import { LocationService, ProviderService } from "../../../services";
import { useState, useEffect } from "react";
import { BreadcrumbProps } from "../../../components";
import { PageTitleProvider, usePageTitle, useUrlParams } from "../../../hooks";
import useAsync from "../../../hooks/useAsync";
import { ErrorPage } from "../../error";
import generateLocationBreadcrumbs from "./LocationsBreadcrumbs";

function BaseLayout() {
  const locationService = new LocationService();
  const providerService = new ProviderService();

  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const { title } = usePageTitle();
  const locationId = parseInt(useUrlParams("id"));
  const providerId = parseInt(useUrlParams("providerId"));

  const { value: locationData, loading: locationLoading, error: locationError } = useAsync(() => locationService.get(locationId), [locationId]);
  const { value: providerData } = useAsync(() => providerService.get(providerId), [providerId]);

/**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
    if(locationId && providerId) {
      const newBreadcrumbs:BreadcrumbProps = generateLocationBreadcrumbs(location.pathname, locationData?.name ?? "", locationId, providerData?.name, providerId);
      setBreadcrumbs(newBreadcrumbs);
    }
    else if (locationId) {
      const newBreadcrumbs:BreadcrumbProps = generateLocationBreadcrumbs(location.pathname, locationData?.name ?? "", locationId);
      setBreadcrumbs(newBreadcrumbs);
    }
  }, [location.pathname, locationData, providerData])

  if(locationLoading) return <></>
  if(locationError){
    return <ErrorPage message={locationError.message} />
  }
  return (
    <PageLayout title={title} topbar={<LocationTopbar />} breadcrumbs={breadcrumbs}>
      <Outlet />
    </PageLayout>
  )
}

export function LocationLayout() {
  // Wrap the BaseUserLayout in a PageTitleProvider.
  // This prevents an error of the context not being initialized when the usePageTitle hook is called.
  return (
    <PageTitleProvider>
      <BaseLayout />
    </PageTitleProvider>
  );
}

export default LocationLayout
