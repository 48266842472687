import { motion } from "framer-motion";
import { router, routes } from "../../app/routes";
import { Button } from "../../components";

export function RegisterConfirmationPage() {
  return (
    <motion.div
      initial={{ y: "10px", opacity: 0 }}
      animate={{y: 0, opacity: 1 }}
      exit={{ y: "50%", opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut"}}
    >
      <h2 className="fs-1">Your Account Has Been Created!</h2>
      <h4 className="fs-5 text-muted mb-4 fw-lighter">🎉 Welcome to Ameena! We're excited to have you on board.</h4>
      <p>You are now ready to simplify and enhance how you manage bookings for your studio.</p>
      <p>Here’s how you can get started:</p>
      <ul className="row g-2">
        <li><span className="fw-bold">Manage Your Bookings: </span>Jump straight into your booking calendar and start scheduling your appointments.</li>
        <li><span className="fw-bold">Customize Your Services: </span>Add details about the services you offer, customize your availability, and set your preferred working hours.</li>
        <li><span className="fw-bold">Engage Your Clients: </span>Check out our tools for client management to provide personalized service and communication.</li>
      </ul>
      <br />
      <Button className="btn-primary" onClick={() => { router.navigate(routes.account.login) }}>Login</Button>
    </motion.div>
  );
}
export default RegisterConfirmationPage;
