import { useMemo, useEffect, useState } from "react";
import { routes } from "../../../app/routes";
import { SubmenuItem, IconDefinitions, TopbarSubmenu } from "../../../components";
import { useUrlParams } from "../../../hooks";

export const OrganizationsIndexTobpar = (props: any) => {

  const returnUrl = useUrlParams("returnUrl");
  const id = useUrlParams("id");

  const defaultSubmenuItems: SubmenuItem[] = useMemo(
    (): SubmenuItem[] => [
      {
        label: "Organizations",
        icon: IconDefinitions.organization,
        url: routes.organization.index,
        activeMatch: routes.organization.index,
        activeMatchExact: true,
        params: new Map([["id", id]]),
      },
    ],
    [id]
  );

  useEffect(() => {
    const backItem: SubmenuItem = {
      label: "Back",
      icon: IconDefinitions.back,
      url: returnUrl ?? "",
    };

    if (returnUrl) {
      setSubmenuItems([backItem, ...defaultSubmenuItems]);
    } else {
      setSubmenuItems(defaultSubmenuItems);
    }
  }, [defaultSubmenuItems, id, returnUrl]);

  const [submenuItems, setSubmenuItems] =
    useState<SubmenuItem[]>(defaultSubmenuItems);

  return <TopbarSubmenu className="p-0" items={submenuItems} />;
};

export default OrganizationsIndexTobpar;

