import { Outlet, useLocation } from "react-router-dom";
import PageLayout, { PageBreadcrumbProps } from "../../PageLayout";
import UserTopbar from "./UserTopbar";
import { UserService } from "../../../services";
import useAsync from "../../../hooks/useAsync";
import { PageTitleProvider, usePageTitle, useUrlParams } from "../../../hooks";
import { ErrorPage } from "../../error";
import { useEffect, useState } from "react";
import { BreadcrumbProps } from "../../../components";
import generateUserBreadcrumbs from "./UsersBreadcrumbs";


function BaseLayout() {
  const userService = new UserService();
  const id = parseInt(useUrlParams("id"));
  const { value: user, loading, error } = useAsync(() => userService.get(id), [id]);
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const location = useLocation();
  const { title } = usePageTitle();

  /**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
    if(id) {
      const newBreadcrumbs:BreadcrumbProps = generateUserBreadcrumbs(location.pathname, user?.email ?? "", id);
      setBreadcrumbs(newBreadcrumbs);
    }
  }, [location.pathname, user])

  if(loading) return <></>
  if(error){
    return <ErrorPage message={error.message} />
  }
  return (
    <PageLayout title={`${title}`} topbar={<UserTopbar />} breadcrumbs={breadcrumbs}>
      <Outlet />
    </PageLayout>
  )
}

export function UserLayout() {
  // Wrap the BaseUserLayout in a PageTitleProvider.
  // This prevents an error of the context not being initialized when the usePageTitle hook is called.
  return (
    <PageTitleProvider>
      <BaseLayout />
    </PageTitleProvider>
  );
}

export default UserLayout
