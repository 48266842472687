import { MenuItem } from "@progress/kendo-react-layout";
import { useCallback } from "react";
import { Button, IconAndText, IconDefinitions, TitlelessCard, ODataGrid, ResponsiveGridColumn, Breakpoints, ConfirmationModal, Icon } from "../../components";
import { useCRUDHandlers, useGridActions, useSetPageTitle, useSharedState } from "../../hooks";
import { usePageActions } from "../PageLayout";
import { AddOrganizationModal } from "./components";
import { OrganizationModel } from "../../data/entities";
import { GridCellProps } from "@progress/kendo-react-grid";
import { routes } from "../../app/routes";
import { OrganizationService } from "../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../app";
import { usePermission } from "../../hooks/usePermission";
import { PermissionDefinitions } from "../../security";

export function OrganizationsIndexPage() {

  const gridData = useSharedState<Array<OrganizationModel>>([]);
  const organizationService = new OrganizationService();
  const { onClick, onSelect, showDelete, deleteItemState, showAddState, showDeleteState } = useGridActions<OrganizationModel>(routes.organization.details);
  const { gridRef, getItems, addItem, deleteItem } = useCRUDHandlers<OrganizationModel>(organizationService, "Organization", showAddState);
  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canAddOrganization = usePermission(PermissionDefinitions.organization.create(tenantId!));
  const canDeleteOrganization = usePermission(PermissionDefinitions.organization.delete(tenantId!));

  useSetPageTitle("Organizations", []);
  usePageActions(
    canAddOrganization && (
      <Button
        onClick={() => {
          showAddState.setter(true);
        }}
      >
        <IconAndText iconName={IconDefinitions.add} text="Add" />
      </Button>
    )
  , [canAddOrganization]);

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <>
      {canDeleteOrganization && (
        <td className="text-center">
          <Icon
            iconName={IconDefinitions.delete}
            className="text-danger"
            onClick={() => showDelete(props.dataItem)}
          />
        </td>
        )}
      </>
    ),
    [showDelete, canDeleteOrganization]
  );

  return (
    <TitlelessCard bodyClassName="p-0">
      <ODataGrid
        ref={gridRef}
        getData={() => getItems(`filter=isDeleted eq ${false}`)}
        dataState={gridData}
        onRowClick={onClick}
        onRowMenuSelect={onSelect}
        sort={[{ field: "name", dir: "desc"}]}
        scrollable="scrollable"
        pageSize={10}
      >
        <ResponsiveGridColumn
          title="Name"
          field="name"
          sortable={true}
        />
        <ResponsiveGridColumn
          className="column-url"
          field="url"
          title=" "
          sortable={false}
          filterable={false}
          headerCell={() => null}
          cell={canDeleteOrganization == true ? commandsCell : undefined}
          width={50}
          breakpoint={Breakpoints.sm}
        />
        <MenuItem
          text="Open"
          data={{ action: "open" }}
          icon="hyperlink-open"
        />
        <MenuItem
          text="Open Tab"
          data={{ action: "opentab" }}
          icon="windows"
        />
        {
          canDeleteOrganization && (
            <MenuItem
              text="Delete"
              data={{ action: "delete" }}
              icon={IconDefinitions.delete}
            />
          )
        }
      </ODataGrid>
      <AddOrganizationModal
        showAddState={showAddState}
        showState={showAddState}
        onSubmit={addItem}
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Delete ${deleteItemState.value?.name}`}
        message={`You are about to delete this organization, it cannot be undone. Are you sure you want to delete ${deleteItemState.value.name}?`}
        confirmText="Delete"
        onConfirm={() => deleteItem(deleteItemState.value)}
      />
    </TitlelessCard>
  )
}

export default OrganizationsIndexPage;
