import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { UrlService } from "../../services";
import { IconAndText } from "../icons";
import "./styles/AppSidebarItem.scss";

interface AppSidebarItemProps extends React.HTMLAttributes<HTMLLIElement> {
  text: string;
  url: string;
  icon?: string;
  image?: string;
  onClick?: React.MouseEventHandler;
  additionalClass?: string;
  activeMatchUrl?: string | string[];
  activeMatchExact?: boolean;
}

export function AppSidebarItem({
  text,
  url,
  icon,
  image,
  onClick,
  additionalClass = "",
  activeMatchUrl,
  activeMatchExact = true,
  ...restProps
}: Readonly<AppSidebarItemProps>) {
  const location = useLocation();

  // Update active class based on current location
  useEffect(() => {}, [location]);

  const isActive = activeMatchUrl
    ? UrlService.isUrlActive(activeMatchUrl, activeMatchExact)
    : false;
  const activeClass = isActive ? "active" : "";
  const combinedClass = `nav-item ${additionalClass} ${activeClass}`.trim();

  return (
    <li {...restProps} className={combinedClass}>
      <Link to={url} onClick={onClick} className="nav-link">
        <IconAndText textClassName="overflow-hidden text-nowrap ms-3" iconName={icon} text={text} image={image} />
      </Link>
    </li>
  );
}

export default AppSidebarItem;
