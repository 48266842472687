import { router, routes } from "../../app/routes";
import { Button as BSButton , Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { showSuccessMessage } from "../../utils";
import { UserModel } from "../../data/entities";
import UserService from "../../services/authentication/UserService";
import { Card } from "../../components/card/Card";
import * as yup from 'yup';
import { Formik } from 'formik';
import { motion } from "framer-motion";
import { IconAndText, IconDefinitions, Button } from "../../components";
import { usePageActions } from "../PageLayout";
import { useRef } from "react";
import { useSetPageTitle } from "../../hooks";

export function ProfileIndexPage() {

  const submitRef = useRef<HTMLButtonElement>(null);
  const validationSchema = yup.object().shape({
    email: yup.string().email("Email must be valid").required("Email is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
  });

  useSetPageTitle(UserService.getUserEmail(), []);
  usePageActions(
    <Button onClick={() => { submitRef.current?.click()}}>
      <IconAndText iconName={IconDefinitions.save} text="Save" />
    </Button>
  );

  return (
      <Card title="Update Your Profile">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            email: UserService.getUserEmail(),
            firstName: UserService.getFirstName(),
            lastName: UserService.getLastName(),
          }}
          onSubmit={async (values) => {
            const updatedProfileModel: UserModel = {
              id: UserService.getId(),
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              userName: UserService.getUserName()
            };

            const ok = await new UserService().updateProfile(updatedProfileModel);

            if (!ok) return;

            showSuccessMessage("Your profile was successfully updated.");
            router.navigate(routes.profile.index);
          }}
        >
          {({ values, handleSubmit, handleChange, touched, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <FloatingLabel label="Username" className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        name="username"
                        value={UserService.getDisplayName()}
                        disabled={true}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FloatingLabel label="Email" className="mb-3">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={touched.email && !!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FloatingLabel label="First Name" className="mb-3">
                      <Form.Control
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={values.firstName}
                        onChange={handleChange}
                        isInvalid={touched.firstName && !!errors.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FloatingLabel label="Last Name" className="mb-3">
                      <Form.Control
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={values.lastName}
                        onChange={handleChange}
                        isInvalid={touched.lastName && !!errors.lastName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
                <BSButton ref={submitRef} type="submit" className="d-none" />
              </Form>
            )
          }}

        </Formik>
      </Card>
  );
}

export default ProfileIndexPage;

