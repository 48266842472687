import { GridColumn } from "@progress/kendo-react-grid";
import { UserService } from "../../services";
import {
  ODataGrid,
  TitlelessCard,
} from "../../components";
import { routes } from "../../app/routes";
import { MenuItem } from "@progress/kendo-react-layout";
import { UserModel } from "../../data/entities";
import { useGridActions, useCRUDHandlers, useSetPageTitle, useSharedState } from "../../hooks";

export function UserIndexPage() {

  const gridData = useSharedState<Array<UserModel>>([]);
  const userService = new UserService();
  const { onClick, onSelect } = useGridActions<UserModel>(routes.user.details);
  const { gridRef, getItems } = useCRUDHandlers<UserModel>(userService, "User");
  useSetPageTitle("Users", []);

  return (
    <TitlelessCard className="p-0 m-0" bodyClassName="p-0">
      <ODataGrid
        ref={gridRef}
        getData={(oDataQuery) => getItems(oDataQuery)}
        dataState={gridData}
        onRowMenuSelect={onSelect}
        onRowClick={onClick}
        sort={[{ field: "userName", dir: "asc" }]}
        scrollable="scrollable"
      >
        <GridColumn
          title="User"
          field="userName"
          sortable={true}
          filter="text"
        />
        <MenuItem
          text="Open"
          data={{ action: "open" }}
          icon="hyperlink-open"
        />
        <MenuItem
          text="Open Tab"
          data={{ action: "opentab" }}
          icon="windows"
        />
      </ODataGrid>
    </TitlelessCard>
  );
}

export default UserIndexPage;

