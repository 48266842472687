import { Role } from "../../models";
import { UserService } from "../../services";
import { ActionItem } from "..";
import { IconDefinitions } from "../icons";
import { useMenuItems, useCommonItems, userHasRoles } from "./hooks";
import AppSidebarItem from "./AppSidebarItem";
import Gravatar from "react-gravatar";
import { Link } from "react-router-dom";
import { routes } from "../../app/routes";

interface AppSidebarProps extends React.PropsWithChildren {
  showToggle: boolean;
  onToggle?: () => void;
}

export function AppSidebar({ showToggle, onToggle, children }: Readonly<AppSidebarProps>) {
  const menuItems = useMenuItems();
  const commonItems = useCommonItems();

  const handleToggle = () => {
    onToggle?.();
  };

  return (
    <div className="p-2 h-100">
      <div className="sidebar h-100 z-1 p-3 d-flex flex-column bg-white border border-radius-md shadow-sm">
        <div className="d-flex flex-column justify-content-between h-100">
          <ul className="nav flex-column">
            {showToggle && (
              <ActionItem
                text="Main Menu"
                icon={IconDefinitions.menu}
                additionalClass="text-nowrap sidebar-toggle"
                onClick={handleToggle}
              />
            )}
            {children || (
              <>
                {commonItems.map(item => (
                  <AppSidebarItem
                    key={item.text}
                    text={item.text}
                    additionalClass={item.className}
                    image={item.image}
                    url={item.url}
                    icon={item.icon}
                    activeMatchUrl={item.activeMatchUrl}
                  />
                ))}
                <ActionItem
                  text="Sign out"
                  icon={IconDefinitions.signOut}
                  additionalClass="text-nowrap"
                  onClick={UserService.signout}
                />
              </>
            )}
          </ul>
          <div>
            <div>
              {!children && (
                <>
                  {userHasRoles([
                    Role.administrator,
                    Role.tenant_admin,
                    Role.owner,
                    Role.manager,
                  ]) && (
                    <ul className="nav flex-column mb-2 border-top border-bottom">
                      {menuItems.map(item => (
                        <AppSidebarItem
                          key={item.text}
                          text={item.text}
                          url={item.url}
                          icon={item.icon}
                          activeMatchUrl={item.activeMatchUrl}
                        />
                      ))}
                    </ul>
                  )}
                </>
              )}
              <ul className="nav flex-column">
                <li className="nav-item opaque">
                  <Link to={routes.profile.index} className="nav-link" style={{marginLeft: "-0.5rem"}}>
                    <span className={`icon-and-text d-inline-flex align-items-center`}>
                      <Gravatar
                        className="navbar-avatar rounded-circle"
                        email={UserService.getUserEmail()}
                        size={48}
                        default="identicon"
                        alt={UserService.getDisplayName()}
                        title={UserService.getDisplayName()}
                      />
                      <span className="ms-3 lh-sm">
                        <div className="fw-bold fs-6">{UserService.getDisplayName()}</div>
                        <div className="text-muted">{UserService.getUserEmail()}</div>
                      </span>
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppSidebar;
