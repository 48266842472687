import {
  Breakpoints,
  ConfirmationModal,
  Icon,
  IconDefinitions,
  ODataGrid,
  ResponsiveGridColumn,
  TitlelessCard,
} from "../../components";
import { useCallback } from "react";
import { useSharedState, useUrlParams } from "../../hooks";
import ProviderServiceModel from "../../data/entities/booking/ProviderServiceModel";
import { AddProviderServiceModal } from "./components";
import useGridActions from "../../hooks/useGridActions";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useCRUDHandlers } from "../../hooks/useCRUDHandlers";
import { ProviderServiceService } from "../../services";
import { Container } from "react-bootstrap";

interface ProviderServicesPageProps {
}

export function ProviderServicesPage(props: Readonly<ProviderServicesPageProps>) {

  const gridData = useSharedState<Array<ProviderServiceModel>>([]);
  const providerServiceService = new ProviderServiceService();
  const providerId = parseInt(useUrlParams("providerId"));
  const { showAddState,  showEditState, showDeleteState, showDelete, deleteItemState } = useGridActions<ProviderServiceModel>()
  const { gridRef, getItems, addItem, deleteItem } = useCRUDHandlers<ProviderServiceModel>(providerServiceService, "Service", showAddState, showEditState);

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <td className="text-center">
        <Icon
          iconName={IconDefinitions.delete}
          className="text-danger"
          onClick={() => showDelete(props.dataItem)}
        />
      </td>
    ),
    [showDelete]
  );

  return (
    <>
      <div className="h-100 d-flex flex-column justify-content-between">
        <TitlelessCard bodyClassName="p-0">
          <ODataGrid
            ref={gridRef}
            dataState={gridData}
            sortable={false}
            filterable={false}
            getData={() => getItems(`filter=providerId eq ${providerId} &$expand=service`)}
            sort={[{ field: "service.name", dir: "desc"}]}
            scrollable="scrollable"
            pageSize={10}
          >
            <ResponsiveGridColumn
              title="Service"
              sortable={true}
              field="service.name"
            />
            <ResponsiveGridColumn
              className="column-url"
              field="url"
              title=" "
              headerCell={() => null}
              cell={commandsCell}
              width={50}
              breakpoint={Breakpoints.sm}
            />
          </ODataGrid>
        </TitlelessCard>
      </div>
      <AddProviderServiceModal
        showState={showAddState}
        providerId={providerId}
        showAddState={showAddState}
        onSubmit={addItem}
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Remove ${deleteItemState.value?.service?.name}`}
        message={`You are about to delete this provider service, it cannot be undone. Are you sure you want to delete ${deleteItemState.value.service?.name}?`}
        confirmText="Delete"
        onConfirm={() => deleteItem(deleteItemState.value)}
      />
    </>
  );
}

export default ProviderServicesPage;

