import { MenuItem } from "@progress/kendo-react-layout"
import { TitlelessCard, ODataGrid, Breakpoints, ConfirmationModal, IconAndText, IconDefinitions, ResponsiveGridColumn, Icon, Button } from "../../components"
import { useCallback } from "react";
import { useSetPageTitle, useSharedState, useUrlParams } from "../../hooks";
import { usePageActions } from "../PageLayout";
import ProviderModel from "../../data/entities/booking/ProviderModel";
import { GridCellProps } from "@progress/kendo-react-grid";
import useGridActions from "../../hooks/useGridActions";
import { useCRUDHandlers } from "../../hooks/useCRUDHandlers";
import { ProviderService } from "../../services";
import AddProviderModal from "./components/AddProviderModal";
import ManageProviderModal from "./components/ManageProviderModal";
import { routes } from "../../app/routes";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app";
import usePermission from "../../hooks/usePermission";
import { PermissionDefinitions } from "../../security";

export function LocationProvidersPage() {

  const location = useLocation();
  const gridData = useSharedState<Array<ProviderModel>>([]);
  const locationId = useUrlParams("id");
  const providerService = new ProviderService();
  const { onClick, onSelect, showDelete, showAddState, showEditState, editItemState, showDeleteState, deleteItemState } = useGridActions<ProviderModel>(`${routes.location.provider.details}?returnUrl=${location.pathname}`);
  const { gridRef, getItems, addItem, editItem, deleteItem } = useCRUDHandlers<ProviderModel>(providerService, "Provider", showAddState, showEditState);

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canAddProvider = usePermission(PermissionDefinitions.provider.get(tenantId!));
  const canDeleteProvider = usePermission(PermissionDefinitions.provider.delete(tenantId!));

  useSetPageTitle("Providers", []);
  usePageActions(
    canAddProvider && (
      <Button
        onClick={() => {
          showAddState.setter(true);
        }}
      >
        <IconAndText iconName={IconDefinitions.add} text="Add" />
      </Button>
    )
  , [canAddProvider]);

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <>
        {canDeleteProvider  && (
          <td className="text-center">
            {canDeleteProvider && (
              <Icon
                iconName={IconDefinitions.delete}
                className="text-danger"
                onClick={() => showDelete(props.dataItem)}
              />
            )}
          </td>
        )}
      </>
    ),
    [showDelete, canDeleteProvider]
  );

  return (
    <TitlelessCard bodyClassName="p-0">
      <ODataGrid
        ref={gridRef}
        getData={() => getItems(`filter=locationId eq ${locationId}`)}
        dataState={gridData}
        onRowClick={(e: any) => onClick(e, new Map([["id", locationId], ["providerId", e.dataItem.id]]))}
        onRowMenuSelect={onSelect}
        sort={[{ field: "description", dir: "desc"}]}
        scrollable="scrollable"
        pageSize={10}
      >
        <ResponsiveGridColumn
          title="Name"
          field="name"
          sortable={true}
        />
        <ResponsiveGridColumn
          title="Description"
          field="description"
          sortable={true}
        />
        <ResponsiveGridColumn
          title="Type"
          field="providerType"
          sortable={true}
        />
        <ResponsiveGridColumn
          title="Capacity"
          field="capacity"
          sortable={true}
        />
        <ResponsiveGridColumn
          className="column-url"
          field="url"
          title=" "
          sortable={false}
          filterable={false}
          headerCell={() => null}
          cell={canDeleteProvider == true ? commandsCell : undefined}
          width={50}
          breakpoint={Breakpoints.sm}
        />
        <MenuItem
          text="Open"
          data={{ action: "open" }}
          icon="hyperlink-open"
        />
        <MenuItem
          text="Open Tab"
          data={{ action: "opentab" }}
          icon="windows"
        />
        {canDeleteProvider && (
          <MenuItem
          text="Delete"
          data={{ action: "delete" }}
          icon={IconDefinitions.delete}
        />
        )}
      </ODataGrid>
        <AddProviderModal
          showState={showAddState}
          onSubmit={addItem}
        />
        <ManageProviderModal
          showState={showEditState}
          dataItemState={editItemState}
          onSubmit={editItem}
        />
        <ConfirmationModal
          showState={showDeleteState}
          title={`Delete Provider ${deleteItemState.value?.name}`}
          message={`You are about to delete this provider, it cannot be undone. Are you sure you want to delete ${deleteItemState.value.name}?`}
          confirmText="Delete"
          onConfirm={() => deleteItem(deleteItemState.value)}
        />
    </TitlelessCard>
  )
}

export default LocationProvidersPage
