import { MenuItem } from "@progress/kendo-react-layout";
import {
  Breakpoints,
  Button,
  ConfirmationModal,
  Icon,
  IconAndText,
  IconDefinitions,
  ODataGrid,
  ResponsiveGridColumn,
  TitlelessCard,
} from "../../components";
import { useCallback } from "react";
import { useCRUDHandlers, useGridActions, useSetPageTitle, useSharedState, useUrlParams } from "../../hooks";
import { AddServiceModal, ManageServiceModal } from "./components";
import { usePageActions } from "../PageLayout";
import { GridCellProps } from "@progress/kendo-react-grid";
import ServiceModel from "../../data/entities/booking/ServiceModel";
import { ServiceService } from "../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../app";
import usePermission from "../../hooks/usePermission";
import { PermissionDefinitions } from "../../security";

export function ServiceTypeServicesPage() {

  const id = parseInt(useUrlParams("id"));
  const serviceService = new ServiceService();
  const gridData = useSharedState<Array<ServiceModel>>([]);
  const { onClick, onSelect, showAddState, showEditState, showDeleteState, editItemState, deleteItemState, showDelete, showEdit } = useGridActions<ServiceModel>();
  const { gridRef, getItems, addItem, editItem, deleteItem } = useCRUDHandlers<ServiceModel>(serviceService, "Service", showAddState, showEditState);

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canAddService = usePermission(PermissionDefinitions.service.create(tenantId!));

  useSetPageTitle("Services", []);
  usePageActions(
    canAddService && (

      <Button
        onClick={() => {
          showAddState.setter(true);
        }}
      >
        <IconAndText iconName={IconDefinitions.add} text="Add" />
      </Button>
    ),
    [canAddService]
  );

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <td className="text-center">
        <Icon
          iconName={IconDefinitions.delete}
          className="text-danger"
          onClick={() => showDelete(props.dataItem)}
        />
      </td>
    ),
    [showDelete]
  );

  return (
    <TitlelessCard bodyClassName="p-0">
      <ODataGrid
        ref={gridRef}
        getData={(oDataQuery) => getItems(`filter=serviceTypeId eq ${id}`)}
        dataState={gridData}
        onRowMenuSelect={onSelect}
        onRowClick={onClick}
        sort={[{ field: "name", dir: "desc"}]}
        scrollable="scrollable"
        pageSize={10}
      >
        <ResponsiveGridColumn
          title="Name"
          field="name"
          sortable={true}
        />
        <ResponsiveGridColumn
          title="Default Price"
          field="defaultPrice"
          sortable={true}
        />
        <ResponsiveGridColumn
          title="Hide Providers"
          field="hideProviders"
          filterable={false}
          cell={(props) => <td>{props.dataItem.hideProviders ? "Yes" : "No"}</td>}
        />
        <ResponsiveGridColumn
          className="column-url"
          field="url"
          title=" "
          sortable={false}
          filterable={false}
          headerCell={() => null}
          cell={commandsCell}
          width={50}
          breakpoint={Breakpoints.sm}
        />
        <MenuItem
          text="Open"
          data={{ action: "open" }}
          icon="hyperlink-open"
        />
        <MenuItem
          text="Delete"
          data={{ action: "delete" }}
          icon={IconDefinitions.delete}
        />
      </ODataGrid>
        <AddServiceModal
          showAddState={showAddState}
          showState={showAddState}
          onSubmit={addItem}
        />
        <ManageServiceModal
          showState={showEditState}
          dataItemState={editItemState}
          onSubmit={editItem}
        />
        <ConfirmationModal
          showState={showDeleteState}
          title={`Delete ${deleteItemState.value?.name}`}
          message={`You are about to delete this service, it cannot be undone. Are you sure you want to delete ${deleteItemState.value.name}?`}
          confirmText="Delete"
          onConfirm={() => deleteItem(deleteItemState.value)}
        />
    </TitlelessCard>
  );
}

export default ServiceTypeServicesPage;

