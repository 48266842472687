import { Outlet, useLocation } from "react-router-dom";
import { ServiceTypeTopbar } from "./ServiceTypeTopbar";
import PageLayout, { PageBreadcrumbProps } from "../../PageLayout";
import { useState, useEffect } from "react";
import { BreadcrumbProps } from "../../../components";
import { PageTitleProvider, usePageTitle, useUrlParams } from "../../../hooks";
import useAsync from "../../../hooks/useAsync";
import { ServiceTypeService } from "../../../services";
import { generateServiceTypesBreadcrumbs } from './ServiceTypesBreadcrumbs'
import { ErrorPage } from "../../error";

function BaseLayout() {
  const serviceTypeService = new ServiceTypeService();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const { title } = usePageTitle();

  const id = parseInt(useUrlParams("id"));

  const { value: serviceType, loading, error } = useAsync(() => serviceTypeService.get(id), [id]);

  useEffect(() => {
    if(id) {
      const newBreadcrumbs:BreadcrumbProps = generateServiceTypesBreadcrumbs(location.pathname, serviceType?.name, id);
      setBreadcrumbs(newBreadcrumbs);
    }
  }, [location.pathname, serviceType])

  if(loading) return <></>
  if(error){
    return <ErrorPage message={error.message} />
  }
  return (
    <PageLayout title={title} topbar={<ServiceTypeTopbar />} breadcrumbs={breadcrumbs}>
      <Outlet />
    </PageLayout>
  )
}

export function ServiceTypeLayout() {
  // Wrap the BaseUserLayout in a PageTitleProvider.
  // This prevents an error of the context not being initialized when the usePageTitle hook is called.
  return (
    <PageTitleProvider>
      <BaseLayout />
    </PageTitleProvider>
  );
}

export default ServiceTypeLayout
