import {
  Button,
  SlideOutModal,
  SlideOutModalProps,
} from "../../../components";
import { Button as BSButton, Form } from "react-bootstrap";
import { Formik } from 'formik';
import { useRef } from "react";
import { Schedule } from "../../../data/entities/booking/Schedule";
import { sharedProperty } from "../../../hooks";
import ScheduleEditor from "../../../components/Schedule/ScheduleEditor";
import { ScheduleBlock } from "../../../data/entities/booking/ScheduleBlock";
import { validationSchema } from '../validation/ScheduleValidation';

export interface EditScheduleModalProps extends SlideOutModalProps {
  onSubmit: any;
  schedule: sharedProperty<Schedule>;
}

export function EditScheduleModal(props: Readonly<EditScheduleModalProps>) {

  const submitRef = useRef<HTMLButtonElement>(null);

  return (
    <SlideOutModal
      {...props}
      title="Edit Schedule"
      size="lg"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => {
                console.log("clicked visible button");
                submitRef.current?.click()
              }} variant="primary" className="w-100">
                Save
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          scheduleId: props.schedule.value.id,
          scheduleBlocks: props.schedule.value.scheduleBlocks || [],
        }}
        onSubmit={(values) => {
          values.scheduleBlocks.forEach((block: ScheduleBlock) => {
            delete block.id;
          });
          props.onSubmit(values.scheduleBlocks as ScheduleBlock[], values.scheduleId);
          props.showState.setter(false);
        }}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <ScheduleEditor />
              <BSButton ref={submitRef} type="submit" className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </SlideOutModal>
  );
}

export default EditScheduleModal;
