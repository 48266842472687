import { PropsWithChildren } from "react";
import logo from "../../images/branding/logo-brown-text-white-inline.svg";
import { routes, router } from "../../app/routes";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store/store";
import Avatar from "react-avatar";
import { UserService } from "../../services";
import { useAuthentication } from "../../hooks/useAuthentication";
import {
  IconDefinitions,
  Icon,
  LinkLooksLike,
  IconAndText,
} from "..";
import { Dropdown } from "react-bootstrap";
import { motion } from "framer-motion";

interface AppTopbarProps extends PropsWithChildren {
  showToggle: boolean;
  onToggle?: () => void;
}

export function AppTopbar(props: Readonly<AppTopbarProps>) {
  const isLoggedIn = useSelector((state: RootState) => state.authentication.isSignedIn);
  const userTenants = useSelector((state: RootState) => state.authentication.tenants);
  const loggedTenant = useSelector((state: RootState) => userTenants.find(t => t.id === state.authentication.tenantId));

  const { switchTenant } = useAuthentication();

  const menuToggle = (e: any) => {
    props.onToggle?.call(null);
  };

  const logoClick = () => {
    router.navigate(routes.home);
  };

  return (
    <nav
      className={`navbar navbar-expand-lg bg-primary py-2 px-3 ${isLoggedIn ? 'justify-content-between' : 'justify-content-center'}`}
    >
      <LinkLooksLike
        className="text-lg text-light toggle lg-invisible"
        onClick={menuToggle}
      >
        <Icon iconName={IconDefinitions.menu} />
      </LinkLooksLike>
      <span className={`cursor-pointer py-2 lh-5 fs-3 me-0 ms-3`} onClick={logoClick}>
        <motion.div
          className="box"
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <img className="d-block" src={logo} width={176} alt="Ameena logo" />
        </motion.div>
      </span>
      <ul className="navbar-nav">
      {isLoggedIn && (
          <Dropdown>
            <Dropdown.Toggle as="div" className="cursor-pointer">
              <Avatar name={loggedTenant?.name} size="48" round={true} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="text-center border-radius-sm">
              <Dropdown.Item className="d-flex align-items-center justify-content-start" disabled>
                <Avatar name={loggedTenant?.name} size="24" round={true} className="me-2" />
                {loggedTenant?.name}
              </Dropdown.Item>
              <Dropdown.Divider />
              {userTenants
                .filter((tenant) => tenant.id !== loggedTenant?.id)
                .map((tenant) => (
                  <Dropdown.Item
                    key={tenant.id}
                    onClick={() => switchTenant(tenant.id)}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <Avatar name={tenant.name} size="24" round={true} className="me-2" />
                    {tenant.name}
                  </Dropdown.Item>
                ))}
              {userTenants.length > 1 && <Dropdown.Divider />}
              <Dropdown.Item
                onClick={() => {
                  UserService.signout();
                }}
              >
                <IconAndText iconName={IconDefinitions.signOut} text="Sign out" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </ul>
    </nav>
  );
}

export default AppTopbar;

