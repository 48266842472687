import React from 'react';
import { Schedule as ScheduleModel } from '../../data/entities/booking/Schedule';
import { ScheduleBlock as ScheduleBlockModel } from '../../data/entities/booking/ScheduleBlock';
import { Row, Col } from 'react-bootstrap';

import style from './styles/Schedule.module.scss';
import { to12HourTime } from '../../utils';

interface ScheduleProps {
  schedule: ScheduleModel;
  editable?: boolean;
  className?: string;
}

interface ScheduleBlockProps {
  block: ScheduleBlockModel;
  className?: string;
}

const daysOfWeek = [
  { name: 'SUN', value: 0 },
  { name: 'MON', value: 1 },
  { name: 'TUE', value: 2 },
  { name: 'WED', value: 3 },
  { name: 'THU', value: 4 },
  { name: 'FRI', value: 5 },
  { name: 'SAT', value: 6 },
];

const Schedule: React.FC<ScheduleProps> & {
  Block: React.FC<ScheduleBlockProps>;
} = ({ schedule, className }) => {
  // Group schedule blocks by day of week
  const blocksByDay: { [key: number]: ScheduleBlockModel[] } = {};
  daysOfWeek.forEach(day => {
    blocksByDay[day.value] = schedule.scheduleBlocks.filter(
      block => block.dayOfWeek === day.value
    );
  });

  return (
    <div className={`${className} schedule`}>
      {daysOfWeek.map(day => (
        <Row key={day.value} className="align-items-start mb-3">
          <Col md={3} className="fw-bold">
            {day.name}
          </Col>
          <Col>
            {blocksByDay[day.value].length > 0 ? (
              blocksByDay[day.value].map(block => (
                <Schedule.Block className={"mb-2"} key={block.id} block={block} />
              ))
            ) : (
              <span className={style.closed}>Closed</span>
            )}
          </Col>
        </Row>
      ))}
    </div>
  );
};

Schedule.defaultProps = {
  editable: false,
};

Schedule.Block = ({ block, className } : ScheduleBlockProps) => {
  return (
    <div>
      <span className={`${style.time} ${className}`}>{to12HourTime(block.startTime)}</span> - <span className={style.time}>{to12HourTime(block.endTime)}</span>
    </div>
  );
};

export default Schedule;
