import { GridSelectionChangeEvent, GridHeaderSelectionChangeEvent } from "@progress/kendo-react-grid";
import { useState, useEffect, useCallback } from "react";
import { RoleModel } from "../../../data/entities";
import { RoleScopeEnum } from "../../../data/entities/user/RoleScopeEnum";
import { useUrlParams } from "../../../hooks";
import { RoleService } from "../../../services";
import { UserEntityModel } from "../../../models/user/roleassignments/UserEntityModel";

export function useRoles(entity: UserEntityModel, onSubmit: any){

  const id = useUrlParams("id");
  const roleService = new RoleService();

  const [roles, setRoles] = useState<RoleModel[]>([]);
  const [organizationId, setOrganizationId] = useState<number | null>(null);
  const [locationId, setLocationId] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let roles = (await roleService.getAll()).filter(role => role.scope == entity.scope);
        const data= roles.map((dataItem: RoleModel) => ({
          ...dataItem,
          selected: entity.roles?.some(a => a === dataItem.name) ?? false
        }));
        setRoles(data);
      } catch (e) {
        console.error("Error loading roles", e);
      }
    };

    fetchData();

    setOrganizationId(null);
    setLocationId(null);

    // assign the id's of tenant, organization, and location depending on the scope
    if (entity.scope === RoleScopeEnum.Organization) {
      setOrganizationId(entity.id);
    } else if (entity.scope === RoleScopeEnum.Location) {
      setLocationId(entity.id);
    }

  }, [id, entity]);


  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newDataState = roles?.map(item => ({
        ...item,
        selected: item.id === event.dataItem.id ? !item.selected : item.selected
      }));
      setRoles(newDataState);
    },
    [roles]
  );

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const target = event.syntheticEvent.target as HTMLInputElement;
      const isChecked = target.checked;

      const newDataState = roles?.map(item => ({
        ...item,
        selected: isChecked
      }));

      setRoles(newDataState);
    },
    [roles]
  );


  const handleSubmit = useCallback(() => {
    // return the id's of the selected roles
    const roleIds = roles?.filter(item => item.selected).map(item => item.id);
    const request = {
      roleIds: roleIds,
      userId: id,
      organizationId: organizationId,
      locationId: locationId
    }
    if (roleIds) {
      onSubmit(id, request);
    }
  }, [roles, onSubmit]);

  return {
    roles,
    handleSubmit,
    onSelectionChange,
    onHeaderSelectionChange
  }
}
