import { router, routes } from "../../../app/routes";
import {
  TopbarSubmenu,
  IconDefinitions,
  SubmenuItem,
} from "../../../components";
import { useMemo } from "react";
import { useUrlParams } from "../../../hooks";
import { useLocation } from "react-router-dom";

export const OrganizationTopbar = (props: any) => {

  const location = useLocation();

  const id = useUrlParams("id");

  const submenuItems: SubmenuItem[] = useMemo(
    (): SubmenuItem[] => [
        {
          label: "Back",
          icon: IconDefinitions.back,
          url: routes.organization.index,
        },
        {
          label: "Details",
          icon: IconDefinitions.squareInfo,
          url: routes.organization.details,
          activeMatch: routes.organization.details,
          activeMatchExact: true,
          params: new Map([["id", id]]),
        },
        {
          label: "Locations",
          icon: IconDefinitions.home,
          clickHandler: () =>
            router.navigate(
              `${routes.location.index}?organizationFilterId=${id}&returnUrl=${location.pathname}`
            ),
          url: "",
        }
      ],
    [id]
  );

  return <TopbarSubmenu className="p-0" items={submenuItems} />;
};

export default OrganizationTopbar;

