import { GridCellProps } from "@progress/kendo-react-grid";
import { getExpiry } from "../../../utils";

export function useExpiryDate() {
  const getExpiryDateCell = (props: GridCellProps) => (
    <td>
      {
        props.dataItem.expiryDate < new Date() ?
          <span className="text-danger">Expired</span> :
          getExpiry(props.dataItem.expiryDate)
      }
    </td>
  )

  return {
    getExpiryDateCell
  }
}
