import { useSharedState, useGridActions } from "../../../hooks";
import { RoleScopeEnum } from "../../../data/entities/user/RoleScopeEnum";
import { UserEntityModel } from "../../../models/user/roleassignments/UserEntityModel";

export function useTableActions(
) {

  const { showAddState: showManageRolesState, showDelete, showDeleteState } = useGridActions();

  const selectedEntity = useSharedState<UserEntityModel>({} as UserEntityModel);

  const handleGlobalRowClick = (globalRoles: string[]) => {
    selectedEntity.setter({
      name: "Global",
      id: 0,
      scope: RoleScopeEnum.Global,
      roles: globalRoles,
    });
    showManageRolesState.setter(true);
  };

  const handleTenantRowClick = (tenant: any) => {
    selectedEntity.setter({
      name: tenant.tenantName,
      id: tenant.tenantId,
      scope: RoleScopeEnum.Tenant,
      roles: tenant.roleNames,
    });
    showManageRolesState.setter(true);
  };

  const handleLocationRowClick = (location: UserEntityModel) => {
    selectedEntity.setter(
      {
        name: location.name,
        id: location.id,
        scope: RoleScopeEnum.Location,
        roles: location.roles,
      }
    );
    showManageRolesState.setter(true);
  }

  const handleOrganziationRowClick = (organization: UserEntityModel) => {
    selectedEntity.setter(
      {
        name: organization.name,
        id: organization.id,
        scope: RoleScopeEnum.Organization,
        roles: organization.roles,
      }
    );
    showManageRolesState.setter(true);
  }

  const handleDeleteClick = (entity: UserEntityModel) => {
    selectedEntity.setter(
      {
        name: entity.name,
        id: entity.id,
        scope: entity.scope,
        roles: entity.roles,
      }
    );
    showDeleteState.setter(true);
  }


  return {
    showManageRolesState,
    showDeleteState,
    showDelete,
    handleDeleteClick,
    handleGlobalRowClick,
    handleTenantRowClick,
    handleLocationRowClick,
    handleOrganziationRowClick,
    selectedEntity
  };
}
