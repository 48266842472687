import { Outlet, useLocation } from "react-router-dom";
import PageLayout, { PageBreadcrumbProps } from "../../PageLayout";
import { OrganizationService } from "../../../services";
import { useState, useEffect } from "react";
import { PageTitleProvider, usePageTitle, useUrlParams } from "../../../hooks";
import useAsync from "../../../hooks/useAsync";
import { ErrorPage } from "../../error";
import { generateOrganizationBreadcrumbs } from "./OrganizationsBreadcrumbs";
import OrganizationTopbar from "./OrganizationTopbar";
import { BreadcrumbProps } from "../../../components";

function BaseLayout() {
  const organizationService = new OrganizationService();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const { title } = usePageTitle();
  const id = parseInt(useUrlParams("id"));

  const { value: organization, loading, error } = useAsync(() => organizationService.get(id), [id]);

/**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
    if(id) {
      const newBreadcrumbs:BreadcrumbProps = generateOrganizationBreadcrumbs(location.pathname, organization?.name ?? "", id);
      setBreadcrumbs(newBreadcrumbs);
    }
  }, [location.pathname, organization])

  if(loading) return <></>
  if(error){
    return <ErrorPage message={error.message} />
  }
  return (
    <PageLayout title={title} topbar={<OrganizationTopbar />} breadcrumbs={breadcrumbs}>
      <Outlet/>
    </PageLayout>
  )
}

export function OrganizationLayout() {
  // Wrap the BaseUserLayout in a PageTitleProvider.
  // This prevents an error of the context not being initialized when the usePageTitle hook is called.
  return (
    <PageTitleProvider>
      <BaseLayout />
    </PageTitleProvider>
  );
}

export default OrganizationLayout
