import { Container, Row } from 'react-bootstrap';
import { Icon, IconDefinitions, IconAndText, ConfirmationModal, Button, Breakpoints, ODataGrid, ResponsiveGridColumn } from '../../components';
import { sharedProperty, useCRUDHandlers, useGridActions, useSharedState } from '../../hooks';
import ServiceDetailsPage from './ServiceDetailsPage';
import ServiceLocationService from '../../services/booking/ServiceLocationService';
import { AddServiceLocationModal } from './components';
import ServiceLocationModel from '../../data/entities/booking/ServiceLocationModel';
import { useCallback } from 'react';
import EditServiceLocationModal from './components/EditServiceLocationModal';
import { GridCellProps } from '@progress/kendo-react-grid';

interface ServiceLocationsPageProps {
  serviceId: number;
  showState: sharedProperty<boolean>;
}

export function ServiceLocationsPage(props: Readonly<ServiceLocationsPageProps>) {

  const gridData = useSharedState<Array<ServiceLocationModel>>([]);
  const serviceLocationsService = new ServiceLocationService();

  const { showAddState, editItemState,  showEditState, showDeleteState, showDelete, deleteItemState } = useGridActions<ServiceLocationModel>()
  const { gridRef, getItems, addItem, editItem, deleteItem } = useCRUDHandlers<ServiceLocationModel>(serviceLocationsService, "Location", showAddState, showEditState);

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <td className="text-center">
        <Icon
          iconName={IconDefinitions.delete}
          className="text-danger"
          onClick={() => showDelete(props.dataItem)}
        />
      </td>
    ),
    [showDelete]
  );
  return (
    <>
      <div className="h-100 d-flex py-3 flex-column justify-content-between">
        <Container>
          <ODataGrid
            ref={gridRef}
            dataState={gridData}
            getData={() => getItems(`$filter=serviceId eq ${props.serviceId}&$expand=Location`)}
            sort={[{ field: "location.name", dir: "desc"}]}
            scrollable="scrollable"
            filterable={false}
            pageSize={10}
          >
            <ResponsiveGridColumn
              title="Location"
              sortable={true}
              field="location.name"
            />
            <ResponsiveGridColumn
              title="Price"
              sortable={true}
              format='{0:c}'
              field="price"
            />
            <ResponsiveGridColumn
              className="column-url"
              field="url"
              title=" "
              sortable={false}
              headerCell={() => null}
              cell={commandsCell}
              width={50}
              breakpoint={Breakpoints.sm}
            />
          </ODataGrid>
        </Container>

        <Container className="pt-3 border-top">
          <Row className="g-0">
            <div className="col-12 pe-2">
              <Button onClick={() => { props.showState.setter(false) }} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-12 ps-2">
              <Button onClick={() => {showAddState.setter(true)}} className="w-100">
                <IconAndText iconName={IconDefinitions.add} text="Add New Location" />
              </Button>
            </div>
          </Row>
        </Container>
      </div>
        <EditServiceLocationModal
          showState={showEditState}
          dataItem={editItemState.value}
          onSubmit={editItem}
        />
        <AddServiceLocationModal
          showState={showAddState}
          onSubmit={addItem}
          serviceId={props.serviceId}
        />
        <ConfirmationModal
          showState={showDeleteState}
          title={`Remove Location `}
          message={`You are about to remove this location, it cannot be undone. Are you sure you want to remove ?`}
          confirmText="Delete"
          onConfirm={() => deleteItem(deleteItemState.value)}
        />
    </>
  )
}

export default ServiceDetailsPage;
