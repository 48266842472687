import { useParams } from "react-router-dom";
import {
  Card,
  SpinnerDefault,
  Toggle,
  Button,
  IconAndText,
  IconDefinitions
} from "../../components";
import { Col, FloatingLabel, Form, Row, Button as BSButton } from "react-bootstrap";
import { UrlService, UserService } from "../../services";
import * as yup from "yup";
import { Formik } from "formik";
import useAsync from "../../hooks/useAsync";
import { useDeleteUser } from "./hooks/useDeleteUser";
import { useRef } from "react";
import { usePageActions } from "../PageLayout";
import { UserModel } from "../../data/entities";
import { useCRUDHandlers, useSetPageTitle } from "../../hooks";

export function UserDeletePage() {
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");
  const submitRef = useRef<HTMLButtonElement>(null);
  const validationSchema = yup.object().shape({
    confirmed: yup.boolean().required("You must confirm the deletion."),
  })


  const { deleteUser: handleSubmit, showSpinner } = useDeleteUser();

  const userService = new UserService();
  const { deleteItem } = useCRUDHandlers<UserModel>(userService, "User");

  const { loading, value: user, error } = useAsync(() => new UserService().get(id), [id]);


  useSetPageTitle("Delete", []);
  usePageActions(
    <Button onClick={() => submitRef.current?.click()}>
      <IconAndText iconName={IconDefinitions.trashCan} text="Delete" />
    </Button>
  );

  if (loading) return <></>;
  if (error) {
    return (
      <Card title="Failed to load data.">
        <div>
          <em>Failed to load.</em>
        </div>
      </Card>
    );
  }
  return (
    <Card title="Delete User Account">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          confirmed: false,
        }}
        onSubmit={async (values) => { handleSubmit(values.confirmed, id, user?.userName!) }}
      >
        {({ values, errors, handleSubmit, handleChange }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="username">
                    <FloatingLabel label="Username" className="mb-3">
                      <Form.Control
                        type="text"
                        name="username"
                        value={user?.userName}
                        disabled={true}
                      />
                      <Form.Control.Feedback type="invalid">{errors.confirmed}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="my-2 px-1">
                <Col>
                  <p className="fs-5">
                    You must confirm that you want to delete this user account. This
                    isn't permanent, but you should be careful. If you accidentally
                    delete an account or if you need to reactivate one, you have to
                    send a new invitation to the same email address as the deleted
                    account. Once the user registers again using the new invitation,
                    the system will reactivate their old account exactly as it was
                    when you deleted it.
                  </p>
                </Col>
              </Row>

              <Row className="my-2">
                <Col>
                <div className="p-2">
                  <Toggle
                    id="deleteToggle"
                    className="fs-5"
                    description="Confirm"
                    initialValue={values.confirmed}
                    onChange={handleChange}
                  />
                </div>
                </Col>
              </Row>

              <BSButton ref={submitRef} type="submit" className="d-none" />
            </Form>
          )
        }}
      </Formik>
    </Card>
  );
}

export default UserDeletePage;

