import { BaseEntityService } from "../BaseEntityService"
import { LocationModel } from "../../data/entities";
import { LocationODataRepo, LocationRepo } from "../../data/repo/organization";


export class LocationService extends BaseEntityService<LocationModel, LocationRepo, LocationODataRepo> {
  constructor(){
    super(new LocationRepo(), new LocationODataRepo());
  }
}

export default LocationService;
