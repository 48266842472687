import {
  Button,
  IconAndText,
  IconDefinitions,
  Card,
  SpinnerDefault,
} from "../../components";
import { OrganizationService } from "../../services";
import useAsync from "../../hooks/useAsync";
import { Formik } from "formik";
import * as yup from "yup";
import { FloatingLabel, Form, Button as BSButton, Col, Row } from "react-bootstrap";
import { useCRUDHandlers, useSetPageTitle, useUrlParams } from "../../hooks";
import { ErrorPage } from "../error";
import { useRef } from "react";
import { usePageActions } from "../PageLayout";
import { OrganizationModel } from "../../data/entities";
import { useSelector } from "react-redux";
import { RootState } from "../../app";
import usePermission from "../../hooks/usePermission";
import { PermissionDefinitions } from "../../security";

export function OrganizationDetailsPage() {
  const organizationService = new OrganizationService();
  const id = parseInt(useUrlParams("id"));
  const submitRef = useRef<HTMLButtonElement>(null);
  const { value: organization, loading, error } = useAsync(() => organizationService.get(id), [id]);
  const { editItem } = useCRUDHandlers(organizationService, "Organization");

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canEditOrganization = usePermission(PermissionDefinitions.organization.edit(tenantId!));

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required")
  });

  useSetPageTitle(organization?.name ?? "", [organization]);
  usePageActions(
    canEditOrganization && (
      <Button onClick={() => submitRef.current?.click()}>
        <IconAndText iconName={IconDefinitions.save} text="Save" />
      </Button>
    ),
    [canEditOrganization]
  );

  if (error) return <ErrorPage message={error.message} />
  if (!organization || loading) return <SpinnerDefault />

  return (
    <Card title="Details">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          ...organization,
          name: organization.name,
        }}
        onSubmit={(values) => {
          editItem(values as OrganizationModel);
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
              <Col>
                <FloatingLabel label="Name">
                  <Form.Control
                      type="string"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      disabled={!canEditOrganization}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <BSButton ref={submitRef} type="submit" className="d-none" />
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default OrganizationDetailsPage;

