import {
  SubmenuItem,
} from "../../../components";
import { useState } from "react";
import { ModalSubmenu } from "../../../components/Submenu/Modal";

interface ServiceTopbarProps {
  onChangeTab: (tab: string) => void;
}

export const ServiceTopbar = ( { onChangeTab } : Readonly<ServiceTopbarProps>) => {

  const [activeTab, setActiveTab] = useState<string>('Details');

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    onChangeTab(tab);
  };

  const items: SubmenuItem[] = [
    {
      label: 'Details',
      url: '',
      clickHandler: () => handleTabChange('Details'),
      ifActive: activeTab === 'Details' ? 'active' : '',
    },
    {
      label: 'Locations',
      url: '',
      clickHandler: () => handleTabChange('Locations'),
      ifActive: activeTab === 'Locations' ? 'active' : '',
    },
  ]

  return <ModalSubmenu className="p-0" items={items} />;
};

export default ServiceTopbar;
