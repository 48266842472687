import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../app/routes";
import AccountService from "../../../services/account/AccountService";
import { ChangePasswordRequest } from "../../../models";

export function useChangePassword() {
  const accountService = new AccountService();
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();

  const changePassword = async (request: ChangePasswordRequest) => {
    setShowSpinner(true);
    const ok = await accountService.changePassword(request);
    setShowSpinner(false);
    if (ok) navigate(routes.profile.changePassword);
  };

  return {
    changePassword,
    showSpinner
  };
}
