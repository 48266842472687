import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Breadcrumbs, { Breadcrumb } from "../components/breadcrumbs/Breadcrumbs";
import { useLocation } from "react-router-dom";
import { Icon, IconDefinitions } from "../components";

// #region Page Actions Context

export const PageActionsContext = createContext({
  actions: <></>,
  setActions: (() => <></>) as React.Dispatch<
    React.SetStateAction<JSX.Element>
  >,
});

export function PageContextActions() {
  const actionsContext = useContext(PageActionsContext);
  return <>{actionsContext.actions}</>;
}

export function usePageActions(
  component: false | JSX.Element | null,
  deps: any[] = []
) {
  let actionsContext = useContext(PageActionsContext);

  return useEffect(() => {
      actionsContext.setActions(component || <></>);
  }, [...deps]);
}

// #endregion

function PageTitle(props: any) {
  if (props.title && props.title !== "") {
    return <h2>{props.title}</h2>;
  }
  return null;
}

export interface PageBreadcrumbProps {
  showHome?: boolean;
  values: Breadcrumb[];
}

export function PageBreadcrumbs(props?: PageBreadcrumbProps) {
  if (!props?.values?.length) return null;

  let breadcrumbs = [...props.values];
  if (props.showHome || props.showHome === undefined) {
    breadcrumbs.unshift({
      label: <><Icon iconName={IconDefinitions.house} /></>,
      url: "/",
    });
  }
  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
}

interface PageLayoutProps extends React.PropsWithChildren {
  title?: string;
  breadcrumbs?: PageBreadcrumbProps;
  topbar?: React.ReactElement;
  sidebar?: React.ReactElement;
  className?: string;
}

export function PageLayout({title, breadcrumbs, topbar, sidebar, children, className}: Readonly<PageLayoutProps>) {
  const [actions, setActions] = useState(<></>);
  const location = useLocation();
  const path = location.pathname;

  // Reset actions when location changes
  useEffect(() => {
    return () => setActions(() => {
      return <></>;
    });
  }, [path]);

  return (
    <PageActionsContext.Provider
      value={useMemo(() => ({ actions, setActions }), [actions, setActions])}
    >
        {topbar}
        <article className='container-fluid px-3'>
          <div className="row align-items-center py-2">
            <div className="col ms-3">
              <PageTitle title={title} />
              {PageBreadcrumbs(breadcrumbs)}
            </div>
            <div className="col-auto me-2">
              {actions}
            </div>
          </div>
          <div className="row">
            {sidebar ? (
              <>
                <div className="col-24 col-lg-6">
                  {sidebar}
                </div>
                <div className="col-24 col-lg-18 position-relative">
                  {children}
                </div>
              </>
            ) : (
              <div className="col mb-2">
                {children}
              </div>
            )}
          </div>
        </article>
    </PageActionsContext.Provider>
  );
}

export default PageLayout;



