import { Form, Row, Col, FloatingLabel, Button as BSButton } from "react-bootstrap";
import { Card } from "../../components/card/Card";
import UserService from "../../services/authentication/UserService";

import * as yup from 'yup';
import { Formik } from 'formik';
import { useChangePassword } from "./hooks/useChangePassword";
import { ChangePasswordRequest } from "../../models";
import { Button, IconAndText, IconDefinitions } from "../../components";
import { motion } from "framer-motion";
import { usePageActions } from "../PageLayout";
import { useRef } from "react";
import { useSetPageTitle } from "../../hooks";

export function ProfileChangePasswordPage() {

  const { changePassword: handleSubmit } = useChangePassword();
  const submitRef = useRef<HTMLButtonElement>(null);
  const validationSchema = yup.object().shape({
    currentPassword: yup.string().required("Current Password is required"),
    newPassword: yup.string().required("New Password is required"),
    confirmNewPassword: yup.string().required("Confirm Password is required").oneOf([yup.ref('newPassword')], 'Passwords must match')
  });

  useSetPageTitle("Change Password", []);
  usePageActions(
    <Button onClick={() => submitRef.current?.click()}>
      <IconAndText iconName={IconDefinitions.save} text="Save" />
    </Button>
  );

  return (
    <Card title="Change Password">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: ''
        }}
        onSubmit={async (values) => {
          handleSubmit({
            userId: UserService.getId().toString(),
            oldPassword: values.currentPassword,
            newPassword: values.newPassword
          } as ChangePasswordRequest)
        }}
      >
          {({ values, handleSubmit, handleChange, touched, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <FloatingLabel label="Current Password" className="mb-3">
                      <Form.Control
                        type="password"
                        name="currentPassword"
                        placeholder="Current Password"
                        value={values.currentPassword}
                        onChange={handleChange}
                        isInvalid={touched.currentPassword && !!errors.currentPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.currentPassword}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FloatingLabel label="New Password" className="mb-3">
                      <Form.Control
                        type="password"
                        name="newPassword"
                        placeholder="New Password"
                        value={values.newPassword}
                        onChange={handleChange}
                        isInvalid={touched.newPassword && !!errors.newPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.newPassword}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FloatingLabel label="Confirm Password" className="mb-3">
                      <Form.Control
                        type="password"
                        name="confirmNewPassword"
                        placeholder="Confirm Password"
                        value={values.confirmNewPassword}
                        onChange={handleChange}
                        isInvalid={touched.confirmNewPassword && !!errors.confirmNewPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmNewPassword}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
                <BSButton ref={submitRef} type="submit" className="d-none" />
              </Form>
            )
          }}
      </Formik>
    </Card>
  );
}

export default ProfileChangePasswordPage;
