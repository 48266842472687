import { UserInfo } from "../../../models";
import { OrganizationModel, ODataResponse, ProvinceModel  } from "../../entities";
import BaseDataRepository from "../BaseDataRepository";

export class OrganizationRepo extends BaseDataRepository<OrganizationModel>  {
  protected baseUrl = 'organization';
  // async getAddress(id: number) {
  //   const url = `Organization/${id}/Address`

  //   const response = await this.client.get(url);
  //   return this.handleResponse<OrganizationModel>(response);
  // }

  async getUsers(id: number): Promise<ODataResponse<UserInfo>> {
    const url = `/OData/Organizations(${id})/Users`;

    const response = await this.client.get(url);
    return this.handleResponse(response);
  }

  

  // async updateAddress(id: number, name: string, address: AddressModel): Promise<boolean | undefined> {
  //   const url = `Organization/${id}/Address`;
  //   const body = {
  //     id: id,
  //     name: name,
  //     address: address
  //   };

  //   const response = await this.client.post(url, body);
  //   const handled = this.handleResponse(response);
  //   return handled !== undefined;
  // }

  // async addOrganization(model: OrganizationAddModel): Promise<number | null> {
  //   const url = `Organization/Insert`;
  //   const body = {
  //       name: model.name,
  //       address: {
  //         streetAddress: model?.streetAddress,
  //         streetAddress2: model?.streetAddress2,
  //         city: model?.city,
  //         postalCode: model?.postalCode,
  //         provinceId: model?.provinceId,
  //       }
  //   };
  //     const response = await this.client.post<number>(url, body);
  //     const handled = this.handleResponse(response);
  //     return handled;
  // }


  async getAllProvinces() {
    const url = `Organization/Regions`

    const response = await this.client.get(url);
    return this.handleResponse<ProvinceModel>(response);
  }
}

export default OrganizationRepo;
