import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { routes } from "../../../app/routes";
import PageLayout from "../../PageLayout";
import { ProfileTopbar } from "..";
import { PageTitleProvider, usePageTitle } from "../../../hooks";

interface Breadcrumb {
  label: string;
  url: string | null;
}

export function BaseLayout() {
  const location = useLocation();
  const breadcrumbs: Array<Breadcrumb> = [];
  const { title } = usePageTitle();

  useEffect(() => {
    return () => {};
  }, [location]);

  if (location.pathname === routes.profile.index) {
    breadcrumbs.push({ label: "Profile", url: null });
  } else {
    breadcrumbs.push({ label: "Profile", url: routes.profile.index });
    breadcrumbs.push({ label: "Change Password", url: null });
  }

  return (
    <PageLayout
      title={title}
      breadcrumbs={{ values: breadcrumbs }}
      topbar={<ProfileTopbar />}
    >
      <Outlet />
    </PageLayout>
  );
}

export function ProfileBaseLayout() {
  // Wrap the BaseUserLayout in a PageTitleProvider.
  // This prevents an error of the context not being initialized when the usePageTitle hook is called.
  return (
    <PageTitleProvider>
      <BaseLayout />
    </PageTitleProvider>
  );
}

export default ProfileBaseLayout;

