import ServiceModel from '../../data/entities/booking/ServiceModel';
import { sharedProperty, useUrlParams } from '../../hooks';
import * as yup from "yup";
import { Formik } from "formik";
import TenantService from '../../services/organization/TenantService';
import { Col, FloatingLabel, Form, Row, Container } from 'react-bootstrap';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { Button } from '../../components';

interface ServiceDetailsPageProps {
  dataItemState: sharedProperty<ServiceModel>;
  showState: sharedProperty<boolean>;
  onSubmit: any;
}

export function ServiceDetailsPage(props: Readonly<ServiceDetailsPageProps>) {

  const id = parseInt(useUrlParams("id")); // ServiceType id

  const validationSchema = yup.object().shape({
    name: yup.string().required("Service Name is required"),
    description: yup.string().notRequired(),
    defaultPrice: yup.number().required("Default price is required"),
    hideProviders: yup.boolean().notRequired()
  });

  return (
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: props.dataItemState.value.name,
          description: props.dataItemState.value.description,
          defaultPrice: props.dataItemState.value.defaultPrice,
          hideProviders: props.dataItemState.value.hideProviders
        }}
        onSubmit={async (values) => {
          const service = {
            ...values,
            id: props.dataItemState.value.id,
            serviceTypeId: id,
            tenantId: TenantService.getTenantId(),
          } as ServiceModel;
          props.onSubmit(service);
        }}
      >
        {({ values, handleSubmit, handleChange, touched, errors }) => {
          return (
            <Form onSubmit={handleSubmit} className="h-100 d-flex flex-column justify-content-between py-3">
              <Container >
                <Row>
                  <Col>
                    <Form.Group controlId="serviceName">
                      <FloatingLabel label="Service Name" className="mb-3">
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Service Name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="defaultPrice">
                      <FloatingLabel label="Default Price" className="mb-3">
                        <Form.Control
                          type="number"
                          name="defaultPrice"
                          placeholder="Default Price"
                          value={values.defaultPrice}
                          onChange={handleChange}
                          isInvalid={touched.defaultPrice && !!errors.defaultPrice}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.defaultPrice}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="description">
                      <FloatingLabel label="Description" className="mb-3">
                        <ReactTextareaAutosize
                          minRows={4}
                          className="form-control"
                          placeholder="Description"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <Form.Group controlId="hideProviders">
                    <Form.Check type="switch" className="d-flex align-items-center px-0">
                      <Form.Check.Label>Hide Providers</Form.Check.Label>
                      <Form.Check.Input
                        onChange={handleChange}
                        checked={values.hideProviders}
                        className="fs-3 ms-2 mt-0 me-2"
                      />
                    </Form.Check>
                  </Form.Group>
                  </Col>
                </Row>
              </Container>

              <Container className="pt-3 border-top">
                <Row className="g-0">
                  <div className="col-12 pe-2">
                    <Button onClick={() => { props.showState.setter(false) }} variant="cancel" className="w-100">
                      Cancel
                    </Button>
                  </div>
                  <div className="col-12 ps-2">
                    <Button type='submit' variant="primary" className="w-100">
                      Save
                    </Button>
                  </div>
                </Row>
              </Container>
            </Form>
          )
        }}
      </Formik>
  )
}

export default ServiceDetailsPage;
