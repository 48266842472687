import { useDispatch, useSelector } from 'react-redux';
import { updatePolicyCache, selectPolicyCache } from '../app/store/authorizationSlice';
import { AuthorizationService } from '../services';
import { AuthorizationRequest, AuthorizationResponse, AuthorizationResult } from '../models';

export function useAuthorization() {

  const dispatch = useDispatch();
  const policyCache = useSelector(selectPolicyCache);
  const authorizationService = new AuthorizationService();

  // #region Authorization Checks
  const checkPolicies = async (policies: string[]): Promise<{ [policy: string]: boolean }> => {

    // Filter out policies that are already in the cache
    const policiesToCheck = policies.filter(policy => policyCache[policy] === undefined);
    let newPolicyResults: { [policy: string]: boolean } = {};

    if (policiesToCheck.length > 0) {
      try {

        const result: AuthorizationResponse = await authorizationService.checkPolicies({ policies: policiesToCheck } as AuthorizationRequest);
        result.result.forEach((authResult: AuthorizationResult) => {
          newPolicyResults[authResult.policy] = authResult.success;
        });

        dispatch(updatePolicyCache(newPolicyResults));

      } catch (error) {
        console.error('Error during authorization check:', error);
        throw error;
      }
    }

    const finalResults: { [policy: string]: boolean } = {};

    policies.forEach(policy => {
      if (policyCache[policy] !== undefined)
        finalResults[policy] = policyCache[policy];
      else if (newPolicyResults[policy] !== undefined)
        finalResults[policy] = newPolicyResults[policy];
      else
        finalResults[policy] = false;

    });

    return finalResults;
  };
  // #endregion

  return {
    checkPolicies,
  };

}

export default useAuthorization;
