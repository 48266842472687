import React from 'react';
import { router } from "../../../app/routes";
import { UrlService } from "../../../services";
import { MenuItem } from "./MenuItem";
import styles from './styles/SidebarSubmenu.module.scss';

export function SidebarSubmenuItem({
  text,
  url,
  additionalClass,
  activeMatchUrl,
  activeMatchExact,
  clickHandler,
}: MenuItem) {
  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    if (clickHandler) {
      clickHandler();
    } else {
      router.navigate(url);
    }
  };

  let isActive = false;
  if (activeMatchUrl) {
    isActive = UrlService.isUrlActive(
      activeMatchUrl,
      activeMatchExact ?? activeMatchExact === undefined
    );
  }

  const liClasses = [
    additionalClass,
    styles.menuItem,
    isActive && styles.active,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <li className={liClasses}>
      <span
        title={text}
        onClick={handleClick}
      >
        {text}
      </span>
    </li>
  );
}

export default SidebarSubmenuItem;
