import { routes } from "../../../app/routes";
import {
  TopbarSubmenu,
  IconDefinitions,
  SubmenuItem,
} from "../../../components";
import { useMemo } from "react";
import { useUrlParams } from "../../../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../app";
import usePermission from "../../../hooks/usePermission";
import { PermissionDefinitions } from "../../../security";

export const ServiceTypeTopbar = (props: any) => {

  const id = useUrlParams("id");

  const tenantId = useSelector((state: RootState) => state.authentication.tenantId);
  const canGetService = usePermission(PermissionDefinitions.service.get(tenantId!));

  const submenuItems: SubmenuItem[] = useMemo((): SubmenuItem[] => {
    const items: SubmenuItem[] = [
      {
        label: "Back",
        icon: IconDefinitions.back,
        url: routes.serviceType.index,
      },
      {
        label: "Details",
        icon: IconDefinitions.squareInfo,
        url: routes.serviceType.details,
        activeMatch: routes.serviceType.details,
        activeMatchExact: true,
        params: new Map([["id", id]]),
      },
    ];

    if (canGetService) {
      items.push(
        {
          label: "Services",
          icon: IconDefinitions.gear,
          url: routes.serviceType.service,
          activeMatch: routes.serviceType.service,
          activeMatchExact: true,
          params: new Map([["id", id]]),
        },
      );
    }

    return items;
  }, [id, canGetService]);


  return <TopbarSubmenu className="p-0" items={submenuItems} />;
};

export default ServiceTypeTopbar;

