import { useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { router, routes } from "../../app/routes";
import AccountService from "../../services/account/AccountService";
import * as yup from 'yup';
import { Formik } from 'formik';

export function RegistrationRequestPage() {
  const [showSpinner, setShowSpinner] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Email must be valid").required("Email is required"),
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
  });


  return (
    <>
      <h2>Request Access</h2>
      <section>
        <h4 className="mb-4">Request a new account.</h4>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            email: '',
            firstName: '',
            lastName: '',
          }}
          onSubmit={async (values) => {
            // setShowSpinner(true);
            // const ok = await new AccountService().register(
            //   values.email,
            //   values.firstName,
            //   values.lastName
            // );
            // setShowSpinner(false);
            // if (ok) router.navigate(routes.account.access.request.confirmation);
          }}
        >
          {({ values, handleSubmit, handleBlur, handleChange, touched, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group controlId="email">
                      <FloatingLabel label="Email" className="mb-3">
                        <Form.Control
                          type="email"
                          name="email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="firstName">
                      <FloatingLabel label="First Name" className="mb-3">
                        <Form.Control
                          type="text"
                          name="firstName"
                          value={values.firstName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={touched.firstName && !!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="lastName">
                      <FloatingLabel label="Last Name" className="mb-3">
                        <Form.Control
                          type="text"
                          name="lastName"
                          value={values.lastName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={touched.lastName && !!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>

                <Button type="submit" size="lg" className="btn btn-primary w-100">
                  {
                    showSpinner ?
                      (<Spinner animation="border" variant="light" as="span" role="status" aria-hidden="true" />) :
                      "Register"
                  }
                </Button>

              </Form>
            )
          }}
        </Formik>
      </section>
    </>
  );
}

export default RegistrationRequestPage;
